import React from 'react';
import classNames from 'classnames';
import {Button, Col, Row} from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import {l} from '../../../../../i18n/translator.js';
import {Fields as CreditOfferRequestFields} from '../../../../../../config/domain/offerRequest/credit.js';
import {getCurrentYear} from '../../../../../helper/date.js';
import {Field, FieldArray} from '../../../../insurance-offer/create-request/component/redux-form-wrapper.jsx';
import {
    renderCurrencyField,
    renderNumberField,
    renderSelectField,
    renderTextField
} from '../../../form/renderer.jsx';
import {creditFieldKey as dataFieldKey} from '../../../../insurance-offer/create-request/helper';

const textFieldProps = {
    component: renderTextField,
    fullWidth: true
};

const numberFieldProps = {
    component: renderNumberField,
    fullWidth: true
};

export const Development = ({currency, fields, meta: {touched, error}}) => (
    <div className="m-b-lg">
        <h3 className="m-b-xs">{l('Development of accounts receivable')}</h3>
        <p className="m-b-md">
            {l('Total receivables (in 000s) at the end of the most recent 4 quarters:')}
        </p>

        <Row className="m-t-n-lg">
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_DEVELOPMENT + '[0]')}
                    {...numberFieldProps}
                    label={l('1st Quarter')}
                    component={renderCurrencyField}
                    currency={currency}
                />
            </Col>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_DEVELOPMENT + '[1]')}
                    {...numberFieldProps}
                    label={l('2nd Quarter')}
                    component={renderCurrencyField}
                    currency={currency}
                />
            </Col>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_DEVELOPMENT + '[2]')}
                    {...numberFieldProps}
                    label={l('3rd Quarter')}
                    component={renderCurrencyField}
                    currency={currency}
                />
            </Col>
            <Col md={3}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_DEVELOPMENT + '[3]')}
                    {...numberFieldProps}
                    label={l('4th Quarter')}
                    component={renderCurrencyField}
                    currency={currency}
                />
            </Col>
        </Row>

        {touched && error && (
            <div className="text-danger">{error}</div>
        )}
    </div>
);

export const SalesStructure = ({fields, meta: {touched, error}}) => (
    <div className="m-b-lg">
        <h3 className="m-b-xs">{l('Structure of accounts receivable')}</h3>
        <p className="m-b-md">
            {l('Customers with max. outstanding debt in 000s')}<br />
            {l('(excl. private customers, subsidiaries, affiliated companies and public corporations)')}
        </p>

        <Row className="m-t-n-lg">
            <Col md={2}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_STRUCTURE + '[0]')}
                    {...numberFieldProps}
                    label={l('up to 5')}
                />
            </Col>
            <Col md={2}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_STRUCTURE + '[1]')}
                    {...numberFieldProps}
                    label={l('5 to 20')}
                />
            </Col>
            <Col md={2}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_STRUCTURE + '[2]')}
                    {...numberFieldProps}
                    label={l('20 to 100')}
                />
            </Col>
            <Col md={2}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_STRUCTURE + '[3]')}
                    {...numberFieldProps}
                    label={l('100 to 300')}
                />
            </Col>
            <Col md={2}>
                <Field
                    name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_STRUCTURE + '[4]')}
                    {...numberFieldProps}
                    label={l('> 300')}
                />
            </Col>
        </Row>

        {touched && error && (
            <div className="text-danger">{error}</div>
        )}
    </div>
);

export const BiggestCustomers = ({currency, fields, meta: {touched, error}}) => (
    <div className="m-b-lg">
        <Row>
            <Col md={9}>
                <h3 className="m-b-xs">
                    {l('Your biggest customers')}
                </h3>
                <p className="m-b-md">
                    {l('Please list your 5-10 biggest customers')}
                </p>
            </Col>
            <Col md={3}>
                <Button className="pull-right" onClick={() => fields.push({})}>
                    <i className="fa fa-plus"/> {l('Add Customer')}
                </Button>
            </Col>
        </Row>

        <Row>
            <Col md={12}>
                {fields.map((field, index) => (
                    <Row key={index} className="m-b-md">
                        <Col md={4}>
                            <Field
                                name={`${field}.name`}
                                label={l('Name')}
                                {...textFieldProps}
                            />

                            <Button onClick={() => fields.remove(index)}>
                                <i className="fa fa-trash"/>
                            </Button>
                        </Col>

                        <Col md={4}>
                            <Field
                                name={`${field}.addressLine1`}
                                label={l('Address line 1')}
                                {...textFieldProps}
                            />

                            <Field
                                name={`${field}.addressLine2`}
                                label="Address line 2"
                                {...textFieldProps}
                            />
                            <Field
                                name={`${field}.zip`}
                                label={l('Zip Code')}
                                {...textFieldProps}
                            />

                            <Field
                                name={`${field}.town`}
                                label={l('City')}
                                {...textFieldProps}
                            />

                            <Field
                                name={`${field}.country`}
                                label={l('Country')}
                                {...textFieldProps}
                            />
                        </Col>

                        <Col md={4}>
                            <Field
                                name={`${field}.maximumOutstandingDept`}
                                label={l('Max. outstanding debt')}
                                {...numberFieldProps}
                                component={renderCurrencyField}
                                currency={currency}
                            />

                            <Field
                                name={`${field}.annualTurnover`}
                                label={l('Annual turnover')}
                                {...numberFieldProps}
                                component={renderCurrencyField}
                                currency={currency}
                            />
                        </Col>
                    </Row>
                ))}

                {touched && error && (
                    <div className="text-danger">{error}</div>
                )}
                <hr />
            </Col>
        </Row>
    </div>
);

export const BadDebtsBiggestCases = ({currency, fields, meta: {touched, error}}) => (
    <div>
        <Row>
            <Col md={8}><h4>{l('Biggest cases')}</h4></Col>
            <Col md={4}>
                <Button className="pull-right" disabled={fields.length >= 2} onClick={() => fields.push({}) }>
                    <i className="fa fa-plus"/> {l('Add')}
                </Button>
            </Col>
        </Row>


        {fields.map((field, index) => (
            <Row key={index}>
                <Col md={5}>
                    <Field
                        name={`${field}.customer`}
                        label={l('Customer')}
                        className="m-t-n-sm"
                        {...textFieldProps}
                    />
                </Col>
                <Col md={5}>
                    <Field
                        name={`${field}.amount`}
                        label={l('Amount')}
                        className="m-t-n-sm"
                        {...numberFieldProps}
                        component={renderCurrencyField}
                        currency={currency}
                    />
                </Col>
                <Col  md={1}>
                    <Button className="m-t-sm" onClick={() => fields.remove(index)}>
                        <i className="fa fa-trash"/>
                    </Button>
                </Col>
            </Row>
        ))}

        {touched && error && (
            <div className="text-danger">{error}</div>
        )}
    </div>
);

export const BadDebts = ({i18n, currency, fields, meta: {touched, error}}) => {
    const currentYear = parseInt(getCurrentYear());

    return (
        <div className={classNames('field m-b-lg', {'has-error': touched && error})}>
            <Row className="m-b-sm">
                <Col md={9}>
                    <h3>
                        {l('Bad debts and distressed claims from the current to the last 3 years')}
                    </h3>
                </Col>
                <Col md={3}>
                    <Button className="pull-right" onClick={() => fields.push({})} disabled={fields.length >= 4}>
                        <i className="fa fa-plus"/> {l('Add Debt')}
                    </Button>
                </Col>
            </Row>

            {fields.map((field, index) => (
                <div key={index}>
                    <Row className="m-b-md">
                        <Col md={4}>
                            <Field
                                name={`${field}.year`}
                                label={l('Year')}
                                component={renderSelectField}
                                fullWidth={true}
                            >
                                <MenuItem value={currentYear} primaryText={l('Current year')}/>
                                <MenuItem value={currentYear - 1} primaryText={l('{{count}} years ago', {count: 1})}/>
                                <MenuItem value={currentYear - 2} primaryText={l('{{count}} years ago', {count: 2})}/>
                                <MenuItem value={currentYear - 3} primaryText={l('{{count}} years ago', {count: 3})}/>
                            </Field>
                        </Col>

                        <Col md={4}>
                            <Field
                                name={`${field}.total`}
                                label={l('Total number')}
                                {...numberFieldProps}
                            />
                        </Col>

                        <Col md={4}>
                            <Field
                                name={`${field}.totalAmount`}
                                label={l('Total amount')}
                                {...numberFieldProps}
                                component={renderCurrencyField}
                                currency={currency}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Button onClick={() => fields.remove(index)}>
                                <i className="fa fa-trash"/>
                            </Button>
                        </Col>
                        <Col md={8}>
                            <FieldArray
                                name={`${field}.biggestCases`}
                                component={BadDebtsBiggestCases}
                                currency={currency}
                                i18n={i18n}
                            />
                        </Col>
                    </Row>
                </div>
            ))}

            {touched && error && (
                <div className="text-danger">{error}</div>
            )}
            <hr />
        </div>
    );
};


export const AccountsReceivableForm = ({i18n, currency}) => (
    <div className="m-t-md">
            <FieldArray
                name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_DEVELOPMENT)}
                component={Development}
                currency={currency}
                i18n={i18n}
            />

            <FieldArray
                name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_STRUCTURE)}
                component={SalesStructure}
                currency={currency}
                i18n={i18n}
            />

            <FieldArray
                name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_BIGGESTCUSTOMERS)}
                component={BiggestCustomers}
                currency={currency}
                i18n={i18n}
            />

            <FieldArray
                name={dataFieldKey(CreditOfferRequestFields.ACCOUNTSRECEIVABLE_BADDEPTS)}
                component={BadDebts}
                currency={currency}
                i18n={i18n}
            />
    </div>
);
