import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {fetchAnalysisTemplates, fetchInsuranceTypes} from '../../../api/sdk-action';

export const ACTION_TYPES = createPageActionTypes('page.analysis_templates_list.');

export const initTemplatesList = createPageLoader(
    ACTION_TYPES,
    (dispatch) => {
        return Promise.all([
            dispatch(fetchAnalysisTemplates({showInactive: true})),
            dispatch(fetchInsuranceTypes()),
        ]).then(([templates, insuranceTypes]) => {
            return {templates, insuranceTypes};
        });
    }
);
