export const CriteriaTypes = {
    PREMIUM: 'premium',
    COVERAGE: 'coverage',
    UTILITY: 'utility',
};

export const RatingTypes = {
    DEFAULT: 'default',
    NUMBER: 'number',
    OPTIONS: 'options',
    CHECKBOX: 'checkbox',
};

///////////////////////////////

export const RatingFields = {
    CRITERIA_ID: 'criteriaId',
    RATING: 'rating',
    RATING_DATA: 'ratingData',
    POINTS: 'points',
    COMMENT_BROKER: 'commentBroker',
    INSURANCE_ID: 'insuranceId',
    INSURANCE_NAME: 'insuranceName',
};

export const CriteriaFields = {
    ID: 'id',
    NAME: 'name',
    DESCRIPTION: 'description',
    NOTE_ADMIN: 'noteAdmin',
    ACTIVE: 'active',
    TYPE: 'type',
    RATING_TYPE: 'ratingType',
    RATING_TYPE_CONFIG: 'ratingTypeConfig',
    INSURANCE_TYPE_IDS: 'insuranceTypeIds',
    WEIGHT: 'weight',
    WEIGHT_PERCENTAGE: 'weightPercentage',
    TARGET_RATING: 'targetRating',
    TARGET_RATING_DATA: 'targetRatingData',
    ORDER: 'order',

    IS_SYSTEM: 'isSystem',
    IS_LICENSED: 'isLicensed',
};

export const CriteriaNumberRatingTypeFields = {
    NUMBER_TYPE: 'numberType',
};

export const NumberRatingTypes = {
    DEFAULT: 'default',
    PERCENTAGE: 'percentage',
    CURRENCY: 'currency',
};

////////////////////////////////

export const TemplateFields = {
    ID: 'id',
    BROKER_ID: 'brokerId',
    NAME: 'name',
    INSURANCE_TYPE_ID: 'insuranceTypeId',
    INDUSTRY_CLASSIFICATION_CODE: 'industryClassificationCode',
    ACTIVE: 'active',

    CRITERIA: 'criteria',
};

export const AutoRatingFields = {
    ID: 'id',
    ANALYSIS_CRITERIA_ID: 'analysisCriteriaId',
    INSURANCE_TYPE_ID: 'insuranceTypeId',
    INSURANCE_ID: 'insuranceId',
    INDUSTRY_CLASSIFICATION_CODE: 'industryClassificationCode',
    GENERAL_CONDITIONS_OF_INSURANCE_VERSION: 'generalConditionsOfInsuranceVersion',
    SPECIAL_CONDITIONS_OF_INSURANCE_VERSION: 'specialConditionsOfInsuranceVersion',
    QUOTE_ENGINE_HASH: 'quoteEngineHash',
    RATING_DATA: 'ratingData',
    COMMENT_BROKER: 'commentBroker',
};

export const CRITERIA_ID_PREMIUM = 0;

export const DEFAULT_PREMIUM_CRITERIA = [{id: CRITERIA_ID_PREMIUM, weight: 100}];
