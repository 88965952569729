import React from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem';

import {l} from '../../../../../i18n/translator';
import {Fields, FORM_NAME} from '../../config/form';
import {Field} from '../redux-form-wrapper.jsx';
import {renderSelectField} from '../../../../common/form/renderer.jsx';
import {dataLoaded} from '../../action/page.js'
import {onCustomerChanged} from '../../action/page';

let renderCustomerField = connect(
    null,
    dispatch => ({
        updateCustomerCompany: (customerCompany) => {
            const contact = customerCompany.contact;
            const bin = customerCompany.businessIdentificationNumber;

            dispatch(dataLoaded('contact', contact));
            dispatch(change(FORM_NAME, Fields.SAME_AS_CONTACT, true));

            const newContact = {...contact, id: null};
            dispatch(change(FORM_NAME, Fields.CONTACT, newContact));

            dispatch(change(FORM_NAME, Fields.BUSINESS_IDENTIFICATION_NUMBER, bin));
        },
    })
)(
    ({updateCustomerCompany, customers, input, ...props}) => {
        const {value, onChange} = input;
        const Component = renderSelectField;

        const setContactWithCompanyId = (companyId) => {
            let customerCompany = customers.find(customer => customer.id === companyId);
            updateCustomerCompany(customerCompany);
        };

        const wrappedOnChange = (newValue) => {
            setContactWithCompanyId(newValue);
            return onChange(newValue);
        };

        return (
            <Component {...props} input={{...input, onChange: wrappedOnChange}}/>
        );
    });

export class CustomerForm extends React.Component {

    render() {
        const {customers, className} = this.props;

        return (
            <div className={className}>
                <h3>
                    {l('Customer')}
                </h3>
                <Field
                    name={Fields.COMPANY_ID}
                    component={renderCustomerField}
                    label={l('Select customer')}
                    fullWidth={true}
                    customers={customers}
                    onChange={this.props.onCustomerChanged}
                >
                    {customers !== null && customers.map(customer => (
                        <MenuItem key={customer.id} value={customer.id} primaryText={customer.name}/>
                    ))}
                </Field>
            </div>
        );
    }
}

export default connect(
    null, dispatch => ({
        change: (field, value, customers) => dispatch(change(FORM_NAME, field, value)),
        onCustomerChanged: (customerId) => dispatch(onCustomerChanged(customerId)),
    })
)(CustomerForm);
