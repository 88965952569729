import React from 'react';
import {l} from '../../../../../i18n/translator.js';
import {Field} from '../../../../insurance-offer/create-request/component/redux-form-wrapper.jsx';
import {renderSingleFileUpload, renderTextField} from '../../../form/renderer.jsx';
import {Fields} from '../../../../insurance-offer/create-request/config/form';

export const AdditionalInformationForm = ({i18n, offerRequestId, dataFieldKey, hideAttachments}) => (
    <div>
        <Field
            className="m-t-md"
            name={dataFieldKey('additionalInformation')}
            component={renderTextField}
            placeholder={l('You can start writing here')}
            multiline={true}
            fullWidth={true}
            rows={10}
            rowsMax={100}
        />


        {!hideAttachments && (
            <div>
                <div className="m-t-md"></div>
                <h4>{l('Offer request files')}</h4>
                <Field
                    name={Fields.ATTACHMENTS}
                    component={renderSingleFileUpload}
                    offerRequestId={offerRequestId}
                    i18n={i18n}
                />
            </div>
        )}
    </div>
);
