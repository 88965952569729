export const CompanyTypes = {
    CUSTOMER: 'customer',
    INSURANCE: 'insurance',
    BROKER: 'broker'
};

export const Roles = {
    USER: 'user',
    ADMIN: 'admin'
};

export const Fields = {
    ID: 'id',
    COMPANY_ID: 'companyId',
    USERNAME: 'username',
    EMAIL: 'email',
    ROLE: 'role',
    PERMISSIONS: 'permissions',
    PASSWORD: 'password'
};
