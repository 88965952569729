import {change, initialize, SubmissionError} from 'redux-form';

import {createPageActionTypes, createPageLoader} from '../../common/page/loaded-page/action';
import {
    apiCreateTemplate,
    apiUpdateTemplate,
    fetchAnalysisCriteriaList,
    fetchAnalysisTemplate,
    fetchInsuranceTypes
} from '../../../api/sdk-action';
import {
    CriteriaFields,
    CriteriaTypes,
    DEFAULT_PREMIUM_CRITERIA,
    TemplateFields
} from '../../../../config/domain/analysis';
import URI from '../../../../config/uri.js';
import {buildErrors} from '../../common/form/helper';
import {FORM_NAME} from './page.jsx';
import {redirectTo} from '../../../router/action';

export const ACTION_TYPES = {
    UPDATE_TEMPLATE: 'page.analysis_templates_edit.update_template',
    ...createPageActionTypes('page.analysis_templates_edit.')
};

export const initTemplateEdit = createPageLoader(
    ACTION_TYPES,
    (dispatch, {templateId}) => {
        return Promise.all([
            templateId !== null ? dispatch(fetchAnalysisTemplate(templateId)) : {},
            dispatch(fetchInsuranceTypes()),
        ]).then(([template, insuranceTypes]) => {
            if (template) {
                dispatch(onInsuranceTypeChanged(template[TemplateFields.INSURANCE_TYPE_ID]));
            }

            if (template && template.criteria) {
                template.criteria = {
                    premium: template.criteria.filter(c => c[CriteriaFields.TYPE] === CriteriaTypes.PREMIUM),
                    coverage: template.criteria.filter(c => c[CriteriaFields.TYPE] === CriteriaTypes.COVERAGE),
                };
            }

            dispatch(initialize(FORM_NAME, template));
            return {template, insuranceTypes};
        });
    }
);

export const saveTemplate = (data, dispatch) => {
    data = {...data};

    if (data.criteria) {
        const criteria = data.criteria || {};
        data.criteria = [...(criteria.premium || []), ...(criteria.coverage || [])];
    }

    return dispatch(
        data[TemplateFields.ID]
            ? apiUpdateTemplate(data[TemplateFields.ID], data)
            : apiCreateTemplate(data)
    ).then(template => {
        if (!data.id) {
            dispatch({type: ACTION_TYPES.UPDATE_TEMPLATE, template});
            dispatch(redirectTo(URI.ANALYSIS_TEMPLATES_EDIT.replace(':id', template.id)));
        }
    }).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};

export const onInsuranceTypeChanged = (insuranceTypeId) => dispatch => {
    if (!insuranceTypeId) return;

    dispatch(change(FORM_NAME, 'criteria.premium', DEFAULT_PREMIUM_CRITERIA));
    dispatch(change(FORM_NAME, 'criteria.coverage', []));

    dispatch(fetchAnalysisCriteriaList({insuranceType: insuranceTypeId}))
        .then(criteria => dispatch({type: ACTION_TYPES.DATA_LOADED, data: {criteria}}));
};
