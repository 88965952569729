import {change, getFormValues, SubmissionError} from 'redux-form';

import URI from '../../../../../config/uri';
import {getUserCompany, isUserOfType} from '../../../../auth/helper';
import {isArray, objectProp, objectPropSet} from '../../../../helper/core';
import {getNextYear} from '../../../../helper/date';
import {createAction} from '../../../../action';
import {replaceUri} from '../../../../router/action';
import {Fields, FORM_NAME} from '../config/form';
import {buildFormData, buildFormErrors} from '../helper';
import {getAdvisoryMandate, getHarmFile} from '../reducer';
import {publishOfferRequest as apiPublish} from '../../../../api/sdk-action';
import TYPES from './type';
import {save as apiSave, validate as apiValidate} from './api';
import {dataLoaded, loadInsurances} from './page';
import {CompanyTypes} from '../../../../../config/domain/user';
import {Fields as CompanyFields} from '../../../../../config/domain/company';
import {CRITERIA_ID_PREMIUM} from '../../../../../config/domain/analysis.js';
import {handleCompanyNotSetupError} from '../../../common/errors';


export const validateForm = () => (dispatch, getState) => {
    // generate request data
    let formData = getFormData(getState);

    return dispatch(
        apiValidate(formData)
    ).catch(error => {
        processValidationError(error, formData);
    });
};


export const save = (currentStep) => {
    return (dispatch, getState) => {
        dispatch({
            type: TYPES.SAVE_START
        });

        // generate request data
        let formData = getFormData(getState);

        return dispatch(
            apiSave(formData)
        ).then((offerRequest) => {
            dispatch({type: TYPES.SAVE_END});

            dispatch(setFormApiData(offerRequest)); // replace all, not just update

            if (!objectProp(Fields.ID, formData.values)) {
                dispatch(replaceUri(
                    URI.EDIT_OFFER_REQUEST.replace(':id', objectProp(Fields.ID, offerRequest))
                    + (currentStep ? `?step=${currentStep}` : '')
                ));
            }

            // clear flag
            setTimeout(() => dispatch({
                type: TYPES.CLEAR_SAVED_FLAG
            }), 1000);

        }).catch((error) => {
            // handle company not set
            if (handleCompanyNotSetupError(dispatch, error, formData.values[Fields.COMPANY_ID])) {
                return;
            }

            if (error.validationErrors) {
                dispatch({type: TYPES.SAVE_END});
                dispatch({type: TYPES.CLEAR_SAVED_FLAG});

                processValidationError(error, formData);
            }

            dispatch({type: TYPES.SAVE_END, error});
        });
    };
};


export const executePublish = (formData) => (dispatch) => {
    dispatch(
        apiSave(formData)
    ).then((offerRequest) => dispatch(
        apiPublish(offerRequest)
    )).then((offerRequest) => {
        dispatch(dataLoaded('offerRequest', offerRequest));
        dispatch({
            type: TYPES.PUBLISH_END,
            isPublished: true
        });
    }).catch(error => dispatch(
        processError(TYPES.PUBLISH_END, error, formData)
    ));
};


export const processError = (type, error, formData) => (dispatch) => {
    if (handleCompanyNotSetupError(dispatch, error, formData.values[Fields.COMPANY_ID])) {
        return;
    }

    // dispatch end with errors action
    dispatch({type: type, error});

    processValidationError(error, formData);
};

export const processValidationError = (error, formData) => {
    if (!error.validationErrors) {
        throw error;
    }

    const validationErrors = buildFormErrors(error.validationErrors, formData);
    throw new SubmissionError(validationErrors);
};

export const validate = () => {
    return (dispatch, getState) => {
        // generate request data
        let formData = getFormData(getState);

        dispatch({type: TYPES.VALIDATE_START});

        return dispatch(
            apiValidate(formData)
        ).then(() => {
            dispatch({type: TYPES.VALIDATE_END, isValidated: true});
            setTimeout(() => dispatch({
                type: TYPES.CLEAR_VALIDATE_FLAG
            }), 1000);
        }).catch(error => {
            if (error instanceof SubmissionError) {
                throw error;
            }
            dispatch(processError(TYPES.VALIDATE_END, error, formData));
        });
    };
};
export const publish = () => {
    return (dispatch, getState) => {
        // generate request data
        let formData = getFormData(getState);

        dispatch({type: TYPES.PUBLISH_START});

        return dispatch(
            apiValidate(formData)
        ).then(() => {
            // check if agreements accepted
            if (isUserOfType(getState().auth, CompanyTypes.CUSTOMER)) {
                if (!formData.values[Fields.ACCEPT_TERMS] || !formData.values[Fields.ACCEPT_CONTRACTUAL_FEE_AGREEMENT]) {
                    dispatch({type: TYPES.PUBLISH_END});
                    const errors = {};
                    // doesn't matter what text. we just need one to be set so we show the error
                    errors[Fields.ACCEPT_TERMS] = 'error';
                    errors[Fields.ACCEPT_CONTRACTUAL_FEE_AGREEMENT] = 'error';
                    dispatch(() => {
                        throw new SubmissionError(errors);
                    });
                    return;
                }
            }

            dispatch(executePublish(formData));
        }).catch(error => {
            if (error instanceof SubmissionError) {
                throw error;
            }
            dispatch(processError(TYPES.PUBLISH_END, error, formData));
        });
    };
};


/*
 -----------------------------------------------------------------------------------------------------------------------
 */

export const getFormData = (getState) => {
    const values = getFormValues(FORM_NAME)(getState());
    const harmFile = getHarmFile(getState());
    const advisoryMandate = getAdvisoryMandate(getState());

    return {values, harmFile, advisoryMandate};
};

export const setFormApiData = (apiData, update = false) => {
    return (dispatch, getState) => {
        let formData = buildFormData(apiData);

        const insuranceType = objectProp(Fields.INSURANCE_TYPE_ID, formData);

        // load insurances
        dispatch(loadInsurances(insuranceType));

        // if same as contact flag is on
        // set contact same as user company contact
        if (objectProp(Fields.SAME_AS_CONTACT, formData)) {
            const {contact} = getState().page.createOfferRequest.data;
            const contactWithoutId = {...contact, id: undefined};

            objectPropSet(Fields.SAME_AS_CONTACT, true, formData);
            objectPropSet(Fields.CONTACT, contactWithoutId, formData);
        }

        const customers = getState().page.createOfferRequest.data.customers;
        if (null !== formData.companyId && null !== customers && customers.length > 0) {

            const company = customers.find((customer) => customer.id === formData[Fields.COMPANY_ID]);

            if (!formData[Fields.BUSINESS_IDENTIFICATION_NUMBER]) {
                objectPropSet(Fields.BUSINESS_IDENTIFICATION_NUMBER, company && company[CompanyFields.BUSINESS_IDENTIFICATION_NUMBER], formData);
            }
        }

        dispatch(setFormData(formData, update));

        return formData;
    };
};

export const setInitialFormData = () => {
    return (dispatch, getState) => {
        const pageState = getState().page.createOfferRequest;

        // init initial values object
        let initialValues = {};

        // update businessIdentificationNumber
        const auth = getState().auth;
        const userCompany = getUserCompany(auth) || {};

        objectPropSet(Fields.BUSINESS_IDENTIFICATION_NUMBER, userCompany.businessIdentificationNumber, initialValues);

        // set default broker
        const {brokers} = pageState.data;

        if (isArray(brokers) && brokers.length) {
            const brokerId = isUserOfType(getState().auth, CompanyTypes.BROKER) ? userCompany.id : brokers[0].id;
            objectPropSet(Fields.BROKER, brokerId, initialValues);
        }

        // set contact same as user company contact
        const {contact} = getState().page.createOfferRequest.data;

        objectPropSet(Fields.SAME_AS_CONTACT, true, initialValues);
        objectPropSet(Fields.CONTACT, {...contact}, initialValues);

        // set that current insurance is not present
        objectPropSet(Fields.HAS_CURRENT_INSURANCE, "yes", initialValues);

        // set yearly right of termination
        objectPropSet(Fields.INSURANCE_YEARLY_TERMINATION, false, initialValues);

        // set default start
        objectPropSet(Fields.INSURANCE_START, `${getNextYear()}-01-01`, initialValues);

        objectPropSet(Fields.PREMIUM_ANALYSIS_CRITERIA, [{
            id: CRITERIA_ID_PREMIUM,
            weight: 100
        }], initialValues);
        objectPropSet(Fields.COVERAGE_ANALYSIS_CRITERIA, [], initialValues);
        objectPropSet(Fields.UTILITY_ANALYSIS_CRITERIA, [], initialValues);

        // dispatch
        dispatch(setFormData(initialValues, true));
    };
};

/* -------------------------------------------------------------------------------- */


Number.prototype.roundToNearest = function (multiple, roundingFunction) {
    // Use normal rounding by default
    roundingFunction = roundingFunction || Math.round;

    return roundingFunction(this / multiple) * multiple;
};

export const generateTargetPremium = () => {
    return (dispatch, getState) => {
        const values = getFormValues(FORM_NAME)(getState());
        const currentPremiumGross = objectProp(Fields.CURRENT_INSURANCE_PREMIUM_GROSS, values);
        if (currentPremiumGross) {
            const percentageLowerThanCurrentPremium = 0.05 + Math.random() * 0.02;
            const targetPremium = currentPremiumGross * (1 - percentageLowerThanCurrentPremium);
            dispatch(change(FORM_NAME, Fields.TARGET_PREMIUM, targetPremium.roundToNearest(100)));
        }
    };
};

export const onCurrentInsurancePremiumChange = () => {
    return (dispatch, getState) => {
        // setTimeout because when called, the value is not yet in redux form
        setTimeout(() => {
            const values = getFormValues(FORM_NAME)(getState());
            const currentPremiumGross = objectProp(Fields.CURRENT_INSURANCE_PREMIUM_GROSS, values);
            const targetPremium = objectProp(Fields.TARGET_PREMIUM, values);
            if (currentPremiumGross && targetPremium) {
                dispatch(generateTargetPremium());
            }
        }, 300);
    };
};


export const setFormData = (formData, update = false) => createAction(
    TYPES.SET_FORM_DATA, {formData, update}
);

export const cancel = () => createAction(
    TYPES.CANCEL_WIZARD
);
