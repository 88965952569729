import {isArray, isDefined} from '../helper/core';
import {CompanyTypes, Roles as UserRoles} from '../../config/domain/user';


export const getUser = (auth) => {
    return isDefined(auth.user) ? auth.user : null;
};

export const getUserToken = (auth) => {
    const user = getUser(auth);
    return null !== user ? user.token : null;
};

export const isUserLoggedIn = (auth) => {
    return !!getUserToken(auth);
};

export const getUserType = (auth) => {
    return isUserLoggedIn(auth) ? getUser(auth).companyType : null;
};

export const getUserCompany = (auth) => {
    return isUserLoggedIn(auth) ? getUser(auth).company : null;
};

export const getUserRole = (auth) => {
    return isUserLoggedIn(auth) ? getUser(auth).role : null;
};


export const isUserOfType = (auth, type) => {
    const userType = getUserType(auth);

    if (!isArray(type)) {
        type = [type];
    }

    return auth !== null && type.indexOf(userType) !== -1;
};

export const isUserBroker = (auth) => isUserOfType(auth, CompanyTypes.BROKER);

export const hasUserRole = (auth, role) => {
    const userRole = getUserRole(auth);

    if (!isArray(role)) {
        role = [role];
    }

    return userRole && role.indexOf(userRole) !== -1;
};

export const isAdmin = (auth) => {
    return hasUserRole(auth, UserRoles.ADMIN);
};

export const isActionPermitted = (requiredAction, availableActions = []) => {
    return availableActions.indexOf(requiredAction) !== -1;
};

export const isAnyActionPermitted = (requiredActions, availableActions = []) => {
    for (let i = 0; i < requiredActions.length; i++) {
        if (isActionPermitted(requiredActions[i], availableActions)) {
            return true;
        }
    }
    return false;
};

export const canUserCreateOfferRequest = (auth) => {
    if (isAdmin(auth)) {
        return true;
    }

    if (auth.user && auth.user.permissions && auth.user.permissions.insuranceTypes) {
        for (let key in auth.user.permissions.insuranceTypes) {
            if (auth.user.permissions.insuranceTypes[key] == 'WRITE') {
                return true;
            }
        }
    }
    return false;
};
