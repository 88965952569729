import React from 'react';
import {FormattedNumber} from 'react-intl';

export const LocalCurrency = ({value, children, ...props}) => value ? (
    <FormattedNumber
        value={value}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
        {...props}
    >
        {children}
    </FormattedNumber>
) : null;

export const LocalNumber = ({value, children, ...props}) =>
    value != undefined && value != null ? (
        <FormattedNumber
            value={value}
            {...props}
        >
            {children}
        </FormattedNumber>
    ) : null;

export const LocalCurrencyWithSymbol = ({value, currency = "CHF", children, ...props}) => value ? (
    <span>
        <LocalCurrency value={value}/>
        <small> {currency}</small>
    </span>
) : null;
