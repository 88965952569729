import React, {useEffect} from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import {DatePicker} from '@material-ui/pickers';
import RadioGroup from '@material-ui/core/RadioGroup';
import {ToggleButtonGroup} from 'react-bootstrap';
import CustomSelect from '../../common/form/select.jsx';
import LocalNumberInput from '../i18n/form/input/number.jsx';
import {l} from '../../../i18n/translator.js';
import {isDefined} from '../../../helper/core.js';
import FileUploadForm from './file-upload/component/singleFileUpload.jsx';
import CheckboxButton from './CheckboxButton';
import {
    CriteriaFields,
    CriteriaNumberRatingTypeFields,
    NumberRatingTypes,
    RatingTypes
} from '../../../../config/domain/analysis';
import {ratingDefaultOptionLabelFor, ratingDefaultOptions, validateWeightPercentageInput} from '../analysis';
import {LocalNumber} from '../i18n/number';

export const withFormControl = (func) => {
    return (props) => {
        let {meta: {/*touched,*/ error}, fullWidth} = props;
        return (
            <FormControl fullWidth={fullWidth || false}
                         error={!!error}>
                {func(props)}
                {error ?
                    (<FormHelperText>{error}</FormHelperText>) : null}
            </FormControl>
        );
    };
};

////////////////////////////////////////

let useEffectDefaultValue = function (input, defaultValue) {
    useEffect(() => {
        if (input && (!isDefined(input.value) || input.value === "") && isDefined(defaultValue)) {
            input.onChange(defaultValue);
        }
    }, []);
};

export const renderTextField = ({input, label, meta, defaultValue, ...custom}) => {
    const {submitting, touched, error} = meta || {};
    const customMessage = custom.error;

    useEffectDefaultValue(input, defaultValue);

    return (
        <TextField
            label={label}
            disabled={submitting}
            {...input}
            {...custom}
            error={(touched && !!error) || !!customMessage}
            helperText={(touched && error) || customMessage}
        />
    );
};

export const renderNumberField = ({input, defaultValue, append, rightAlign, ...props}) => {
    if (append) {
        return renderNumberWithAppendField({input, defaultValue, append, rightAlign, ...props});
    }
    useEffectDefaultValue(input, defaultValue);
    return (
        <LocalNumberInput
            {...input}
            {...props}
            className={rightAlign ? 'right-align' : null}
            component={renderTextField}
        />
    );
};
export const renderCurrencyField = ({currency = 'CHF', ...props}) => {
    return renderNumberWithAppendField({append: currency, ...props});
};
export const renderNumberWithAppendField = ({input, append = '', defaultValue, rightAlign, ...props}) => {
    useEffectDefaultValue(input, defaultValue);
    return (
        <LocalNumberInput
            {...input}
            {...props}
            className={rightAlign ? 'right-align' : null}
            decimals={2}
            InputProps={{
                endAdornment: <InputAdornment position="end"><small
                    className="adornment">{append}</small></InputAdornment>
            }}
            component={renderTextField}
            fullWidth={props.fullWidth}
        />
    );
};

export const renderDatePicker = withFormControl(({input, label, defaultValue, meta: {submitting, touched, error}, ...custom}) => {
    useEffectDefaultValue(input, defaultValue);
    return (
        <DatePicker
            autoOk
            clearable
            label={label}
            format='LL'
            emptyLabel={''}
            okLabel={l('OK')}
            cancelLabel={l('Cancel')}
            disabled={submitting}
            {...input}
            value={input.value || null}
            onChange={(value) => input.onChange(value)}
            onBlur={() => null}
            {...custom}
        />
    );
});

export class renderCheckbox extends React.Component {

    componentDidMount() {
        const {input, defaultValue} = this.props;

        if ((!isDefined(input.value) || input.value === "") && isDefined(defaultValue)) {
            input.onChange(defaultValue);
        }
    }

    render() {
        const {input, label, /*toRemoveIt*/ fullWidth, ...props} = this.props;

        let isChecked = !!input.value;
        delete props.meta; // can not use this on CheckBox (results in error)

        return (
            <CheckboxButton
                {...props}
                label={label}
                checked={isChecked}
                onChange={(event, value) => input.onChange(!isChecked)}
            />
        );
    }
}

export class renderCheckboxWithLink extends React.Component {

    componentDidMount() {
        const {input, defaultValue} = this.props;

        if ((!isDefined(input.value) || input.value === "") && isDefined(defaultValue)) {
            input.onChange(defaultValue);
        }
    }

    render() {
        const {input, label} = this.props;

        let isChecked = !!input.value;

        return (
            <label style={{flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                <Checkbox
                    id={this.props.id}
                    name={this.props.name}
                    style={{width: 'auto', display: 'inline-block'}}
                    checked={isChecked}
                    onChange={(event, value) => input.onChange(!isChecked)}
                />
                {this.props.label}
            </label>
        );
    }
}

export const renderCheckboxGroup = ({input, meta: {touched, error}, renderComponent, ...props}) => {
    const inputValue = input.value || [];
    const Component = renderComponent;

    const onChange = (value) => {
        const index = inputValue.indexOf(value);
        const newInputValue = index === -1 ?
            /*add new element*/
            [...inputValue, value] :
            /*remove element*/
            [...inputValue.slice(0, index), ...inputValue.slice(index + 1)];

        input.onChange(newInputValue);
    };

    const isChecked = (value) => inputValue.indexOf(value) !== -1;

    const CheckboxComponent = ({label, value, ...other}) => (
        <CheckboxButton
            label={label}
            checked={isChecked(value)}
            onChange={() => onChange(value)}
            {...other}
        />
    );

    const Error = () => (touched && error ? <span style={{color: 'red'}}>{error}</span> : null);

    return (
        <Component {...props} {...{Checkbox: CheckboxComponent, Error}} />
    )
};


export class renderToggle extends React.Component {

    componentDidMount() {
        const {input, defaultValue} = this.props;

        if ((!isDefined(input.value) || input.value === "") && isDefined(defaultValue)) {
            input.onChange(defaultValue);
        }
    }

    render() {
        const {input, label} = this.props;

        let isChecked = !!input.value;

        return (
            <FormControlLabel
                control={<Switch value={isChecked} onChange={(value) => input.onChange(!isChecked)}/>}
                label={label}
            />
        );
    }
}

export const renderRadioGroup = ({input, children, defaultValue, isValueInteger, meta: {touched, error}, ...rest}) => {
    useEffectDefaultValue(input, defaultValue);
    return (
        <span>
        <RadioGroup
            {...input}
            {...rest}
            value={typeof input.value !== 'undefined' ? String(input.value) : input.value}
            onChange={(event, value) => {
                if (isValueInteger) value = parseInt(value);
                input.onChange(value);
            }}
        >
            {children}
        </RadioGroup>

        <span style={{color: 'red'}}>
            {touched && error}
        </span>
    </span>
    );
};

export const renderToggleButtonGroup = ({input, children, meta: {touched, error}, ...rest}) => (
    <span>
        <ToggleButtonGroup
            {...input}
            {...rest}
            exclusive
            onChange={(value) => input.onChange(value)}
        >
            {children}
        </ToggleButtonGroup>

        <span style={{color: 'red'}}>
            {touched && error}
        </span>
    </span>
);

export const renderSelectField = ({input, label, options, onChangeIntercept, defaultValue, meta: {touched, error}, children, ...custom}) => {
    useEffectDefaultValue(input, defaultValue);

    if (!('placeholder' in custom)) {
        custom.placeholder = '';
    }

    return (
        <CustomSelect
            label={label}
            name={input.name}
            value={input.value}
            options={options}
            onChange={(value) => {
                if (typeof onChangeIntercept === 'function') onChangeIntercept(value);
                input.onChange(value);
            }}

            error={touched && error}
            {...custom}
        >
            {children}
        </CustomSelect>
    );
};

export const renderSortedSelectField = ({options, ...props}) => {
    if (options) {
        options = [{value: undefined, label: ''}, ...options];
        options.sort((o1, o2) => {
            if (o1.value === undefined) return -1;
            if (o2.value === undefined) return 1;
            return o2.value - o1.value;
        });
    }
    return renderSelectField({options, ...props});
};

export const AnalysisRatingEditField = ({criteria, selectProps, ...props}) => {
    const ratingTypeConfig = criteria[CriteriaFields.RATING_TYPE_CONFIG] || {};
    const input = {...(props.input || {})};
    switch (criteria.ratingType) {
        case RatingTypes.NUMBER:
            let NumberField = renderNumberField;
            const additionalAttributes = {};
            const numberType = ratingTypeConfig && ratingTypeConfig[CriteriaNumberRatingTypeFields.NUMBER_TYPE];
            if (numberType === NumberRatingTypes.CURRENCY || numberType === NumberRatingTypes.PERCENTAGE) {
                additionalAttributes.append = numberType === NumberRatingTypes.CURRENCY ? 'CHF' : '%';
                additionalAttributes.inputProps = {style: {textAlign: 'end'/*, maxWidth: '30px'*/}};

                // validation
                if (numberType === NumberRatingTypes.PERCENTAGE) {
                    const validationResult = validateWeightPercentageInput(input.value);
                    if (typeof validationResult === 'object' && validationResult.valid === false) {
                        additionalAttributes.error = validationResult.message;
                    }
                }
            }
            input.onChange = (event) => {
                const number = parseFloat(event.target.value);
                const ratingData = isNaN(number) ? null : number;

                if (props.input && props.input.onChange) props.input.onChange(ratingData);
            };
            return <NumberField {...props} {...additionalAttributes} input={input}/>;
        case RatingTypes.CHECKBOX:
            let CheckBox = renderCheckbox;
            input.onChange = (value) => {
                const ratingData = value ? 1 : 0;

                if (props.input && props.input.onChange) props.input.onChange(ratingData);
            };
            return <CheckBox
                {...props}
                label={ratingTypeConfig ? ratingTypeConfig.name : null}
                input={input}/>;
        case RatingTypes.OPTIONS:
            let options;
            if (ratingTypeConfig.options) {
                options = ratingTypeConfig.options.map((option) => ({value: option.rating, label: option.name}));
            }
        case RatingTypes.DEFAULT:
        default:
            if (!options) options = ratingDefaultOptions();

            let SelectField = renderSelectField;
            return <SelectField {...props} {...selectProps} options={options}/>;
    }
};

export const AnalysisRatingField = ({ratingData, criteria}) => {
    const ratingTypeConfig = criteria[CriteriaFields.RATING_TYPE_CONFIG] || {};

    switch (criteria[CriteriaFields.RATING_TYPE]) {
        case RatingTypes.NUMBER:
            return <LocalNumber value={ratingData}/>;
        case RatingTypes.CHECKBOX: {
            const iconClassName = ratingData ? 'fa-check-square-o' : 'fa-square-o';
            return (<span><i className={`fa ${iconClassName} m-r-xs`}></i>{ratingTypeConfig.name}</span>);
        }
        case RatingTypes.OPTIONS:
            const options = ratingTypeConfig.options;
            if (ratingData !== undefined && ratingData !== null && options) {
                const option = options.find((item) => item.rating === ratingData);
                return option ? option.name : null;
            }
            return null;
        case RatingTypes.DEFAULT:
        default:
            return ratingDefaultOptionLabelFor(ratingData);
    }
};

export const renderSingleFileUpload = ({meta: {error}, ...fileUploadProps}) => {
    return (
        <div>
            <FileUploadForm
                {...fileUploadProps}
            />

            <div style={{color: 'red'}}>
                {!!fileUploadProps.error && l('An error occurred while uploading file.')}
                {!!error && error}
            </div>
        </div>
    )
};
