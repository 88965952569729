import {CurrentInsuranceFields, Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest';
import {Fields as AuctionFields} from '../../../../../config/domain/offerRequest/offer-round/auction.js';


export const FORM_NAME = 'offer-request-form';


export const Fields = {
    SAME_AS_CONTACT: 'ui.sameAsContact',
    HAS_CURRENT_INSURANCE: 'ui.hasCurrentInsurance',
    TERMS_AND_AGREEMENT: 'ui.acceptTermsAndAgreement',
    CONTACT: OfferRequestFields.CONTACT,

    ID: OfferRequestFields.ID,
    COMPANY_ID: OfferRequestFields.COMPANY_ID,
    INSURANCE_TYPE_ID: OfferRequestFields.INSURANCE_TYPE_ID,

    INSURANCE_ID: OfferRequestFields.REQUEST_FROM_INSURANCE_IDS,
    FIRST_NAME: OfferRequestFields.CONTACT_FIRST_NAME,
    LAST_NAME: OfferRequestFields.CONTACT_LAST_NAME,
    FUNCTION: OfferRequestFields.CONTACT_FUNCTION,
    ADDRESS_1: OfferRequestFields.CONTACT_ADDRESS_LINE1,
    ADDRESS_2: OfferRequestFields.CONTACT_ADDRESS_LINE2,
    ZIP: OfferRequestFields.CONTACT_ZIP,
    TOWN: OfferRequestFields.CONTACT_TOWN,
    COUNTRY: OfferRequestFields.CONTACT_COUNTRY,
    TELEPHONE: OfferRequestFields.CONTACT_TELEPHONE,
    MOBILE: OfferRequestFields.CONTACT_MOBILE,
    EMAIL: OfferRequestFields.CONTACT_EMAIL,

    BUSINESS_IDENTIFICATION_NUMBER: OfferRequestFields.BUSINESS_IDENTIFICATION_NUMBER,
    JOINTLY_INSURED_COMPANIES: OfferRequestFields.JOINTLY_INSURED_COMPANIES,

    CURRENT_INSURANCE: OfferRequestFields.CURRENT_INSURANCE,
    CURRENT_INSURANCE_ID: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.INSURANCE_ID,
    CURRENT_INSURANCE_NAME: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.INSURANCE_NAME,
    CURRENT_INSURANCE_PREMIUM_GROSS: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.PREMIUM_GROSS,
    CURRENT_INSURANCE_PREMIUM_NET: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.PREMIUM_NET,
    CURRENT_INSURANCE_POLICY_NUMBER: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.POLICY_NUMBER,
    CURRENT_INSURANCE_ADJUSTMENTS_ONGOING: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.ADJUSTMENTS_ONGOING,
    CURRENT_INSURANCE_NO_HARM_HISTORY: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.NO_HARM_HISTORY,
    CURRENT_INSURANCE_HARM_HISTORY_FILES: OfferRequestFields.CURRENT_INSURANCE_HARM_HISTORY_FILES,
    CURRENT_INSURANCE_PREMIUM_ANALYSIS_RATINGS: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.PREMIUM_ANALYSIS_RATINGS,
    CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS: OfferRequestFields.CURRENT_INSURANCE + '.' + CurrentInsuranceFields.COVERAGE_ANALYSIS_RATINGS,

    PREVIOUS_INSURANCE_ID: OfferRequestFields.PREVIOUS_INSURANCE_ID,
    PREVIOUS_INSURANCE_POLICY_NUMBER: OfferRequestFields.PREVIOUS_INSURANCE_POLICY_NUMBER,

    INSURANCE_START: OfferRequestFields.DESIRED_INSURANCE_START,
    INSURANCE_PERIOD: OfferRequestFields.INSURANCE_PERIOD_YEARS,
    INSURANCE_YEARLY_TERMINATION: OfferRequestFields.INSURANCE_YEARLY_RIGHT_OF_TERMINATION,
    INSURANCE_BILLING_FREQUENCY: OfferRequestFields.INSURANCE_BILLING_FREQUENCY_MONTHS,
    TARGET_PREMIUM: OfferRequestFields.TARGET_INSURANCE_PREMIUM,

    BROKER: OfferRequestFields.BROKER_ID,
    ADVISORY_MANDATE_FILE: OfferRequestFields.ADVISORY_MANDATE_FILE,
    ATTACHMENTS: OfferRequestFields.ATTACHMENTS,

    OFFER_ROUND_TYPE: OfferRequestFields.OFFER_ROUND_TYPE,
    OFFER_ROUND_END_DATE: OfferRequestFields.OFFER_ROUND_END_DATE,
    OFFER_ROUND_DATA: OfferRequestFields.OFFER_ROUND_DATA,

    DATA: OfferRequestFields.DATA,

    PREMIUM_ANALYSIS_CRITERIA: OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA,
    COVERAGE_ANALYSIS_CRITERIA: OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA,
    UTILITY_ANALYSIS_CRITERIA: OfferRequestFields.UTILITY_ANALYSIS_CRITERIA,
    UTILITY_ANALYSIS_RATINGS: OfferRequestFields.UTILITY_ANALYSIS_RATINGS,

    ACCEPT_TERMS: 'acceptTerms',
    ACCEPT_CONTRACTUAL_FEE_AGREEMENT: 'acceptContractualFeeAgreement'
};

export const MandatoryFields = [
    Fields.INSURANCE_TYPE_ID,
    Fields.FIRST_NAME,
    Fields.LAST_NAME,
    Fields.FUNCTION,
    Fields.ADDRESS_1,
    Fields.ADDRESS_2,
    Fields.ZIP,
    Fields.TOWN,
    Fields.COUNTRY,
    Fields.TELEPHONE,
    Fields.MOBILE,
    Fields.EMAIL,
    Fields.SAME_AS_CONTACT,
    Fields.BUSINESS_IDENTIFICATION_NUMBER,
    Fields.BROKER,
    Fields.ADVISORY_MANDATE_FILE
];

export const CurrentInsurerFields = [
    Fields.CURRENT_INSURANCE_ID,
    Fields.CURRENT_INSURANCE_PREMIUM_GROSS,
    Fields.CURRENT_INSURANCE_PREMIUM_NET,
    Fields.CURRENT_INSURANCE_POLICY_NUMBER,
    Fields.CURRENT_INSURANCE_ADJUSTMENTS_ONGOING,
];

export const ContractDataFields = [
    Fields.INSURANCE_START,
    Fields.INSURANCE_PERIOD,
    Fields.INSURANCE_YEARLY_TERMINATION,
    Fields.INSURANCE_BILLING_FREQUENCY,
    Fields.INSURANCE_ID,
    Fields.TARGET_PREMIUM
];

export const OfferRoundDataFields = [
    Fields.OFFER_ROUND_TYPE,
    Fields.OFFER_ROUND_END_DATE,
    Fields.OFFER_ROUND_DATA,

    // AUCTION
    `${Fields.OFFER_ROUND_DATA}.${AuctionFields.END_AUTO_EXTEND}`,
];

export const SummaryFields = [
    Fields.ACCEPT_TERMS,
    Fields.ACCEPT_CONTRACTUAL_FEE_AGREEMENT
];
