import React from 'react';
import PropTypes from "prop-types";
import {injectIntl, intlShape} from 'react-intl';
import {isDefined} from '../../../../../helper/core';

const JS_DECIMAL_SEPARATOR = '.';
const GROUP_THOUSANDS_REGEX = /\B(?=(\d{3})+(?!\d))/g;
const MAX_CHARS = 15;


/**
 * Returns decimal separator based on intl formatNumber
 *
 * @param {{formatNumber: function}} intl
 * @return {{decimal: string, thousand: string}}
 */
const getSeparators = (intl) => ({
    decimal: intl.formatNumber(0.1).match(/\d(\D+)\d/)[1],
    thousand: intl.formatNumber(1000).match(/\d(\D+)\d/)[1],
});


const escapeRegExp = (str) => {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};

const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

/**
 * @param {string} value
 * @param {{formatNumber: function}} intl
 * @param {number} decimals
 */
const unformatNumber = (value, intl, decimals) => {
    const separators = getSeparators(intl);

    // replace local thousands and decimal separators with JS standard
    let rawValue = replaceAll(value, separators.thousand, '');
    rawValue = rawValue.replace(separators.decimal, JS_DECIMAL_SEPARATOR);

    // remove all chars except numbers and decimal separator
    rawValue = rawValue.replace(new RegExp(`[^\\d${JS_DECIMAL_SEPARATOR}]`, 'g'), '');

    // remove all except first occurrence of the decimal separator
    const separatorIndex = rawValue.indexOf(JS_DECIMAL_SEPARATOR);
    if (separatorIndex !== -1) {
        if (decimals === 0) {
            rawValue = rawValue.substr(0, separatorIndex);
        } else {
            // remove all `.` after the first
            rawValue = rawValue.substr(0, separatorIndex + 1) + rawValue.substr(separatorIndex + 1).replace(JS_DECIMAL_SEPARATOR, '');

            if (decimals) {
                rawValue = rawValue.substr(0, separatorIndex + 1 + decimals);
            }
        }
    }

    // if starts with decimal separator
    // add 0 in front
    if (rawValue.substr(0, 1) === JS_DECIMAL_SEPARATOR) {
        rawValue = '0' + rawValue;
    }

    return rawValue;
};

const formatNumber = (value, intl) => {
    // make value a string
    let formattedValue = value ? value + '' : '';
    const separators = getSeparators(intl);

    // group thousands
    let numberParts = formattedValue.split(JS_DECIMAL_SEPARATOR);
    numberParts[0] = numberParts[0].replace(GROUP_THOUSANDS_REGEX, separators.thousand);

    // join decimal
    formattedValue = numberParts.join(separators.decimal);

    return formattedValue;
};

const calculateSelectionStart = (oldValue, newValue, target, intl) => {
    const oldEndOffset = target.value.length - target.selectionEnd;

    return newValue.length - oldEndOffset;
};


export const DefaultComponent = (props) => (<input type="text" {...props}/>);

export class LocalNumberInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleKeyDown(event) {
        const {intl} = this.props;
        const separators = getSeparators(intl);

        const {key} = event;
        const {value, selectionStart, selectionEnd} = event.target;

        if (selectionStart == selectionEnd) {
            if (key == 'Backspace' && value[selectionStart - 1] == separators.thousand) {
                event.target.selectionStart = selectionStart - 2;
            }
            if (key == 'Delete' && value[selectionEnd] == separators.thousand) {
                event.target.selectionEnd = selectionEnd + 2;
            }
        }
    }

    handleInputChange(event) {
        const {onChange, intl, decimals} = this.props;
        const value = event.target.value;
        const newValue = unformatNumber(value, intl, decimals);

        if (newValue.length > MAX_CHARS) {
            event.preventDefault();
            return;
        }

        const selectionStart = calculateSelectionStart(
            formatNumber(this.props.value, intl),
            formatNumber(newValue, intl),
            event.target,
            intl
        );

        event.target.value = newValue;
        onChange && onChange(event);
        this.setState({...this.state, inputValue: newValue});

        // The caret moving behavior happens after the redux actions finish processing, so we set timeout
        // for synthetic event to work, we have to persist (see https://github.com/erikras/redux-form/issues/1396#issuecomment-251000205)
        event.persist();
        setTimeout(() => {
            event.target.selectionStart = selectionStart;
            event.target.selectionEnd = selectionStart;
        }, 0);
    }

    handleBlur(event) {
        const {onBlur, intl, decimals} = this.props;
        const value = event.target.value;

        event.target.value = unformatNumber(value, intl, decimals);

        onBlur && onBlur(event);
    }

    prepareValueProp() {
        const {value, intl} = this.props;

        if (typeof value === "undefined" || value === null || value === '') {
            return '';
        }

        let valueProp = value + '';

        // support for .00 input (like when writing 1.001)
        if (this.state && isDefined(this.state.inputValue)) {
            if (parseFloat(this.state.inputValue) === value) {
                valueProp = this.state.inputValue;
            }
        }

        valueProp = valueProp.substr(0, MAX_CHARS);
        valueProp = formatNumber(valueProp, intl);

        return valueProp;
    }

    render() {
        const {intl, component, ...props} = this.props;
        let Component;

        if (!component) {
            Component = DefaultComponent;
        } else {
            Component = component;
        }

        return (
            <Component
                {...props}
                value={this.prepareValueProp()}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKeyDown}
            />
        );
    }
}


LocalNumberInput.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func,
    intl: intlShape
};

export default injectIntl(LocalNumberInput);
