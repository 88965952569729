import React from 'react';
import {connect} from 'react-redux';
import {Types as InsuranceTypes} from '../../../../config/domain/insurance.js';
import {InsuranceType} from '../../insurance-offer/common/insuranceType.jsx';

import {Fields, FORM_NAME} from '../../insurance-offer/create-request/config/form';
import {CreditInsuranceForm} from './credit/credit.insuranceData.form.jsx';
import {UVGInsuranceForm} from './uvg/uvg.insuranceData.form.jsx';
import {DefaultInsuranceForm} from './default/default.insuranceData.form.jsx';

import {formValueSelector} from 'redux-form';
import {SickPayInsuranceForm} from './sick_pay/sick_pay.insuranceData.form';

const InsuranceDataForm = ({insuranceTypeId, ...props}) => {
    return (
        <InsuranceType.Container activeType={insuranceTypeId}>
            <InsuranceType.Type type={InsuranceTypes.CREDIT}>
                <CreditInsuranceForm {...props} />
            </InsuranceType.Type>

            <InsuranceType.Type type={InsuranceTypes.UVG}>
                <UVGInsuranceForm {...props} />
            </InsuranceType.Type>

            <InsuranceType.Type type={InsuranceTypes.SICK_PAY}>
                <SickPayInsuranceForm {...props} />
            </InsuranceType.Type>

            <InsuranceType.Default>
                <DefaultInsuranceForm {...props} />
            </InsuranceType.Default>
        </InsuranceType.Container>
    )
};

// Decorate with connect to read form values
const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        insuranceTypeId: selector(state, Fields.INSURANCE_TYPE_ID),
        offerRequestId: selector(state, Fields.ID)
    })
)(InsuranceDataForm);
