import React from 'react';
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {Col, Row} from 'react-bootstrap';
import {l} from '../../../../../i18n/translator';
import {ACTIONS} from '../../../../../../config/domain/entity';
import {isActionPermitted} from '../../../../../auth/helper';
import {fixDecimals, formatPercentageFloat, round} from '../../../../common/analysis';
import {Fields as OfferFields} from '../../../../../../config/domain/offer';
import {CriteriaFields} from '../../../../../../config/domain/analysis';

export default class RatingModal extends React.Component {
    static propTypes = {
        insurances: PropTypes.arrayOf(PropTypes.object).isRequired,
        onShowOffer: PropTypes.func.isRequired,
        onSaveComment: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    showRating(offer, analysisField, rating, criteria) {
        this.setState({
            offer, analysisField, rating, criteria,
            open: true,
            editingComment: false,
            savingComment: false
        });
    }

    showOffer() {
        const offer = this.state.offer;
        this.handleClose();
        this.props.onShowOffer(offer);
    }

    editComment() {
        this.setState({...this.state, editingComment: true, comment: this.state.rating.commentBroker});
    }

    onCommentChange(event) {
        this.setState({...this.state, comment: event.target.value});
    }

    onSaveComment() {
        this.setState({...this.state, savingComment: true});
        this.props.onSaveComment(this.state.offer, this.state.analysisField, this.state.rating, this.state.comment)
            .then(() => {
                this.setState({...this.state, editingComment: false, comment: null, savingComment: null});
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    editingComment: false,
                    comment: this.state.rating.commentBroker,
                    savingComment: null
                });
            });
    }

    handleClose() {
        this.setState({open: false});
    };

    render() {
        if (!this.state.open || !this.state.rating) return null;

        const {offer, criteria, rating} = this.state;
        const {insurances} = this.props;
        const canEditComment = isActionPermitted(ACTIONS.OFFER_ANALYSIS_UPDATE_COMMENT, offer.actions);
        const isEditingComment = this.state.editingComment;
        const isSavingComment = !!this.state.savingComment;

        const insuranceName = offer[OfferFields.INSURANCE_NAME]
            || insurances.find((insurance) => insurance.id === offer[OfferFields.INSURANCE_ID]).name;
        const weight = criteria[CriteriaFields.WEIGHT_PERCENTAGE];
        return <Dialog open={this.state.open} onBackdropClick={this.handleClose.bind(this)}>
            <DialogTitle>{criteria.name}</DialogTitle>
            <DialogContent>
                <Row>
                    <Col sm={3}><b>{l('Insurance')}</b></Col>
                    <Col sm={9}>{insuranceName}</Col>
                </Row>
                <Row>
                    <Col sm={3}><b>{l('Offer id')}</b></Col>
                    <Col sm={9}><Link to="#" onClick={this.showOffer.bind(this)}>{offer.id}</Link></Col>
                </Row>

                <Row className="m-t-md">
                    <Col xs={3}><b>{l('Rating')}</b></Col>
                    <Col xs={1}/>
                    <Col xs={3}><b>{l('Weight')}</b></Col>
                    <Col xs={1}/>
                    <Col xs={4}><b>{l('Points')}</b></Col>
                </Row>
                <Row>
                    <Col xs={3}>{round(rating.rating, 2)}</Col>
                    <Col xs={1}>x</Col>
                    <Col xs={3}>{formatPercentageFloat(weight)}</Col>
                    <Col xs={1}>=</Col>
                    <Col xs={3}><u>{fixDecimals(rating.rating * weight)}</u></Col>
                </Row>

                <h3 className="m-t-md">{l('Comment')}</h3>
                {isEditingComment ? this.renderCommentTextField() : this.renderComment()}
            </DialogContent>
            <DialogActions>
                {canEditComment ? (<Button
                    color="primary"
                    onClick={isEditingComment ? this.onSaveComment.bind(this) : this.editComment.bind(this)}
                    disabled={isSavingComment}>
                    {isEditingComment ? l('Save') : l('Edit comment')}</Button>) : null
                }
                <Button
                    color="primary"
                    onClick={this.handleClose.bind(this)}
                    disabled={isSavingComment}>
                    {l('Close')}
                </Button>
                <Button
                    color="primary"
                    className="pull-left"
                    onClick={this.showOffer.bind(this)}
                    disabled={isSavingComment}>
                    {l('Show Offer')}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    renderComment() {
        const rating = this.state.rating.commentBroker;
        return !!rating ? rating : <i>{l('No comment')}</i>;
    }

    renderCommentTextField() {
        const isSavingComment = !!this.state.savingComment;
        return (
            <TextField id='rating-comment' value={this.state.comment}
                       fullWidth={true} multiline={true} disabled={isSavingComment}
                       onChange={this.onCommentChange.bind(this)}/>);
    }
}
