import React from 'react';
import {Col, Row} from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import {l} from '../../../../../i18n/translator.js';
import {Fields as CreditOfferRequestFields} from '../../../../../../config/domain/offerRequest/credit.js';
import {Field} from '../../../../insurance-offer/create-request/component/redux-form-wrapper.jsx';
import RadioButton from '../../../form/RadioButton.jsx';
import {
    renderCheckbox,
    renderCheckboxGroup,
    renderCurrencyField,
    renderNumberField,
    renderRadioGroup,
    renderSelectField,
    renderTextField
} from '../../../form/renderer.jsx';
import {creditFieldKey as dataFieldKey} from '../../../../insurance-offer/create-request/helper';


export const ManagementOfAccountsReceivableForm = ({currency, isManagementAssessmentNewBuyersInExcess, isManagementAssessmentExistingBuyersInExcess, isRequestYearlyFinancialStatementsSometimes}) => {
    return (
        <div className="m-t-md">
            <h3>
                {l('In order to assess the credit risk associated with each buyer, we request...')}
            </h3>

            <Row>
                <Col md={6}>
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_NEWBUYERS_FREQUENCY)}
                        component={renderSelectField}
                        label={l('For new buyers')}
                        InputLabelProps={{shrink: true}}
                        fullWidth={true}
                    >
                        <MenuItem value={""} primaryText={null}/>
                        <MenuItem value={"never"} primaryText={l("Never")}/>
                        <MenuItem value={"occasionally"} primaryText={l("Occasionally")}/>
                        <MenuItem value={"systematically"} primaryText={l("Systematically")}/>
                        <MenuItem value={"inExcess"} primaryText={l("Orders in excess of")}/>
                    </Field>
                </Col>
                <Col md={6}>
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_EXISTINGBUYERS_FREQUENCY)}
                        component={renderSelectField}
                        label={l('For existing buyers')}
                        InputLabelProps={{shrink: true}}
                        fullWidth={true}
                    >
                        <MenuItem value={""} primaryText={null}/>
                        <MenuItem value={"never"} primaryText={l("Never")}/>
                        <MenuItem value={"occasionally"} primaryText={l("Occasionally")}/>
                        <MenuItem value={"systematically"} primaryText={l("Systematically")}/>
                        <MenuItem value={"inExcess"} primaryText={l("Orders in excess of")}/>
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    {isManagementAssessmentNewBuyersInExcess ? (
                        <Field
                            className="m-t-n-sm"
                            name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_NEWBUYERS_EXCESS)}
                            component={renderCurrencyField}
                            label={l('excess')}
                            InputLabelProps={{shrink: true}}
                            currency={currency}
                            fullWidth={true}
                        />
                    ) : null}
                </Col>
                <Col md={6}>
                    {isManagementAssessmentExistingBuyersInExcess ? (
                        <Field
                            className="m-t-n-sm"
                            name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_EXISTINGBUYERS_EXCESS)}
                            component={renderCurrencyField}
                            label={l('excess')}
                            InputLabelProps={{shrink: true}}
                            currency={currency}
                            fullWidth={true}
                        />
                    ) : null}
                </Col>
            </Row>

            <h4>{l('Our sources')}</h4>
            <Row>
                <Col md={6}>
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_SOURCES_INFORMATIONBUREAUS)}
                        component={renderCheckbox}
                        defaultValue={false}
                        label={l('Information bureaus')}
                    />
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_SOURCES_DEBTCOLLECTIONOFFICES)}
                        component={renderCheckbox}
                        defaultValue={false}
                        label={l('Debt collection offices')}
                    />
                </Col>
                <Col md={6}>
                    <Field
                        className="m-t-n-md"
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_SOURCES_OTHER)}
                        component={renderTextField}
                        label={l('Other')}
                        InputLabelProps={{shrink: true}}
                    />
                </Col>
            </Row>


            <h5>{l('# of credit/financial reports obtained annually')}</h5>
            <Field
                className="m-t-n-md"
                name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_FINANCIALREPORTSOBTAINEDANNUALLY)}
                component={renderNumberField}
            />

            <div className="m-t-md" />

            <h3>
                {l('We request annual financial statements from our customers')}
            </h3>
            <Row>
                <Col md={6}>
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ANNUALFINANCIALSTATEMENTS_ISREQUESTED)}
                        component={renderRadioGroup}
                        label={null}
                    >
                        <RadioButton
                            className="radio-horizontal"
                            value={'never'}
                            label={l('never')}
                        />
                        <RadioButton
                            className="radio-horizontal m-l-lg"
                            value={'sometimes'}
                            label={l('sometimes')}
                        />
                    </Field>
                </Col>
                <Col md={6}>
                    {isRequestYearlyFinancialStatementsSometimes ? (
                        <Field
                            className="m-t-n-sm"
                            name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ANNUALFINANCIALSTATEMENTS_CASES + '[0]')}
                            component={renderTextField}
                            label={l('financial statements request cases')}
                            multiline={true}
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            fullWidth={true}
                        />) : null}
                </Col>
            </Row>


            <h3 className="m-b-md">
                {l('We set credit limits for our buyers')}
            </h3>
            <Row>
                <Col md={6}>
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_CREDITLIMITS)}
                        component={renderRadioGroup}
                        label={null}
                    >
                        <RadioButton
                            className="radio-horizontal"
                            value={true}
                            label={l('Yes')}
                        />
                        <RadioButton
                            className="radio-horizontal m-l-lg"
                            value={false}
                            label={l('No')}
                        />
                    </Field>
                </Col>
                <Col md={6}>
                    <h5>{l('If yes, the credit limits are decided by the:')}</h5>
                    <Field
                        name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_CREDITLIMITSDECISION)}
                        component={renderCheckboxGroup}
                        renderComponent={({Checkbox, Error}) => (
                            <div>
                                <Checkbox
                                    value="salesDepartment"
                                    label={l('Sales department')}
                                />

                                <Checkbox
                                    value="financeDepartment"
                                    label={l('Finance department')}
                                />

                                <Checkbox
                                    value="seniorManagement"
                                    label={l('Senior nanagement')}
                                />

                                <div>
                                    <Error/>
                                </div>
                            </div>
                        )}
                    />
                </Col>
            </Row>

            <div className="m-t-md" />

            <h3>
                {l('We secure our receivables through a...')}
            </h3>
            <Field
                className="m-t-n-md"
                parse={value => value ? [value] : []}
                format={value => value ? value[0] : undefined}
                name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_SECURERECEIVABLES_RETENTIONCASES)}
                component={renderTextField}
                label={l('retention of title, in following cases:')}
                multiline={true}
                rows={3}
                InputLabelProps={{shrink: true}}
                fullWidth={true}
            />

            <Field
                parse={value => value ? [value] : []}
                format={value => value ? value[0] : undefined}
                name={dataFieldKey(CreditOfferRequestFields.MANAGEMENT_SECURERECEIVABLES_BANKGUARANTEECASES)}
                component={renderTextField}
                label={l('bank guarantee, in following cases:')}
                multiline={true}
                rows={3}
                InputLabelProps={{shrink: true}}
                fullWidth={true}
            />
        </div>
    );
};
