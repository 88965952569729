import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';

import {Fields as OfferRequestFields} from '../../../../../../config/domain/offerRequest.js';
import {Fields as OfferFields, States as OfferStates} from '../../../../../../config/domain/offer.js';
import {isDefined} from '../../../../../helper/core';
import {l} from '../../../../../i18n/translator';
import {
    CRITERIA_ID_PREMIUM,
    CriteriaFields,
    CriteriaTypes,
    RatingFields
} from '../../../../../../config/domain/analysis';
import {Fields as CompanyFields} from '../../../../../../config/domain/company';
import {AnalysisRatingField} from '../../../../common/form/renderer';
import {LocalCurrencyWithSymbol} from '../../../../common/i18n/number';
import {formatPercentageFloat} from '../../../../common/analysis';
import {CurrentInsuranceFields} from '../../../../../../config/domain/offerRequest';

export const styles = {
    root: {
        width: '100%',
        marginTop: '8px',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
};

class AnalysisTableCriteriaRow extends React.Component {
    render() {
        const {offerRequest, criteria, targetRatingMap, statisticsForTable} = this.props;

        const criteriaId = criteria[CriteriaFields.ID];
        const criteriaType = criteria[CriteriaFields.TYPE];
        const isPremiumValueCriteria = criteriaId === CRITERIA_ID_PREMIUM;
        const offerRatingField =
            criteriaType === CriteriaTypes.PREMIUM ? OfferFields.PREMIUM_ANALYSIS_RATINGS
                : criteriaType === CriteriaTypes.COVERAGE ? OfferFields.COVERAGE_ANALYSIS_RATINGS
                : null;
        const currentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE];
        const hasCurrentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE];
        const currentInsurancePremium = hasCurrentInsurance ? currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS] : null;
        const currentInsuranceRating = hasCurrentInsurance && currentInsurance[offerRatingField]
            && currentInsurance[offerRatingField].find(rating => rating[RatingFields.CRITERIA_ID] === criteria[CriteriaFields.ID])
            || {};
        const targetInsurancePremium = offerRequest[OfferRequestFields.TARGET_INSURANCE_PREMIUM];

        const getRatingColor = (rating) => {
            const colorBetter = '#dfd';
            const colorWorse = '#fdd';

            if (isPremiumValueCriteria) {
                const premium = rating;
                const compareToPremium = currentInsurancePremium || targetInsurancePremium;

                if (premium) {
                    if (premium < compareToPremium) return colorBetter;
                    else if (premium > compareToPremium) return colorWorse;
                    else return null;
                }
            }

            const targetRating = targetRatingMap.get(criteriaId);
            const compareToRating = isDefined(targetRating) ? targetRating : currentInsuranceRating[RatingFields.RATING];
            if (isDefined(compareToRating) && isDefined(rating)) {
                if (rating < compareToRating) return colorWorse;
                else if (rating > compareToRating) return colorBetter;
                else return null;
            } else return null;
        };

        return (
            <TableRow key={criteria[CriteriaFields.ID]}>
                <TableCell>{criteria[CriteriaFields.NAME]}</TableCell>
                <TableCell>{formatPercentageFloat(criteria[CriteriaFields.WEIGHT_PERCENTAGE])}</TableCell>
                {/*TARGET*/}
                <TableCell className="target"
                           style={{backgroundColor: isPremiumValueCriteria ? null : getRatingColor(criteria[CriteriaFields.TARGET_RATING])}}>
                    {isPremiumValueCriteria ?
                        <LocalCurrencyWithSymbol value={targetInsurancePremium}/>
                        : <AnalysisRatingField criteria={criteria}
                                               ratingData={criteria[CriteriaFields.TARGET_RATING_DATA]}/>}
                </TableCell>
                {/*CURRENT*/}
                {hasCurrentInsurance ?
                    <TableCell className="current"
                               style={{backgroundColor: isPremiumValueCriteria ? null : getRatingColor(currentInsuranceRating[RatingFields.RATING])}}>
                        {isPremiumValueCriteria ?
                            <LocalCurrencyWithSymbol value={currentInsurancePremium}/>
                            : <AnalysisRatingField criteria={criteria}
                                                   ratingData={currentInsuranceRating[RatingFields.RATING_DATA]}/>}
                    </TableCell> : null}
                {/*INSURANCES(offers)*/}
                {statisticsForTable.map((entry, index) => {
                    const offer = entry.bestOfferItem && entry.bestOfferItem.offer;
                    if (isPremiumValueCriteria) {
                        const premium = offer[OfferFields.PREMIUM_GROSS];
                        return <TableCell key={index}
                                          style={{backgroundColor: getRatingColor(premium)}}>
                            <LocalCurrencyWithSymbol value={premium}/>
                        </TableCell>;
                    }

                    const ratings = offer && offer[offerRatingField] || [];
                    const rating = ratings.find(r => r[RatingFields.CRITERIA_ID] === criteriaId);

                    if (rating) {
                        const ratingData = rating[RatingFields.RATING_DATA];
                        return (<TableCell key={index}
                                           style={{backgroundColor: getRatingColor(rating[RatingFields.RATING])}}>
                            <AnalysisRatingField criteria={criteria}
                                                 ratingData={ratingData}/>
                        </TableCell>);
                    } else return <TableCell key={index}>?</TableCell>;
                })}
            </TableRow>
        );
    }
}

class AnalysisTableComponent extends React.Component {
    render() {
        const {offerRequest, statistics, insurances, classes} = this.props;

        const premiumCriteria = offerRequest[OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA];
        const coverageCriteria = offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA];
        const allCriteria = [...premiumCriteria, ...coverageCriteria];
        const targetRatingMap = new Map(allCriteria.map(c => [c[CriteriaFields.ID], c[CriteriaFields.TARGET_RATING]]));

        const insuranceNames = new Map(insurances.map((insurance) => [insurance[CompanyFields.ID], insurance[CompanyFields.NAME]]));

        if (!isDefined(premiumCriteria) || !isDefined(coverageCriteria)
            || premiumCriteria.length === 0 || coverageCriteria.length === 0) {
            return null;
        }

        const statisticsForTable = statistics.filter(item => item.offerItems.some(item => item.offer[OfferFields.STATE] === OfferStates.PUBLISHED && !item.offer[OfferFields.IS_CURRENT]));
        const hasCurrentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE];
        const totalColumns = 3 + (hasCurrentInsurance ? 1 : 0) + statisticsForTable.length;

        return (
            <Paper className={`${classes.root} m-b-md`}>
                <Table className={`${classes.table} analysis-comparison`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{l('Criteria')}</TableCell>
                            <TableCell>{l('Weight %')}</TableCell>
                            <TableCell className="target">{l('Target')}</TableCell>
                            {hasCurrentInsurance ? <TableCell className="current">{l('Current')}</TableCell> : null}
                            {/*Insurances*/}
                            {statisticsForTable.map((entry, index) =>
                                <TableCell key={index} className="insurance">
                                    {insuranceNames.get(entry.insuranceId)}
                                </TableCell>)}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow style={{backgroundColor: '#eee'}}>
                            <TableCell colSpan={totalColumns}>{l('Premium')}</TableCell>
                        </TableRow>
                        {premiumCriteria.map((criteria, index) =>
                            <AnalysisTableCriteriaRow key={index} criteria={criteria} offerRequest={offerRequest}
                                                      targetRatingMap={targetRatingMap}
                                                      statisticsForTable={statisticsForTable}/>)}
                        <TableRow style={{backgroundColor: '#eee'}}>
                            <TableCell colSpan={totalColumns}>{l('Coverage')}</TableCell>
                        </TableRow>
                        {coverageCriteria.map((criteria, index) =>
                            <AnalysisTableCriteriaRow key={index} criteria={criteria} offerRequest={offerRequest}
                                                      targetRatingMap={targetRatingMap}
                                                      statisticsForTable={statisticsForTable}/>)}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export const AnalysisTable = injectIntl(withStyles(styles)(AnalysisTableComponent));

AnalysisTable.propTypes = {
    offerRequest: PropTypes.object.isRequired,
    offers: PropTypes.arrayOf(PropTypes.object).isRequired,
    insurances: PropTypes.arrayOf(PropTypes.object).isRequired,
};
