/**
 * API Protocol
 *
 * @type {string}
 */
export const PROTOCOL = 'https';

/**
 * API Host
 *
 * @type {string}
 */
// export const HOST = 'api.insurtec.ch';
export const HOST = 'api.demo.insurtec.ch';
// export const HOST = 'api.development.insurtec.ch';
// export const HOST = 'localhost:8080';

/**
 * API base URL without trailing slash
 *
 * @type {string}
 */
export const BASE_URL = '';

const getEnvironment = () => {
    switch(HOST) {
        case 'localhost:8080': return 'local';
        case 'api.development.insurtec.ch': return 'development';
        case 'api.staging.insurtec.ch': return 'staging';
        case 'api.demo.insurtec.ch': return 'demo';
        case 'api.insurtec.ch': return 'production';
        default: return 'unknown';
    }
};

export const ENVIRONMENT = getEnvironment();

export const IS_PRODUCTION = ENVIRONMENT == 'production';

export const AUTHORIZATION_HEADER  = "Authorization";
export const AUTHORIZATION_HEADER_SCHEME  = "Bearer";
export const AUTHORIZATION_QUERY_STRING  = "access_token";
