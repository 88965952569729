import {replace} from 'connected-react-router';
import {change, formValueSelector} from 'redux-form';

import {l} from '../../../../i18n/translator';
import {warn} from '../../../../../lib/logger';
import {ACTIONS} from '../../../../../config/domain/entity';

import {CompanyTypes} from '../../../../../config/domain/user';
import {Fields as CompanyFields} from '../../../../../config/domain/company';
import {getUserCompany, isActionPermitted, isUserBroker, isUserOfType} from '../../../../auth/helper';
import {
    fetchAnalysisCriteriaList,
    fetchAnalysisTemplates,
    fetchBrokers,
    fetchCustomers,
    fetchInsurances,
    fetchInsuranceTypes,
    fetchOfferRequest,
    fetchOfferRequestContact
} from '../../../../api/sdk-action';
import {setFormApiData, setInitialFormData} from './form';
import TYPES from './type';
import URI from '../../../../../config/uri.js';
import {Fields, FORM_NAME} from '../config/form';
import {isCreatedFinishedOffer} from '../../../../model/offerRequest';
import {isDefined} from '../../../../helper/core';
import {
    CriteriaFields,
    CriteriaTypes,
    DEFAULT_PREMIUM_CRITERIA,
    RatingFields
} from '../../../../../config/domain/analysis';

export const init = (offerRequestId) => {
    return (dispatch, getState) => {
        const userCompany = getUserCompany(getState().auth);
        const isBroker = isUserOfType(getState().auth, CompanyTypes.BROKER);
        const userContact = userCompany.contact ? userCompany.contact : {};

        dispatch({type: TYPES.PAGE_LOAD_START});

        dispatch(
            loadOfferRequest(offerRequestId)
        ).then(offerRequest => {
            if (offerRequest && isCreatedFinishedOffer(offerRequest)) {
                return dispatch(replace(URI.CREATE_OFFER_REQUEST_FINISHED_EDIT.replace(':id', offerRequest.id)));
            }

            return Promise.all([
                dispatch(fetchInsuranceTypes()),
                dispatch(fetchBrokers()),
                isBroker ? dispatch(fetchCustomers()) : [],
                offerRequest && offerRequest.insuranceTypeId ? dispatch(fetchAnalysisCriteriaList({insuranceType: offerRequest.insuranceTypeId})) : [],
                dispatch(loadContact(offerRequest, userContact))
            ]).then(([insuranceTypes, brokers, customers, analysisCriteria, contact]) => {
                dispatch(dataLoaded('insuranceTypes', insuranceTypes));
                dispatch(dataLoaded('brokers', brokers));
                dispatch(dataLoaded('customers', customers));
                dispatch(dataLoaded('analysisCriteria', analysisCriteria));

                const customer = offerRequest && offerRequest.companyId && customers
                    && customers.find(customer => customer.id === offerRequest.companyId);

                if (null === offerRequest || null !== offerRequest.contact || offerRequest.companyId === offerRequest.creatorCompanyId) {
                    dispatch(dataLoaded('contact', contact));
                } else {
                    // if offer request is created by broker, set the company of the company of the offerRequest
                    const contact = customer.contact;
                    dispatch(dataLoaded('contact', contact ? {...contact, id: null} : contact));
                }

                if (null !== offerRequest) {
                    dispatch(setFormApiData(offerRequest));
                } else {
                    dispatch(setInitialFormData());
                }

                dispatch(pageLoadEnd({isLoaded: true}));

                dispatch(loadAnalysisTemplates({
                    insuranceTypeId: offerRequest && offerRequest[Fields.INSURANCE_TYPE_ID],
                    industryClassificationCode: customer && customer[CompanyFields.INDUSTRY_CLASSIFICATION_CODE]
                }));
            }).catch(error => {
                warn("Error while fetching offer resources!", error);
                let errors = [];

                if (error.requestError) {
                    errors.push(l("Unknown response received from server"));
                } else {
                    errors.push(l("Unknown error occurred"));
                }

                dispatch(pageLoadEnd({errors: errors}));
            });

        }).catch(error => {
            warn("Error while loading offer!", error);

            if (error.notFound || error.forbidden) {
                return dispatch(pageLoadEnd({notFound: true}));
            }

            let errors = [];

            if (error.requestError) {
                errors.push(l("Unknown response received from server"));
            } else {
                errors.push(l("Unknown error occurred"));
            }

            dispatch(pageLoadEnd({errors}));
        });
    };
};

export const onInsuranceTypeChanged = (insuranceType) => {
    return (dispatch) => {
        dispatch(change(FORM_NAME, Fields.INSURANCE_ID, []));
        dispatch(change(FORM_NAME, Fields.CURRENT_INSURANCE_ID, null));
        dispatch(change(FORM_NAME, Fields.PREMIUM_ANALYSIS_CRITERIA, DEFAULT_PREMIUM_CRITERIA));
        dispatch(change(FORM_NAME, Fields.CURRENT_INSURANCE_PREMIUM_ANALYSIS_RATINGS, []));
        dispatch(change(FORM_NAME, Fields.COVERAGE_ANALYSIS_CRITERIA, []));
        dispatch(change(FORM_NAME, Fields.CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS, []));
        dispatch(change(FORM_NAME, Fields.UTILITY_ANALYSIS_CRITERIA, []));
        dispatch(change(FORM_NAME, Fields.UTILITY_ANALYSIS_RATINGS, []));
        dispatch(loadInsurances(insuranceType));
        dispatch(loadAnalysisCriteria(insuranceType));
        dispatch(loadAnalysisTemplates({insuranceTypeId: insuranceType}));
    };
};

export const onCustomerChanged = (customerId) => {
    return (dispatch) => {
        dispatch(loadAnalysisTemplates({customerId}));
    };
};

export const loadInsurances = (insuranceType) => {
    return (dispatch) => dispatch(
        fetchInsurances(insuranceType)
    ).then(insurances =>
        dispatch(dataLoaded('insurances', insurances))
    ).catch(error => {
        if (error.requestError) {
            warn(`Loading insurances for type ${insuranceType} failed`, error);
            return dispatch(dataLoaded('insurances', []));
        }

        throw error;
    });
};

export const loadAnalysisCriteria = (insuranceType) => {
    return (dispatch) => dispatch(
        fetchAnalysisCriteriaList({insuranceType: insuranceType})
    ).then(analysisCriteria =>
        dispatch(dataLoaded('analysisCriteria', analysisCriteria))
    ).catch(error => {
        if (error.requestError) {
            warn(`Loading analysisCriteria for type ${insuranceType} failed`, error);
            return dispatch(dataLoaded('analysisCriteria', []));
        }

        throw error;
    });
};

/**
 * @param props {object} with insuranceTypeId and/or industryClassificationCode and/or customerId
 */
export const loadAnalysisTemplates = (props) => {
    let {insuranceTypeId, industryClassificationCode, customerId} = props || {};
    return (dispatch, getState) => {
        const state = getState();
        const selector = formValueSelector(FORM_NAME);

        customerId = customerId || selector(state, Fields.COMPANY_ID);
        insuranceTypeId = insuranceTypeId || selector(state, Fields.INSURANCE_TYPE_ID);

        if (!isUserBroker(state.auth) || !customerId || !insuranceTypeId) {
            return dispatch(dataLoaded('analysisTemplates', []));
        }

        if (!isDefined(industryClassificationCode)) {
            const customers = state.page.createOfferRequest.data.customers;
            const customer = (customers || []).find(c => c[CompanyFields.ID] === customerId);
            industryClassificationCode = customer[CompanyFields.INDUSTRY_CLASSIFICATION_CODE];
        }

        return dispatch(
            fetchAnalysisTemplates({
                insuranceType: insuranceTypeId,
                industryClassificationCode: industryClassificationCode,
                showInactive: false
            }))
            .then(analysisCriteria =>
                dispatch(dataLoaded('analysisTemplates', analysisCriteria))
            )
            .catch(error => {
                if (error.requestError) {
                    warn(`Loading analysisTemplates for type ${insuranceTypeId} && industry classification code ${industryClassificationCode} failed`, error);
                    return dispatch(dataLoaded('analysisTemplates', []));
                }

                throw error;
            });
    };
};

export const applyAnalysisTemplate = (template) => (dispatch, getState) => {
    const premiumCriteria = template.criteria.filter(criteria => criteria[CriteriaFields.TYPE] === CriteriaTypes.PREMIUM);
    const coverageCriteria = template.criteria.filter(criteria => criteria[CriteriaFields.TYPE] === CriteriaTypes.COVERAGE);

    const premiumCriteriaIds = premiumCriteria.map(criteria=>criteria[CriteriaFields.ID]);
    const coverageCriteriaIds = coverageCriteria.map(criteria=>criteria[CriteriaFields.ID]);
    const selector = formValueSelector(FORM_NAME);
    const state = getState();
    const premiumRatings = (selector(state, Fields.CURRENT_INSURANCE_PREMIUM_ANALYSIS_RATINGS) || []).filter(rating => premiumCriteriaIds.includes(rating[RatingFields.CRITERIA_ID]));
    const coverageRatings = (selector(state, Fields.CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS) || []).filter(rating => coverageCriteriaIds.includes(rating[RatingFields.CRITERIA_ID]));

    dispatch(change(FORM_NAME, Fields.PREMIUM_ANALYSIS_CRITERIA, premiumCriteria.length > 0 ? premiumCriteria : DEFAULT_PREMIUM_CRITERIA));
    dispatch(change(FORM_NAME, Fields.COVERAGE_ANALYSIS_CRITERIA, coverageCriteria));
    dispatch(change(FORM_NAME, Fields.CURRENT_INSURANCE_PREMIUM_ANALYSIS_RATINGS, premiumRatings));
    dispatch(change(FORM_NAME, Fields.CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS, coverageRatings));
};

export const loadContact = (offerRequest, userContact) => {
    return (dispatch) => {
        // return user contact if no offer request or offer request has custom contact
        if (null === offerRequest || offerRequest.contact !== null) {
            return Promise.resolve(userContact);
        }

        return dispatch(
            fetchOfferRequestContact(offerRequest.id)
        );
    };
};

export const loadOfferRequest = (offerRequestId) => {
    return (dispatch) => {
        if (!offerRequestId) {
            return Promise.resolve(null);
        }

        return dispatch(
            fetchOfferRequest(offerRequestId)
        ).then(offerRequest => {
            if (!isActionPermitted(ACTIONS.EDIT, offerRequest.actions)) {
                return Promise.reject({notFound: true});
            }

            return Promise.resolve(offerRequest);
        });
    };
};

export const dataLoaded = (name, values) => {
    return {type: TYPES.DATA_LOADED, data: {[name]: values}};
};

export const pageLoadEnd = (params = {}) => ({
    type: TYPES.PAGE_LOAD_END,
    ...params
});

export const cleanup = () => {
    return {
        type: TYPES.PAGE_RESET
    };
};
