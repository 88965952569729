import {change, getFormValues, SubmissionError, updateSyncErrors} from 'redux-form';

import {buildErrors} from '../../common/form/helper';
import {apiCreateCustomer, apiGetCustomer, apiUpdateCustomer} from '../../../api/sdk-action';
import {companyLookup} from '../../administration/action/api';
import {l} from '../../../i18n/translator';
import {FORM_NAME} from './page.jsx';
import {Fields} from '../../../../config/domain/company';

export const ACTION_TYPES = {
    CREATE_USER_START: 'page.customer_create.started',
    CREATE_USER_END: 'page.customer_create.ended'
};

export const loadCustomer = (customerId) => (dispatch) => {
    return dispatch(
        apiGetCustomer(customerId)
    ).then(customer => {
        dispatch(change(FORM_NAME, Fields.ID, customer[Fields.ID]));
        dispatch(change(FORM_NAME, Fields.NAME, customer[Fields.NAME]));
        dispatch(change(FORM_NAME, Fields.KEY, customer[Fields.KEY]));
        dispatch(change(FORM_NAME, Fields.BUSINESS_IDENTIFICATION_NUMBER, customer[Fields.BUSINESS_IDENTIFICATION_NUMBER]));
        dispatch(change(FORM_NAME, Fields.INDUSTRY_CLASSIFICATION_CODE, customer[Fields.INDUSTRY_CLASSIFICATION_CODE]));
        dispatch(change(FORM_NAME, Fields.CONTACT, customer[Fields.CONTACT]));
    });
};

export const createCustomer = (data, dispatch) => {
    data = {...data};
    data[Fields.CONTACT] = data[Fields.CONTACT] || {};
    return dispatch(
        data[Fields.ID] ? apiUpdateCustomer(data[Fields.ID], data) : apiCreateCustomer(data)
    ).catch((error) => {
        if (error.validationErrors) {
            throw new SubmissionError(buildErrors(error.validationErrors));
        }
        return Promise.reject(error);
    });
};

export const lookupCompany = () => (dispatch, getState) => {
    const formValues = getFormValues(FORM_NAME)(getState()) || {};
    const businessId = formValues[Fields.BUSINESS_IDENTIFICATION_NUMBER];
    if (businessId === undefined) {
        // TODO error message or do not allow button to be pressed at all (disable)?
        return;
    }

    dispatch(updateSyncErrors(FORM_NAME, {}));

    return dispatch(
        companyLookup(businessId)
    ).then(company => dispatch(
        (dispatch) => {
            dispatch(change(FORM_NAME, Fields.NAME, company.name));
            dispatch(change(FORM_NAME, Fields.CONTACT, company.contact));
        }
    )).catch(e => {
        if (!e.requestError) throw e;

        dispatch(updateSyncErrors(FORM_NAME, {
            [Fields.BUSINESS_IDENTIFICATION_NUMBER]: l('Lookup found nothing.')
        }));
    });
};
