import * as queryString from 'query-string';

import API from '../../config/api/routes';
import API_ERRORS from '../../config/api/errors';
import {AttachmentTypes} from '../../config/domain/file';
import {Fields as OfferFields} from '../../config/domain/offer';
import {AutoRatingFields} from '../../config/domain/analysis';
import {
    handleApiValidationErrorResponse,
    handleErrorResponse,
    isBadRequest,
    isForbidden,
    isNotFound
} from '../../lib/http';
import {updateQueryParameter} from '../../lib/url';
import {deleteRequest, jsonGetRequest, jsonPostRequest, postRequest} from './request';
import {authApiUploadFile, makeAuthApiRequest} from './action';
import {objectPropBlankToNull} from '../helper/core';


export const fetchNotifications = () => (dispatch) => dispatch(
    makeAuthApiRequest(API.NOTIFICATIONS)
).then(
    response => response.json()
).catch(
    error => handleErrorResponse(error, response => {
        if (isNotFound(response)) {
            return Promise.reject({notFound: true});
        }
        if (isForbidden(response)) {
            return Promise.reject({forbidden: true});
        }

        return Promise.reject({requestError: response});
    })
);

export const fetchInsuranceTypes = () => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.INSURANCE_TYPES, jsonGetRequest())
    ).then(
        response => response.json()
    ).then(
        insurancesTypes => {
            insurancesTypes.sort((i1, i2) => i1.name.localeCompare(i2.name));
            return insurancesTypes;
        }
    ).catch(e => handleErrorResponse(e, response => {
        return Promise.reject({requestError: response});
    }));
};

export const fetchInsurances = (insuranceType = null) => {
    let uri = API.INSURANCES;

    if (null !== insuranceType) {
        uri = updateQueryParameter(uri, 'insuranceType', insuranceType);
    }

    return (dispatch) => dispatch(
        makeAuthApiRequest(uri, jsonGetRequest())
    ).then(
        response => response.json()
    ).then(
        insurances => {
            insurances.sort((i1, i2) => i1.name.localeCompare(i2.name));
            return insurances;
        }
    ).catch(e => handleErrorResponse(e, response => {
        return Promise.reject({requestError: response});
    }));
};

export const fetchBrokers = () => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.BROKERS, jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        return Promise.reject({requestError: response});
    }));
};

export const fetchCustomers = () => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.CUSTOMERS, jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        return Promise.reject({requestError: response});
    }));
};


export const fetchOfferRequest = (offerRequestId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST.replace(':id', offerRequestId), jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const fetchOffer = (offerId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER.replace(':id', offerId), jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const fetchOfferRequestOffers = (offerRequestId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST_OFFERS.replace(':id', offerRequestId), jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const fetchOfferRequestList = (page = 1, filterBy = null, filterValue = null, pageSize = null) => {
    return (dispatch) => {
        let URI = API.OFFER_REQUESTS;

        // add filter
        if (filterBy) {
            URI = updateQueryParameter(URI, 'filterBy', filterBy);
            URI = updateQueryParameter(URI, 'filterValue', filterValue);
        }

        if (pageSize) {
            URI = updateQueryParameter(URI, 'pageSize', pageSize);
        }

        // add page
        URI = updateQueryParameter(URI, 'page', page);

        return dispatch(
            makeAuthApiRequest(URI, jsonGetRequest())
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            if (isNotFound(response)) {
                return Promise.reject({notFound: true});
            }
            if (isForbidden(response)) {
                return Promise.reject({forbidden: true});
            }

            return Promise.reject({requestError: response});
        }));
    };
};

// TODO all besides API.OFFER is same as fetchOfferRequestList. So probably should refactor!!
export const fetchOffersList = (page = 1, filterBy = null, filterValue = null, pageSize = null) => {
    return (dispatch) => {
        let URI = API.OFFERS;

        // add filter
        if (filterBy) {
            URI = updateQueryParameter(URI, 'filterBy', filterBy);
            URI = updateQueryParameter(URI, 'filterValue', filterValue);
        }

        if (pageSize) {
            URI = updateQueryParameter(URI, 'pageSize', pageSize);
        }

        // add page
        URI = updateQueryParameter(URI, 'page', page);

        return dispatch(
            makeAuthApiRequest(URI, jsonGetRequest())
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            if (isNotFound(response)) {
                return Promise.reject({notFound: true});
            }
            if (isForbidden(response)) {
                return Promise.reject({forbidden: true});
            }

            return Promise.reject({requestError: response});
        }));
    };
};

export const fetchOfferRequestContact = (offerRequestId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST_CONTACT.replace(':id', offerRequestId), jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const uploadOfferRequestAttachment = (offerRequestId, file, onProgress) => {
    return uploadOfferRequestFile(offerRequestId, AttachmentTypes.ATTACHMENT, file, onProgress);
};

export const uploadOfferRequestFile = (offerRequestId, attachmentType, file, onProgress) => {
    return (dispatch) => {
        // set form data
        let formData = new FormData();
        formData.append('file', file);
        formData.append('attachmentType', attachmentType);

        return dispatch(authApiUploadFile(
            API.OFFER_REQUEST_FILES.replace(':id', offerRequestId), postRequest({
                body: formData,
                onProgress
            }))
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            // if response is not expected reject with error
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }

            // process validation errors
            return response.json().then(data => {
                // reject promise
                return Promise.reject({validationErrors: data});
            });
        }));
    };
};

export const deleteOfferRequestFile = (offerRequestId, fileId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_REQUEST_FILE.replace(':id', offerRequestId).replace(':fileId', fileId),
            deleteRequest()
        )
    ).catch(handleErrorResponseDefault);
};


export const deleteOfferRequest = (offerRequestId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST.replace(':id', offerRequestId), deleteRequest())
    ).catch(handleErrorResponseDefault);
};

export const deleteOffer = (offerId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER.replace(':id', offerId),
            deleteRequest()
        )
    ).catch(handleErrorResponseDefault);
};

// {insuranceTypeId, type, showInactive}
export const fetchAnalysisCriteriaList = (queryParams) => {
    let uri = API.ANALYSIS_CRITERIA;
    if (queryParams) {
        uri = uri + '?' + queryString.stringify(queryParams);
    }
    return simpleGetRequest(uri);
};

export const apiUpdateCriteria = (criteriaId, criteriaData) => {
    const uri = API.ANALYSIS_CRITERIA_BY_ID.replace(':id', criteriaId);
    return simplePostRequest(uri, criteriaData);
};

export const apiCreateCriteria = (criteriaData) => {
    return simplePostRequest(API.ANALYSIS_CRITERIA, criteriaData);
};

export const fetchAnalysisCriteria = (criteriaId) =>
    simpleGetRequest(API.ANALYSIS_CRITERIA_BY_ID.replace(':id', criteriaId));

export const fetchAnalysisAutoRatings = () => {
    return simpleGetRequest(API.ANALYSIS_AUTO_RATINGS);
};
export const fetchAnalysisAutoRating = (autoRatingId) => {
    return simpleGetRequest(API.ANALYSIS_AUTO_RATING.replace(':id', autoRatingId));
};

let fixAutoRatingDataFromForm = function (autoRating) {
    objectPropBlankToNull(AutoRatingFields.GENERAL_CONDITIONS_OF_INSURANCE_VERSION, autoRating);
    objectPropBlankToNull(AutoRatingFields.SPECIAL_CONDITIONS_OF_INSURANCE_VERSION, autoRating);
};
export const apiUpdateAutoRating = (autoRatingId, autoRating) => {
    fixAutoRatingDataFromForm(autoRating);
    const uri = API.ANALYSIS_AUTO_RATING.replace(':id', autoRatingId);
    return simplePostRequest(uri, autoRating);
};
export const apiCreateAutoRating = (autoRating) => {
    fixAutoRatingDataFromForm(autoRating);
    return simplePostRequest(API.ANALYSIS_AUTO_RATINGS, autoRating);
};

export const fetchAnalysisTemplates = ({insuranceType, industryClassificationCode, showInactive}) => {
    let uri = API.ANALYSIS_TEMPLATES;
    if (insuranceType || industryClassificationCode) {
        const queryParams = {};
        if (insuranceType) queryParams.insuranceType = insuranceType;
        if (industryClassificationCode) queryParams.industryClassificationCode = industryClassificationCode;
        if (showInactive) queryParams.showInactive = showInactive;
        uri = uri + '?' + queryString.stringify(queryParams);
    }
    return simpleGetRequest(uri);
};
export const fetchAnalysisTemplate = (templateId) =>{
    return simpleGetRequest(API.ANALYSIS_TEMPLATE.replace(':id', templateId));
};
export const apiUpdateTemplate = (templateId, templateData) => {
    return simplePostRequest(API.ANALYSIS_TEMPLATE.replace(':id', templateId), templateData);
};
export const apiCreateTemplate = (templateData) => simplePostRequest(API.ANALYSIS_TEMPLATES, templateData);


export const updateOfferAnalysisRatingComment = (offerId, analysisField, criteriaId, comment) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_ANALYSIS_RATINGS_COMMENT.replace(':id', offerId),
            jsonPostRequest({
                body: JSON.stringify({analysisField, criteriaId, comment})
            })
        )
    ).catch(handleErrorResponseDefault);
};

export const withdrawOfferRequest = (offerRequestId, reason = null) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST_WITHDRAW.replace(':id', offerRequestId), jsonPostRequest({
            body: reason ? JSON.stringify({reason}) : null
        }))
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const rejectOffer = (offerId, reason = null) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_REJECT.replace(':id', offerId),
            jsonPostRequest({
                body: reason ? JSON.stringify({reason}) : null
            }))
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const withdrawOffer = (offerId, reason = null) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_WITHDRAW.replace(':id', offerId),
            jsonPostRequest({
                body: reason ? JSON.stringify({reason}) : null
            }))
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const abstainFromOffer = (offerRequestId, insuranceId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST_ABSTAIN.replace(':id', offerRequestId),
            insuranceId ? jsonPostRequest({body: JSON.stringify({insuranceId: insuranceId})}) : postRequest())
    ).catch(handleErrorResponseDefault);
};

export const publishOfferRequest = (offerRequest) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST_PUBLISH.replace(':id', offerRequest.id), jsonPostRequest({
            body: JSON.stringify(offerRequest)
        }))
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        // process validation errors
        if (isBadRequest(response)) {
            return response.json().then(data => {
                if (data.errors) {
                    return Promise.reject({validationErrors: data.errors});
                } else {
                    return Promise.reject({data: data, requestError: response});
                }
            });
        }
        // process forbidden errors
        if (isForbidden(response)) {
            return response.json().then(data => {
                if (data.error == API_ERRORS.COMPANY_NOT_SET_UP) {
                    return Promise.reject({companyError: API_ERRORS.COMPANY_NOT_SET_UP});
                } else {
                    return Promise.reject({requestError: response});
                }
            });
        }

        return Promise.reject({requestError: response});
    }));
};


export const fetchFinishedOfferRequest = (offerRequestId) => {
    return (dispatch) =>
        dispatch(makeAuthApiRequest(API.OFFER_REQUEST_FINISHED.replace(':id', offerRequestId), jsonGetRequest()))
            .then(response => response.json())
            .catch(handleErrorResponseDefault);
};

export const createFinishedOfferRequest = (data) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUESTS_FINISHED,
            jsonPostRequest({body: JSON.stringify(data)})
        )
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        // process validation errors
        if (isBadRequest(response)) {
            return response.json().then(data => {
                if (data.errors) {
                    return Promise.reject({validationErrors: data.errors});
                } else {
                    return Promise.reject({data: data, requestError: response});
                }
            });
        }
        // process forbidden errors
        if (isForbidden(response)) {
            return response.json().then(data => {
                if (data.error == API_ERRORS.COMPANY_NOT_SET_UP) {
                    return Promise.reject({companyError: API_ERRORS.COMPANY_NOT_SET_UP});
                } else {
                    return Promise.reject({requestError: response});
                }
            });
        }

        return Promise.reject({requestError: response});
    }));
};

export const publishFinishedOfferRequest = (offerRequestId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.OFFER_REQUEST_PUBLISH.replace(':id', offerRequestId),
            jsonPostRequest()
        )
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        // process validation errors
        if (isBadRequest(response)) {
            return response.json().then(data => {
                if (data.errors) {
                    return Promise.reject({validationErrors: data.errors});
                } else {
                    return Promise.reject({data: data, requestError: response});
                }
            });
        }
        // process forbidden errors
        if (isForbidden(response)) {
            return response.json().then(data => {
                if (data.error == API_ERRORS.COMPANY_NOT_SET_UP) {
                    return Promise.reject({companyError: API_ERRORS.COMPANY_NOT_SET_UP});
                } else {
                    return Promise.reject({requestError: response});
                }
            });
        }

        return Promise.reject({requestError: response});
    }));
};

/////////////////////////////
// offer
/////////////////////////////

export const publishOffer = (offer) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_PUBLISH.replace(':id', offer.id),
            jsonPostRequest({
                body: JSON.stringify(offer)
            })
        )
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        // if response is not expected reject with error
        if (!isBadRequest(response)) {
            return Promise.reject({requestError: response});
        }

        // process validation errors
        return response.json().then(data => {
            return Promise.reject({validationErrors: data.errors});
        });
    }));
};

export const validateOffer = (offerRequest, offer) => {
    return (dispatch) => {
        return dispatch(makeAuthApiRequest(
            API.OFFER_VALIDATE, jsonPostRequest({
                body: JSON.stringify(offer)
            })
        )).catch(e => handleErrorResponse(e, response => {
            // if response is not expected reject with error
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }
            // process validation errors
            return response.json().then(data => {
                return Promise.reject({validationErrors: data.errors});
            });
        }));
    };
};

export const saveOffer = (offerRequest, offer) => {
    objectPropBlankToNull(OfferFields.GENERAL_CONDITIONS_OF_INSURANCE_VERSION, offer);
    objectPropBlankToNull(OfferFields.SPECIAL_CONDITIONS_OF_INSURANCE_VERSION, offer);

    return (dispatch) => {
        return dispatch(makeAuthApiRequest(
            API.OFFER_REQUEST_OFFERS.replace(':id', offerRequest.id), jsonPostRequest({
                body: JSON.stringify(offer)
            }))
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }

            // process validation errors
            return response.json().then(data => {
                if (data.error == API_ERRORS.COMPANY_NOT_SET_UP) {
                    return Promise.reject({companyError: API_ERRORS.COMPANY_NOT_SET_UP});
                } else {
                    return Promise.reject({requestError: response, error: data.error, validationErrors: data.errors});
                }
            });
        }));
    };
};


export const requestOfficialOffer = (offerId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_REQUEST_OFFICIAL.replace(':id', offerId),
            postRequest()
        )
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        if (isNotFound(response)) {
            return Promise.reject({notFound: true});
        }
        if (isForbidden(response)) {
            return Promise.reject({forbidden: true});
        }
        if (isBadRequest(response)) {
            // process validation errors
            return response.json().then(data => {
                return Promise.reject({validationErrors: data.errors});
            });
        }

        return Promise.reject({requestError: response});
    }));
};

export const acceptOfficialOffer = (offerId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(
            API.OFFER_ACCEPT.replace(':id', offerId),
            postRequest()
        )
    ).then(
        response => response.json()
    ).catch(e => handleErrorResponse(e, response => {
        if (isNotFound(response)) {
            return Promise.reject({notFound: true});
        }
        if (isForbidden(response)) {
            return Promise.reject({forbidden: true});
        }
        if (isBadRequest(response)) {
            // process validation errors
            return response.json().then(data => {
                return Promise.reject({validationErrors: data.errors});
            });
        }

        return Promise.reject({requestError: response});
    }));
};

export const uploadOfferFile = (offer, file, onProgress) => {
    return (dispatch) => {
        // set form data
        let formData = new FormData();
        formData.append('file', file);

        return dispatch(authApiUploadFile(
            API.OFFER_OFFICIAL_FILE.replace(':id', offer.id), postRequest({
                body: formData,
                onProgress
            }))
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            // if response is not expected reject with error
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }

            // process validation errors
            return response.json().then(data => {
                // reject promise
                return Promise.reject({validationErrors: data});
            });
        }));
    };
};

export const uploadFile = (file, onProgress) => {
    return (dispatch) => {
        // set form data
        let formData = new FormData();
        formData.append('file', file);

        return dispatch(authApiUploadFile(
            API.FILES, postRequest({
                body: formData,
                onProgress
            }))
        ).then(
            response => response.json()
        ).catch(e => handleErrorResponse(e, response => {
            // if response is not expected reject with error
            if (!isBadRequest(response)) {
                return Promise.reject({requestError: response});
            }

            // process validation errors
            return response.json().then(data => {
                // reject promise
                return Promise.reject({validationErrors: data});
            });
        }));
    };
};

export const deleteFile = (fileId) => {
    return (dispatch) => dispatch(
        makeAuthApiRequest(API.FILE.replace(':id', fileId), deleteRequest())
    ).catch(handleErrorResponseDefault);
};

export const fetchLatestMessages = () => (dispatch) => {
    return dispatch(
        makeAuthApiRequest(API.MESSAGES_UNREAD, jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const fetchKpi = () => (dispatch) => {
    return dispatch(
        makeAuthApiRequest(API.KPI, jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const fetchSelectedOfferRequests = () => (dispatch) => {
    return dispatch(
        makeAuthApiRequest(API.OFFER_REQUESTS_SELECTED, jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const updateOfferRequestNotes = (offerRequest, notes) => (dispatch) => {
    return dispatch(makeAuthApiRequest(
        API.OFFER_REQUEST_NOTES.replace(':id', offerRequest.id),
        jsonPostRequest({
            body: JSON.stringify(notes)
        })
    )).then(
        // return passed notes
        () => ({...notes, notesLastModified: (new Date()).toISOString()})
    ).catch(handleErrorResponseDefault);
};

export const updateOfferRequestUtilityAnalysis = (offerRequestId, utilityAnalysis) => (dispatch) => {
    return dispatch(makeAuthApiRequest(
        API.OFFER_REQUEST_UTILITY_ANALYSIS.replace(':id', offerRequestId),
        jsonPostRequest({body: JSON.stringify(utilityAnalysis)})
    )).then(
        // return passed utilityAnalysis
        () => ({...utilityAnalysis})
    ).catch(handleErrorResponseDefault);
};

export const favoriteOfferRequest = (offerRequest) => (dispatch) => {
    return dispatch(makeAuthApiRequest(
        API.OFFER_REQUEST_FAVORITE.replace(':id', offerRequest.id), postRequest()
    )).catch(handleErrorResponseDefault);
};

export const unfavoriteOfferRequest = (offerRequest) => (dispatch) => {
    return dispatch(makeAuthApiRequest(
        API.OFFER_REQUEST_FAVORITE.replace(':id', offerRequest.id), deleteRequest()
    )).catch(handleErrorResponseDefault);
};

export const apiCreateCustomer = (company) => (dispatch) => {
    return dispatch(makeAuthApiRequest(API.CUSTOMERS_CREATE, jsonPostRequest({
            body: JSON.stringify(company)
        }))
    ).then(
        response => response.json()
    ).catch(handleApiValidationErrorResponse);
};

export const apiUpdateCustomer = (customerId, company) => (dispatch) => {
    return dispatch(makeAuthApiRequest(API.CUSTOMER.replace(':id', customerId), jsonPostRequest({
            body: JSON.stringify(company)
        }))
    ).then(
        response => response.json()
    ).catch(handleApiValidationErrorResponse);
};

export const apiGetCustomer = (customerId) => (dispatch) => {
    return dispatch(makeAuthApiRequest(API.CUSTOMER.replace(':id', customerId), jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

export const apiGetCustomerUsers = (customerId, all = false) => (dispatch) => {
    let uri = API.CUSTOMER_USERS.replace(':id', customerId);
    uri = all ? updateQueryParameter(uri, 'all', 'true') : uri;
    return dispatch(makeAuthApiRequest(uri, jsonGetRequest())
    ).then(
        response => response.json()
    ).catch(handleErrorResponseDefault);
};

let removeEmptyUserPermissions = function (user) {
    if (user.permissions && user.permissions.insuranceTypes) {
        user.permissions.insuranceTypes = {...user.permissions.insuranceTypes};
        Object.keys(user.permissions.insuranceTypes).forEach((insuranceType) => {
            if (user.permissions.insuranceTypes[insuranceType] === "") {
                delete user.permissions.insuranceTypes[insuranceType];
            }
        });
    }
};

export const apiPostUser = (user) => {
    const uri = user.id ? API.USER.replace(':id', user.id) : API.USERS;
    removeEmptyUserPermissions(user);
    return (dispatch) => {
        return dispatch(makeAuthApiRequest(uri, jsonPostRequest({
                body: JSON.stringify(user)
            }))
        ).then(response => response.json()
        ).catch(handleApiValidationErrorResponse);
    };
};

export const apiPostCustomerUser = (customerId, user) => {
    const uri = user.id
        ? API.CUSTOMER_USER.replace(':id', customerId).replace(":userId", user.id)
        : API.CUSTOMER_USERS.replace(':id', customerId);
    removeEmptyUserPermissions(user);
    return (dispatch) => {
        return dispatch(makeAuthApiRequest(uri, jsonPostRequest({
                body: JSON.stringify(user)
            }))
        ).then(response => response.json()
        ).catch(handleApiValidationErrorResponse);
    };
};

export const apiEnableCustomerUser = (customerId, userId, enable = true) => {
    return (dispatch) => {
        const uri = API.CUSTOMER_USER_ENABLE.replace(':id', customerId).replace(':userId', userId);
        return dispatch(makeAuthApiRequest(uri, enable ? postRequest() : deleteRequest())
        ).catch(e => handleErrorResponse(e, response => {
            return Promise.reject({requestError: response});
        }));
    };
};

export const apiSendEmailCustomerUser = (customerId, userId) => {
    return (dispatch) => {
        const uri = API.CUSTOMER_USER_EMAIL.replace(':id', customerId).replace(':userId', userId);
        return dispatch(makeAuthApiRequest(uri, postRequest())
        ).catch(e => handleErrorResponse(e, response => {
            return Promise.reject({requestError: response});
        }));
    };
};

///////////////////////////////////////////

const simpleGetRequest = (uri) => (dispatch) => {
    return dispatch(makeAuthApiRequest(uri, jsonGetRequest())
    ).then(response => response.json()
    ).catch(handleErrorResponseDefault);
};

const simplePostRequest = (uri, data) => (dispatch) => {
    return dispatch(makeAuthApiRequest(uri, jsonPostRequest({body: JSON.stringify(data)}))
    ).then(response => response.json()
    ).catch(handleApiValidationErrorResponse);
};

const handleErrorResponseDefault = (e) => handleErrorResponse(e, response => {
    if (isNotFound(response)) {
        return Promise.reject({notFound: true});
    }
    if (isForbidden(response)) {
        return Promise.reject({forbidden: true});
    }

    return Promise.reject({requestError: response});
});
