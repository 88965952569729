import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {change, FormName, formValueSelector, registerField, unregisterField} from 'redux-form';
import {CriteriaTypes} from '../../../../../../config/domain/analysis';
import {AnalysisForm} from '../analysis.form';
import {PremiumAnalysisForm} from '../premiumAnalysis.form';
import {UtilityAnalysisForm} from '../utilityAnalysisTable.form';
import {l} from '../../../../../i18n/translator';

class AnalysisFormFieldsComponent extends React.Component {
    componentDidMount() {
        if (shouldHaveCriteriaField(this.props)) {
            this.props.dispatch(registerField(this.props.formName, this.props.criteriaFieldName, 'Field'));
        }
        if (shouldHaveRatingsField(this.props)) {
            this.props.dispatch(registerField(this.props.formName, this.props.ratingsFieldName, 'Field'));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const hadCriteriaField = shouldHaveCriteriaField(prevProps);
        const hasCriteriaField = shouldHaveCriteriaField(this.props);
        const hadRatingsField = shouldHaveRatingsField(prevProps);
        const hasRatingsFields = shouldHaveRatingsField(this.props);

        // add fields
        if (!hadCriteriaField && hasCriteriaField) {
            this.props.dispatch(registerField(this.props.formName, this.props.criteriaFieldName, 'Field'));
        }
        if (!hadRatingsField && hasRatingsFields) {
            this.props.dispatch(registerField(this.props.formName, this.props.ratingsFieldName, 'Field'));
        }

        // remove fields
        if (hadCriteriaField && !hasCriteriaField) {
            this.props.dispatch(unregisterField(prevProps.formName, prevProps.criteriaFieldName, 'Field'));
        }
        if (hadRatingsField && !hasRatingsFields) {
            this.props.dispatch(unregisterField(prevProps.formName, prevProps.ratingsFieldName, 'Field'));
        }
    }

    componentWillUnmount() {
        if (shouldHaveCriteriaField(this.props)) {
            this.props.dispatch(unregisterField(this.props.formName, this.props.criteriaFieldName, 'Field'));
        }
        if (shouldHaveRatingsField(this.props)) {
            this.props.dispatch(unregisterField(this.props.formName, this.props.ratingsFieldName, 'Field'));
        }
    }

    onCriteriaChange(criteria) {
        if (shouldHaveCriteriaField(this.props)) {
            this.props.dispatch(change(this.props.formName, this.props.criteriaFieldName, criteria));
        }
    }

    onRatingChange(ratings) {
        if (shouldHaveRatingsField(this.props)) {
            this.props.dispatch(change(this.props.formName, this.props.ratingsFieldName, ratings));
        }
    }

    render() {
        const {
            availableCriteria, criteriaType, offerRequest, offers, canEditComment, disabled, criteriaDisabled,
            showRatings, targetRatings
        } = this.props;
        const {criteria, ratings, insurances} = this.props;

        const AnalysisFormComponent =
            (criteriaType === CriteriaTypes.COVERAGE) ? AnalysisForm :
                (criteriaType === CriteriaTypes.PREMIUM) ? PremiumAnalysisForm :
                    (criteriaType === CriteriaTypes.UTILITY) ? UtilityAnalysisForm :
                        null;

        if (!AnalysisFormComponent) return null;

        const pointsTitle = criteriaType === CriteriaTypes.PREMIUM ? l('Premium (%)') : l('Points');
        const assessmentTitle = targetRatings ? l('Requirement') : null;

        return <AnalysisFormComponent availableCriteria={availableCriteria}
                                      criteria={criteria} ratings={ratings}
                                      offerRequest={offerRequest} offers={offers}
                                      disabled={disabled} criteriaDisabled={criteriaDisabled}
                                      canEditComment={canEditComment} showRatings={showRatings}
                                      pointsTitle={pointsTitle} assessmentTitle={assessmentTitle}
                                      targetRatings={targetRatings}
                                      onCriteriaChange={criteria => this.onCriteriaChange(criteria)}
                                      onRatingsChange={ratings => this.onRatingChange(ratings)}/>;
    }
}

function shouldHaveCriteriaField(props) {
    const {disabled, criteriaDisabled, criteriaFieldName} = props;
    return !disabled && !criteriaDisabled && !!criteriaFieldName;
}

function shouldHaveRatingsField(props) {
    return !props.disabled && shouldShowRatings(props);
}

function shouldShowRatings(props) {
    return props.ratingsFieldName && props.showRatings !== false;
}

const mapStateToProps = (state, ownProps) => {
    const result = {};

    const addCriteria = !!ownProps.criteriaFieldName;
    const addRatings = shouldShowRatings(ownProps);

    if (addCriteria || addRatings) {
        const selector = formValueSelector(ownProps.formName);
        if (addCriteria) result.criteria = selector(state, ownProps.criteriaFieldName);
        if (addRatings) result.ratings = selector(state, ownProps.ratingsFieldName);
    }

    return result;
};
const AnalysisFormFieldsComponentConnected = connect(mapStateToProps)(AnalysisFormFieldsComponent);

/////////////////////////////

class AnalysisFormFieldsComponentWrapper extends React.Component {
    render() {
        return (
            <FormName>{({form}) => <AnalysisFormFieldsComponentConnected formName={form} {...this.props}/>}</FormName>);
    }
}

export const AnalysisFormFields = AnalysisFormFieldsComponentWrapper;

AnalysisFormFields.propTypes = {
    availableCriteria: PropTypes.arrayOf(PropTypes.object),
    criteriaType: PropTypes.string.isRequired,

    offerRequest: PropTypes.object,
    offers: PropTypes.arrayOf(PropTypes.object),

    disabled: PropTypes.bool,
    criteriaDisabled: PropTypes.bool,
    showRatings: PropTypes.bool,
    canEditComment: PropTypes.bool,

    criteriaFieldName: PropTypes.string,
    ratingsFieldName: PropTypes.string,

    targetRatings: PropTypes.bool,
};
