import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {l} from '../../../i18n/translator';
import {redirectTo} from '../../../router/action.js';
import URI from '../../../../config/uri';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx'
import * as Ibox from '../../ui-components/ibox.jsx';
import {TemplateFields} from '../../../../config/domain/analysis';
import {initTemplatesList} from './action';

export class TemplatesListPage extends React.Component {

    componentWillMount() {
        this.props.actions.init();
        this.setState({search: ''});
    }

    render() {
        const {dispatch, auth, pageState} = this.props;
        const {data} = pageState;

        const insuranceTypes = [...(data.insuranceTypes || [])];
        const insuranceTypeNameById = {};
        insuranceTypes.forEach((insuranceType) => insuranceTypeNameById[insuranceType.id] = insuranceType.name);

        let templates = [...(data.templates || [])];

        const filter = (this.state.search || '').trim().toLowerCase().split(' ');
        if (filter.length > 0) {
            templates = templates.filter(template => {
                const combinedString = template[TemplateFields.NAME].toLowerCase() + ' '
                    + insuranceTypeNameById[template[TemplateFields.INSURANCE_TYPE_ID]];
                return filter.every(item => combinedString.includes(item));
            });
        }

        templates.sort((a, b) => a[TemplateFields.NAME].localeCompare(b[TemplateFields.NAME]));

        return (
            <InsurTecLayout>
                <BreadCrumbs title={l('Templates')} icon="bar-chart"/>

                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content">
                        <Row>
                            <Col md={12}>
                                <Ibox.Container>
                                    <Ibox.Title>{l('Templates')}</Ibox.Title>

                                    <Ibox.Content className="p-sm">
                                        <TextField value={this.state.search}
                                                   label={l('Filter')}
                                                   onChange={e => this.setState({search: e.target.value})}
                                                   fullWidth/>
                                        <div className="m-b-lg"/>

                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th>{l('Name')}</th>
                                                <th>{l('Insurance type')}</th>
                                                <th>{l('Industry Classification Code (NOGA)')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {templates.map((template, index) => {
                                                const isOwn = template[TemplateFields.BROKER_ID] === auth.user.companyId;
                                                return (
                                                    <tr key={template[TemplateFields.ID]}
                                                        className={!template[TemplateFields.ACTIVE] ? "font-italic gray" : null}
                                                        style={isOwn ? {cursor: 'pointer'} : null}
                                                        onClick={isOwn ? () => dispatch(redirectTo(URI.ANALYSIS_TEMPLATES_EDIT.replace(':id', template[TemplateFields.ID]))) : null}>
                                                        <td>{template[TemplateFields.NAME]}</td>
                                                        <td>{insuranceTypeNameById[template[TemplateFields.INSURANCE_TYPE_ID]]}</td>
                                                        <td>{template[TemplateFields.INDUSTRY_CLASSIFICATION_CODE]}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>

                                        <Button variant="contained"
                                                color="primary"
                                                onClick={() => dispatch(redirectTo(URI.ANALYSIS_TEMPLATES_CREATE))}>
                                            {l('Create template')}
                                        </Button>
                                    </Ibox.Content>
                                </Ibox.Container>
                            </Col>
                        </Row>
                    </div>
                </LoadedPage>
            </InsurTecLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        auth: state.auth,
        user: state.auth.user,
        pageState: {...state.page.analysisTemplatesList}
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: () => dispatch(initTemplatesList())
        },
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplatesListPage);
