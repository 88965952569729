import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';

import {l} from '../../../i18n/translator';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx'
import * as Ibox from '../../ui-components/ibox.jsx';
import {initCriteriaEdit, saveCriteria} from './action.js';
import URI from '../../../../config/uri';
import {CriteriaFields, CriteriaTypes, NumberRatingTypes, RatingTypes} from '../../../../config/domain/analysis';
import {
    renderCheckbox,
    renderCheckboxGroup,
    renderNumberField,
    renderRadioGroup,
    renderTextField
} from '../../common/form/renderer';
import RadioButton from '../../common/form/RadioButton';
import {Field, FieldArray} from '../../insurance-offer/create-request/component/redux-form-wrapper';

export const RatingOptionItem = ({fields, meta: {touched, error, submitting}, disabled, ...other}) => (
    <div>
        <h4>{l('Options')}</h4>
        {fields.map((field, index) => (
            <Row key={index}>
                <Col md={6}>
                    <Field
                        name={`${field}.name`}
                        label={l('Name')}
                        component={renderTextField}
                        disabled={disabled || submitting}
                        fullWidth
                    />
                </Col>
                <Col md={4}>
                    <Field
                        name={`${field}.rating`}
                        label={l('Rating (0-10)')}
                        component={renderNumberField}
                        disabled={disabled || submitting}
                    />
                </Col>
                <Col md={1}>
                    <div className="m-t-md"/>
                    <Button onClick={() => fields.remove(index)} disabled={disabled || submitting}>
                        <i className="fa fa-trash red"/>
                    </Button>
                </Col>
            </Row>
        ))}

        <div className="m-t-md"/>
        <Button color="secondary" onClick={() => fields.push({})} disabled={disabled || submitting}>
            <i className="fa fa-plus m-r-xs"/>{l('Add option')}
        </Button>

        {touched && error && (
            <div className="text-danger">{error}</div>
        )}
    </div>
);

const InsuranceTypesCheckboxList = ({Checkbox, Error, insuranceTypes}) => {
    return (
        <Row>
            <Col md={12} className="m-b-sm">
                <Error/>
            </Col>

            <div className="col-md-12">
                {insuranceTypes.map((insuranceType, index) => (
                    <Checkbox key={index} value={insuranceType.id} label={insuranceType.name}
                              style={{display: 'block'}}/>
                ))}
            </div>
        </Row>
    );
};

export class CriteriaEditPage extends React.Component {

    componentWillMount() {
        let {match: {params = {}}, actions} = this.props;
        let criteriaId = this.isEdit() ? params.id : null;

        this.props.actions.init(criteriaId);
    }

    isEdit() {
        return this.props.match.path === URI.ANALYSIS_CRITERIA_EDIT;
    }

    render() {
        const {ratingType, i18n, handleSubmit, submitting, submitSucceeded, pageState} = this.props;
        const {data} = pageState;

        const insuranceTypes = [...(data.insuranceTypes || [])];
        const criteria = data.criteria || {};

        const insuranceTypeNameById = {};
        insuranceTypes.forEach((insuranceType) => insuranceTypeNameById[insuranceType.id] = insuranceType.name);

        const isUsed = criteria.isUsed;
        return (
            <InsurTecLayout>
                <BreadCrumbs items={[{label: l('Criteria'), uri: URI.ANALYSIS_CRITERIA}]}
                             title={this.isEdit() ? criteria.name || l('Criteria') : l('New criteria')}
                             icon="bar-chart"/>

                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content">
                        <Row>
                            <Col md={12}>
                                <Ibox.Container>
                                    <Ibox.Title>{l('Criteria')}</Ibox.Title>

                                    <Ibox.Content className="p-sm">
                                        {isUsed ? (
                                            <div>{l('Is already used and therefore can only be edited partly.')}</div>
                                        ) : null}
                                        <form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md={6}>
                                                    <Field name={CriteriaFields.TYPE} component={renderRadioGroup} row>
                                                        <RadioButton value={CriteriaTypes.PREMIUM}
                                                                     label={l(`analysis_criteria_type_${CriteriaTypes.PREMIUM}`)}
                                                                     disabled={isUsed || submitting}/>
                                                        <RadioButton value={CriteriaTypes.COVERAGE}
                                                                     label={l(`analysis_criteria_type_${CriteriaTypes.COVERAGE}`)}
                                                                     disabled={isUsed || submitting}/>
                                                        <RadioButton value={CriteriaTypes.UTILITY}
                                                                     label={l(`analysis_criteria_type_${CriteriaTypes.UTILITY}`)}
                                                                     disabled={isUsed || submitting}/>
                                                    </Field>

                                                    <Field name={CriteriaFields.NAME} label={l('Name')}
                                                           component={renderTextField} disabled={submitting} fullWidth/>
                                                    <Field name={CriteriaFields.DESCRIPTION} label={l('Description')}
                                                           component={renderTextField} disabled={submitting} fullWidth/>
                                                    <Field name={CriteriaFields.NOTE_ADMIN} label={l('Note (admin)')}
                                                           component={renderTextField} disabled={submitting} fullWidth/>

                                                    <Field name={CriteriaFields.ACTIVE} label={l('Active')}
                                                           component={renderCheckbox} disabled={submitting}
                                                           defaultValue={true}/>

                                                    <div className="m-t-lg"/>

                                                    <Field name={CriteriaFields.RATING_TYPE}
                                                           component={renderRadioGroup} row>
                                                        <RadioButton value={RatingTypes.DEFAULT}
                                                                     label={l(`analysis_criteria_rating_type_${RatingTypes.DEFAULT}`)}
                                                                     disabled={isUsed || submitting}/>
                                                        <RadioButton value={RatingTypes.NUMBER}
                                                                     label={l(`analysis_criteria_rating_type_${RatingTypes.NUMBER}`)}
                                                                     disabled={isUsed || submitting}/>
                                                        <RadioButton value={RatingTypes.OPTIONS}
                                                                     label={l(`analysis_criteria_rating_type_${RatingTypes.OPTIONS}`)}
                                                                     disabled={isUsed || submitting}/>
                                                        <RadioButton value={RatingTypes.CHECKBOX}
                                                                     label={l(`analysis_criteria_rating_type_${RatingTypes.CHECKBOX}`)}
                                                                     disabled={isUsed || submitting}/>
                                                    </Field>

                                                    {ratingType === RatingTypes.NUMBER ?
                                                        <div className="m-b-lg">
                                                            <Field
                                                                name={`${CriteriaFields.RATING_TYPE_CONFIG}.higherIsBetter`}
                                                                label={l('Higher value is better')}
                                                                component={renderCheckbox}
                                                                disabled={isUsed || submitting}/>

                                                            <h3>{l('Number type')}</h3>
                                                            <Field name={`${CriteriaFields.RATING_TYPE_CONFIG}.numberType`}
                                                                   defaultValue={NumberRatingTypes.DEFAULT}
                                                                   component={renderRadioGroup} row>
                                                                <RadioButton value={NumberRatingTypes.DEFAULT}
                                                                             label={l(`analysis_criteria_number_type_${NumberRatingTypes.DEFAULT}`)}
                                                                             disabled={isUsed || submitting}/>
                                                                <RadioButton value={NumberRatingTypes.CURRENCY}
                                                                             label={l(`analysis_criteria_number_type_${NumberRatingTypes.CURRENCY}`)}
                                                                             disabled={isUsed || submitting}/>
                                                                <RadioButton value={NumberRatingTypes.PERCENTAGE}
                                                                             label={l(`analysis_criteria_number_type_${NumberRatingTypes.PERCENTAGE}`)}
                                                                             disabled={isUsed || submitting}/>
                                                            </Field>
                                                        </div>
                                                        : null}
                                                    {ratingType === RatingTypes.OPTIONS ?
                                                        <div className="m-b-lg">
                                                            <FieldArray
                                                                name={`${CriteriaFields.RATING_TYPE_CONFIG}.options`}
                                                                component={RatingOptionItem}
                                                                disabled={isUsed || submitting}
                                                                i18n={i18n}
                                                            />
                                                        </div>
                                                        : null}
                                                    {ratingType === RatingTypes.CHECKBOX ?
                                                        <div className="m-b-lg">
                                                            <Field
                                                                name={`${CriteriaFields.RATING_TYPE_CONFIG}.name`}
                                                                label={l('Checkbox name')}
                                                                component={renderTextField} disabled={submitting}
                                                                fullWidth/>

                                                            <Row>
                                                                <Col md={6}>
                                                                    <Field
                                                                        name={`${CriteriaFields.RATING_TYPE_CONFIG}.uncheckedRating`}
                                                                        label={l('Rating when NOT checked (0-10)')}
                                                                        defaultValue={0}
                                                                        component={renderNumberField}
                                                                        disabled={isUsed || submitting}
                                                                        fullWidth/>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Field
                                                                        name={`${CriteriaFields.RATING_TYPE_CONFIG}.checkedRating`}
                                                                        label={l('Rating when checked (0-10)')}
                                                                        defaultValue={10}
                                                                        component={renderNumberField}
                                                                        disabled={isUsed || submitting}
                                                                        fullWidth/>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null}
                                                </Col>
                                                <Col md={6}>
                                                    <h3>{l('Insurance types')}</h3>
                                                    <Field name={CriteriaFields.INSURANCE_TYPE_IDS}
                                                           component={renderCheckboxGroup}
                                                           renderComponent={InsuranceTypesCheckboxList}
                                                           insuranceTypes={insuranceTypes}/>
                                                </Col>
                                            </Row>

                                            <Button variant="contained"
                                                    className="m-l-lg"
                                                    color="primary"
                                                    disabled={submitting}
                                                    onClick={handleSubmit}>
                                                {l('Save')}
                                            </Button>
                                        </form>
                                    </Ibox.Content>
                                </Ibox.Container>
                            </Col>
                        </Row>
                    </div>
                </LoadedPage>
            </InsurTecLayout>
        )
    }
}

export const FORM_NAME = 'criteria-edit';
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        ratingType: selector(state, CriteriaFields.RATING_TYPE),
        pageState: {...state.page.analysisCriteriaEdit}
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: (criteriaId) => dispatch(initCriteriaEdit({criteriaId: criteriaId}))
        },
        dispatch
    }
}

export default reduxForm({
    form: FORM_NAME,
    onSubmit: (data, dispatch) => saveCriteria(data, dispatch)
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(CriteriaEditPage));
