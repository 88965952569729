
/**
 * Created by br0wn on 12/5/16.
 */


export const normalizeSalariesData = (ahvSalaries) => {
    ahvSalaries = ahvSalaries || {};
    ahvSalaries = {...ahvSalaries};

    ahvSalaries.salarySumsJobAccidents = ahvSalaries.salarySumsJobAccidents || {};
    ahvSalaries.salarySumsNonJobAccidents = ahvSalaries.salarySumsNonJobAccidents || {};

    return ahvSalaries;
};
