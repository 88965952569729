import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {ScrollContext} from 'react-router-scroll-4';
import MomentUtils from '@date-io/moment';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import {ReactIntlProvider} from './i18n/provider.jsx';
import URI from '../config/uri.js';
import DashboardPage from './ui/dashboard/page.jsx';
import {requireAnonymous, requireAuth, requireUserRole, requireUserType} from './router/accessControl.js';
import OfferRequestListPage from './ui/insurance-offer/request-list/page.jsx';
import CreateOfferRequestPage from './ui/insurance-offer/create-request/page.jsx';
import {CompanyTypes as UserTypes, Roles as UserRoles} from '../config/domain/user.js';
import CreateFinishedOfferRequestPage from './ui/insurance-offer/create-request-finished/page.jsx';
import OfferRequestDetailsPage from './ui/insurance-offer/request-details/page.jsx';
import FilePage from './ui/insurance-offer/file/page.jsx';
import ProfilePage from './ui/profile/page.jsx';
import AdministrationPage from './ui/administration/page.jsx';
import EditUserPage from './ui/users/pages/edit.jsx';
import UserListPage from './ui/users/pages/list.jsx';
import CustomersListPage from './ui/customers/customers-list/page.jsx';
import CustomersCreatePage from './ui/customers/customers-create/page.jsx';
import CustomerUsersPage from './ui/customers/customers-users/page.jsx';
import AutoRatingsListPage from './ui/analysis/auto-ratings-list/page.jsx';
import AutoRatingsEditPage from './ui/analysis/auto-ratings-edit/page.jsx';
import CriteriaListPage from './ui/analysis/criteria-list/page.jsx';
import CriteriaEditPage from './ui/analysis/criteria-edit/page.jsx';
import TemplatesListPage from './ui/analysis/templates-list/page.jsx';
import TemplatesEditPage from './ui/analysis/templates-edit/page.jsx';
import NotFoundPage from './ui/layout/notFound.jsx';
import LoginPage from './ui/auth/login/page.jsx';
import ForgotPasswordPage from './ui/auth/forgot-password/page.jsx';
import VerifyUserPage from './ui/auth/verify-user/page.jsx';
import ResetPasswordPage from './ui/auth/reset-password/page.jsx';
import UpdateOfferRequestDataPage from './ui/insurance-offer/update-request/page.jsx';
import ForbiddenPage from './ui/layout/forbidden.jsx';

export default class Root extends Component {
    render() {
        const shouldUpdateScroll = (prevRouterProps, {location /*, history */}) => {
            if (!prevRouterProps || !location) {
                return;
            }

            return prevRouterProps && location.pathname !== prevRouterProps.location.pathname
        };

        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#DC1200'
                },
                secondary: {
                    main: '#DC1200'
                },
            },
            typography: {
                fontSize: 20,
            },
            mixins: {
                toolbar: {
                    minHeight: 56
                }
            }
        });

        const {store, history} = this.props;
        return (
            <Provider store={store}>
                <ReactIntlProvider>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={store.getState().i18n.locale}>
                            <ConnectedRouter history={history}>
                                <ScrollContext shouldUpdateScroll={shouldUpdateScroll}>
                                    <Switch>
                                        {/*<Route path={URI.DASHBOARD} exact component={DashboardPage}*/}
                                        {/*       onEnter={requireAuth(store)}/>*/}
                                        <Route path={URI.DASHBOARD} exact
                                               render={(props) => conditionalRender(props, requireAuth(store), DashboardPage)}/>

                                        <Route path={URI.OFFER_REQUEST_LIST} exact
                                               render={(props) => conditionalRender(props, requireAuth(store), OfferRequestListPage)}/>

                                        <Route path={URI.CREATE_OFFER_REQUEST}
                                               render={(props) => conditionalRender(props, requireUserType([UserTypes.CUSTOMER, UserTypes.BROKER], store), CreateOfferRequestPage)}/>

                                        <Route path={URI.EDIT_OFFER_REQUEST}
                                               render={(props) => conditionalRender(props, requireUserType([UserTypes.CUSTOMER, UserTypes.BROKER], store), CreateOfferRequestPage)}/>

                                        <Route path={URI.CREATE_OFFER_REQUEST_FINISHED_EDIT}
                                               render={(props) => conditionalRender(props, requireUserType([UserTypes.BROKER], store), CreateFinishedOfferRequestPage)}/>
                                        <Route path={URI.CREATE_OFFER_REQUEST_FINISHED}
                                               render={(props) => conditionalRender(props, requireUserType([UserTypes.BROKER], store), CreateFinishedOfferRequestPage)}/>

                                        <Route path={URI.OFFER_REQUEST_DETAILS} exact
                                               render={(props) => conditionalRender(props, requireAuth(store), OfferRequestDetailsPage)}/>

                                        <Route path={URI.OFFICIAL_OFFER_FILE}
                                               render={(props) => conditionalRender(props, requireAuth(store), FilePage)}/>
                                        <Route path={URI.OFFER_REQUEST_FILE}
                                               render={(props) => conditionalRender(props, requireAuth(store), FilePage)}/>
                                        <Route path={URI.OFFER_REQUEST_FILES_ADVISORY_MANDATE}
                                               render={(props) => conditionalRender(props, requireAuth(store), FilePage)}/>
                                        <Route path={URI.OFFER_REQUEST_FILES_ADVISORY_MANDATE_TEMPLATE}
                                               render={(props) => conditionalRender(props, requireAuth(store), FilePage)}/>
                                        <Route path={URI.FILE}
                                               render={(props) => conditionalRender(props, requireAuth(store), FilePage)}/>

                                        <Route path={URI.PROFILE} exact
                                               render={(props) => conditionalRender(props, requireAuth(store), ProfilePage)}/>

                                        <Route path={URI.ADMINISTRATION}
                                               render={(props) => conditionalRender(props, requireAuth(store), AdministrationPage)}/>
                                        <Route path={URI.USER_CREATE} exact
                                               render={(props) => conditionalRender(props, requireAuth(store), EditUserPage)}/>
                                        <Route path={URI.USER_EDIT}
                                               render={(props) => conditionalRender(props, requireAuth(store), EditUserPage)}/>
                                        <Route path={URI.USER_LIST} exact
                                               render={(props) => conditionalRender(props, requireAuth(store), UserListPage)}/>

                                        {/* Broker only pages */}
                                        <Route path={URI.CUSTOMERS} render={(props) => {
                                            if (!requireUserType(UserTypes.BROKER, store)(props)) return <EmptyComponent/>;
                                            return (
                                                <Switch>
                                                    <Route path={URI.CUSTOMERS} exact component={CustomersListPage}/>
                                                    <Route path={URI.CUSTOMERS_CREATE} component={CustomersCreatePage}/>
                                                    <Route path={URI.CUSTOMERS_EDIT} component={CustomersCreatePage}/>
                                                    <Route path={URI.CUSTOMERS_USERS} component={CustomerUsersPage}/>
                                                    <Route component={NotFoundPage}/>
                                                </Switch>
                                            );
                                        }}/>

                                        {/* Broker only pages */}
                                        <Route path={URI.ANALYSIS} render={(props) => {
                                            if (!requireUserType(UserTypes.BROKER, store)(props) || !requireUserRole(UserRoles.ADMIN, store)(props)) return <EmptyComponent/>;
                                            return (
                                                <Switch>
                                                    <Route path={URI.ANALYSIS_CRITERIA_CREATE} exact
                                                           component={CriteriaEditPage}/>
                                                    <Route path={URI.ANALYSIS_CRITERIA_EDIT} exact
                                                           component={CriteriaEditPage}/>
                                                    <Route path={URI.ANALYSIS_CRITERIA} exact
                                                           component={CriteriaListPage}/>

                                                    <Route path={URI.ANALYSIS_TEMPLATES_CREATE} exact
                                                           component={TemplatesEditPage}/>
                                                    <Route path={URI.ANALYSIS_TEMPLATES_EDIT} exact
                                                           component={TemplatesEditPage}/>
                                                    <Route path={URI.ANALYSIS_TEMPLATES} exact
                                                           component={TemplatesListPage}/>

                                                    <Route path={URI.ANALYSIS_AUTO_RATING_CREATE} exact
                                                           component={AutoRatingsEditPage}/>
                                                    <Route path={URI.ANALYSIS_AUTO_RATING_EDIT} exact
                                                           component={AutoRatingsEditPage}/>
                                                    <Route path={URI.ANALYSIS_AUTO_RATINGS} exact
                                                           component={AutoRatingsListPage}/>

                                                    <Route component={NotFoundPage}/>
                                                </Switch>
                                            );
                                        }}/>

                                        {/* Public pages */}
                                        <Route path={URI.LOGIN}
                                               render={(props) => conditionalRender(props, requireAnonymous(store), LoginPage)}/>
                                        <Route path={URI.FORGOT_PASSWORD}
                                               render={(props) => conditionalRender(props, requireAnonymous(store), ForgotPasswordPage)}/>
                                        {/*<Route path={URI.REGISTER} render={(props) => conditionalRender(props, requireAnonymous(store), RegisterPage)} />*/}
                                        <Route path={URI.VERIFY_USER}
                                               render={(props) => conditionalRender(props, requireAnonymous(store), VerifyUserPage)}/>

                                        {/* Misc pages */}
                                        <Route path={URI.RESET_PASSWORD}
                                               render={(props) => conditionalRender(props, requireAnonymous(store), ResetPasswordPage)}/>
                                        <Route path={URI.UPDATE_OFFER_REQUEST_DATA}
                                               component={UpdateOfferRequestDataPage}/>
                                        <Route path={URI.OFFER_REQUEST_INSURANCE_FILE} component={FilePage}/>
                                        <Route path={URI.OFFER_REQUEST_INSURANCE_ADVISORY_MANDATE}
                                               component={FilePage}/>

                                        {/* Error pages */}
                                        <Route path={URI.ACCESS_FORBIDDEN} component={ForbiddenPage}/>
                                        <Route path="*" component={NotFoundPage}/>
                                    </Switch>
                                </ScrollContext>
                            </ConnectedRouter>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </ReactIntlProvider>
            </Provider>
        );
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};


function conditionalRender(props, condition, WrappedComponent) {
    if (!condition(props)) return (<EmptyComponent/>);

    return <WrappedComponent {...props} />;
}

export class EmptyComponent extends Component {
    render() {
        return <div/>;
    }
}
