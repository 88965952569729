import React from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {formValueSelector, getFormSubmitErrors, reduxForm} from 'redux-form';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import classNames from 'classnames';
import {scroller} from 'react-scroll';
import $ from 'jquery';
import StepMaterial from '@material-ui/core/Step';
import StepButtonMaterial from '@material-ui/core/StepButton';
import StepContentMaterial from '@material-ui/core/StepContent';
import StepperMaterial from '@material-ui/core/Stepper';
import * as queryString from 'query-string';

import URI from '../../../../../config/uri';
import {l} from '../../../../i18n/translator';
import BrokerOnly from '../../../common/auth/acl/brokerOnly.jsx';
import * as WizardSteps from '../config/wizard';
import * as FormActions from '../action/form';
import * as AdvisoryMandateActions from '../action/advisoryMandate';
import CustomerForm from './form/customer.form.jsx';
import InsuranceTypeForm from './form/insuranceType.form.jsx';
import BrokerForm from './form/broker.form.jsx';
import ContactDataForm from './form/contactData.form.jsx';
import CurrentInsurerForm from './form/currentInsurer.form.jsx';
import InsuranceDataForm from '../../../common/insurance-type/insuranceData.form.jsx';
import InsuranceCompaniesForm from './form/insuranceCompanies.form.jsx';
import ContractDataForm from './form/contractData.form.jsx';
import OfferDataForm from './form/offerData.form.jsx';
import OfferAnalysisCurrentRatingForm from './form/offerAnalysisCurrentRating.form.jsx';
import OfferAnalysisTargetForm from './form/offerAnalysisTarget.form.jsx';
import SummaryForm from './form/summary.form.jsx';
import {Fields, FORM_NAME} from '../config/form';
import {hasStepErrors} from '../helper';
import {Step, Wizard} from '../../../common/misc/wizard.jsx';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {redirectTo} from '../../../../router/action.js';
import {updateQueryString} from '../../../../helper/querystring';
import RouteLeavingGuard from '../../../common/page/RouteLeavingGuard';

export const WizardNavItem = ({hasErrors, onSelect, index, activeIndex, isActive, ...props}) => (
    <StepMaterial>
        <StepButtonMaterial icon={index + 1} completed={activeIndex > index} active={isActive}
                            onClick={(e) => onSelect(e, props.id)}>
            {props.title}
        </StepButtonMaterial>
    </StepMaterial>
);

export const WizardNavItemVertical = ({hasErrors, onSelect, index, activeIndex, isActive, children, ...props}) => (
    <StepMaterial>
        <StepButtonMaterial orientation="vertical" icon={index + 1} completed={activeIndex > index} active={isActive}
                            onClick={(e) => onSelect(e, props.id)}>
            {props.title}
        </StepButtonMaterial>
        <StepContentMaterial orientation="vertical" className={classNames({'hidden': !isActive})} active={true}>
            {children}
        </StepContentMaterial>
    </StepMaterial>
);
export const WizardNavItemCustom = ({hasErrors, onSelect, index, activeIndex, isActive, isLastActive, ...props}) => (
    <div className={classNames('step', {
        'complete': activeIndex > index || isLastActive,
        'current': isActive,
        'error': hasErrors
    })} onClick={(e) => onSelect(e, props.id)}>
        <div className="step-label">{props.title}</div>
        <div className="step-dot"></div>
    </div>
);

export const WizardNavbar = ({children, orientation = "horizontal"}) => (
    <StepperMaterial linear={false} orientation={orientation}>
        {children}
    </StepperMaterial>
);
export const WizardNavbarCustom = ({children, isLastActive}) => (
    <div className="row">
        <div className="col-md-2 vbottom">
            <div className="step-line complete"></div>
        </div>
        <div className="col-md-8 vbottom">
            <div className="stepper">
                {children}
            </div>
        </div>
        <div className="col-md-2 vbottom">
            <div className={classNames('step-line', {
                'complete': isLastActive
            })}></div>
        </div>
    </div>
);
// export const WizardNavbar = ({children}) => (
//     <div className="steps clearfix widget m-b-lg" style={{lineHeight: '3em'}}>
//         {children}
//     </div>
// );

export class WizardContentItem extends React.Component {

    render() {
        const {
            id,
            isActive,
            className: classNameProp,
            children,
            title,
            prevStepId,
            onPrevStep,
            nextStepId,
            onNextStep,
            setStep,
            onSave, isSaving, isSaved,
            onSummary,
            onValidate, isValidating, isValidated,
            onPublish, isPublishing,
            ...props
        } = this.props;

        const className = classNames(
            'wizard-content',
            classNameProp,
            {
                hidden: !isActive
            }
        );

        const Navigation = ({className}) => (
            <div className={`pull-right ${className}`}>
                <a className={classNames('back', {'disabled': !prevStepId})}
                   onClick={() => prevStepId && setStep(prevStepId)}><i
                    className="fa fa-arrow-left"></i><span>{l('Back')}</span></a>
                <a className={classNames('next', {'disabled': !nextStepId})}
                   onClick={() => nextStepId && setStep(nextStepId)}><span>{l('Next')}</span><i
                    className="fa fa-arrow-right"></i></a>
            </div>
        );

        return id != 'summary' ? (
            <div id={id} className={`row wrapper wrapper-content text-left ${className}`}>
                <div className="col-md-8 col-md-offset-2">
                    <div className="content clearfix m-b-lg">
                        <Ibox.Container>
                            <Ibox.Title>
                                <h5>
                                    <i className="fa fa-file"/>&nbsp;{title}
                                </h5>
                                <Navigation/>
                            </Ibox.Title>
                            <Ibox.Content>
                                <div className="m-l-sm m-r-sm">
                                    {children}
                                </div>
                            </Ibox.Content>
                        </Ibox.Container>
                        <Navigation className="m-r"/>
                    </div>
                </div>
                <div className="col-md-2">
                    <button disabled={isSaving || isSaved} className="btn btn-block btn-dark" onClick={onSave}>
                        {l('Save request')}
                        {isSaving ? (
                            <i className="fa fa-cog m-l-md"></i>
                        ) : null}

                        {isSaved ? (
                            <i className="fa fa-check m-l-md"></i>
                        ) : null}
                    </button>
                    <button className="btn btn-block btn-default" onClick={onSummary}>
                        {l('Summary')}
                    </button>
                </div>
            </div>
        ) : (
            <div id={id} className={`row wrapper wrapper-content text-left ${className}`}>
                <div className="col-md-8 col-md-offset-2">
                    <div className="content clearfix m-b-lg">
                        {children}
                        <Navigation className="m-r"/>
                    </div>
                </div>
                <div className="col-md-2">
                    <button disabled={isPublishing || isValidating} className="btn btn-block btn-primary"
                            onClick={onValidate}>
                        {l('Validate')}

                        {isValidating ? (
                            <i className="fa fa-cog m-l-md"></i>
                        ) : null}

                        {isValidated ? (
                            <i className="fa fa-check m-l-md"></i>
                        ) : null}
                    </button>
                    <button disabled={isPublishing || isValidating} className="btn btn-block btn-danger"
                            onClick={onPublish}>
                        {l('Publish')}

                        {isPublishing ? (
                            <i className="fa fa-cog m-l-md"></i>
                        ) : null}
                    </button>
                </div>
            </div>
        );
    }
}

export const WizardContent = (all) => {
    const {children} = all;
    return (
        <div>
            {children}
        </div>
    );
};

class CreateOfferRequestWizard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.setStep = this.setStep.bind(this);
    }

    componentWillMount() {
    }

    componentWillUnmount() {
        this.props.destroy();
    }

    componentWillReceiveProps(nextProps) {
        this.setupScrollToStep(nextProps);
        this.setupScrollToError(nextProps);
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        if (query.validateOnInit) {
            setTimeout(() => this.props.handleSubmit(() => this.props.actions.validateForm())(), 100);
        }
    }

    componentDidUpdate() {
        this.doScrollToStep();
        this.doScrollToError();
        this.doScrollToField();
    }

    setupScrollToStep(nextProps) {
        const nextStep = this.getCurrentStep(nextProps);
        const currentStep = this.getCurrentStep();

        if (nextStep && currentStep !== nextStep) {
            this.setState({scrollToStep: nextStep});
        }
    }

    doScrollToStep() {
        const {scrollToStep, scrollToField} = this.state;

        if (!scrollToStep) {
            return;
        }

        // disable scroll to step if scroll to field is set
        if (scrollToField) {
            // make sure it wont scroll after scrollToField is unset
            return this.setState({scrollToStep: undefined});
        }

        const step = `step-${scrollToStep}`;

        setTimeout(() => scroller.scrollTo(step, {
            duration: 300,
            offset: -20,
            smooth: true
        }));

        this.setState({scrollToStep: undefined});
    }

    setupScrollToError(nextProps) {
        // run the code if 'invalid' prop changed from false to true
        const scrollToError = (nextProps.submitFailed && this.props.submitting && !nextProps.submitting) ||
            (nextProps.submitFailed && !this.props.submitFailed);

        this.setState({scrollToError});
    }

    doScrollToError() {
        const {scrollToError} = this.state;

        if (!scrollToError || !this.container) {
            return;
        }

        setTimeout(() => {
            const errorField = $(this.container).find('.field.has-error').first();
            if (errorField.length <= 0) {
                return;
            }

            const stepContent = errorField.parents('.wizard-content').first();
            const step = stepContent.attr('id');
            const field = errorField.attr('name');

            this.setStep(step);

            this.setState({
                scrollToField: field
            })
        }, 200);

        this.setState({scrollToError: false});
    }

    doScrollToField() {
        const {scrollToField} = this.state;

        if (!scrollToField) {
            return;
        }

        setTimeout(() => scroller.scrollTo(scrollToField, {
            duration: 300,
            offset: -20,
            smooth: true
        }), 100);

        this.setState({scrollToField: undefined})
    }


    getCurrentStep(props) {
        props = props || this.props;

        const query = queryString.parse(props.location.search);
        return query.step || '';
    }

    setStep(stepId) {
        const {location, router} = this.props;

        const search = updateQueryString(location.search, {step: stepId});
        this.props.dispatch(redirectTo({
            ...location,
            search: search,
        }));
    }

    jumpToSummary() {
        return this.props.actions.validateForm().then(
            () => this.setStep(WizardSteps.STEP_SUMMARY)
        );
    }

    render() {
        const {i18n, auth, handleSubmit, actions, data, hasCurrentInsurance, errors = {}} = this.props;
        const {dirty, isSaving, isSaved, isValidating, isValidated, isPublishing, isPublished} = this.props;

        const currentStep = this.getCurrentStep();

        const stepProps = {i18n, auth, ...data, currentStep, setStep: this.setStep, WizardContent};

        const {location, match: {params}, route, router, routeParams, dispatch} = this.props;
        const routerProps = {location, params, route, router, routeParams, dispatch};

        if (isPublished) {
            return (
                <div>
                    <h1>
                        {l('Offer request #:number').replace(':number', data.offerRequest.id)}
                    </h1>
                    <p>
                        {l('Thank you for publishing your offer request. Our system will now get to work to find the best insurance premium possible')}
                        {l('Please see the offer details in the link bellow.')}
                    </p>
                    <p>
                        <Link to={URI.OFFER_REQUEST_DETAILS.replace(':id', data.offerRequest.id)}>
                            <Button bsStyle="primary">
                                {l('Go to details')}
                            </Button>
                        </Link>
                    </p>
                </div>
            )
        }

        // render
        return (
            <div ref={node => this.container = node}>
                <RouteLeavingGuard when={location => {
                                       return dirty && !isPublished && location.pathname !== this.props.location.pathname;
                                   }}
                                   title={l('You have unsaved data')}
                                   message={l('If you leave now, all changes will be lost. Are you sure you want to leave?')}/>

                <Wizard
                    defaultActive={WizardSteps.FIRST_STEP}
                    activeStep={currentStep}
                    className="wizard clearfix"
                    navbarComponent={WizardNavbarCustom}
                    onSelect={(e, id) => this.setStep(id)}
                    onSave={handleSubmit(() => actions.save(currentStep))}
                    {...{
                        isSaving, isSaved, isValidating, isValidated, isPublishing, isPublished,
                        onSummary: handleSubmit(this.jumpToSummary.bind(this)),
                        onValidate: handleSubmit(actions.validate),
                        onPublish: handleSubmit(actions.publish)
                    }}
                    {...routerProps}
                >
                    <Step
                        id={WizardSteps.STEP_BASIC_DATA}
                        title={l('Basic data')}
                        navComponent={WizardNavItemCustom}
                        contentComponent={WizardContentItem}
                        navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_BASIC_DATA)}}
                    >
                        <WizardContent>
                            <BrokerOnly auth={auth}>
                                <CustomerForm className="m-b-xl" {...stepProps} />
                            </BrokerOnly>
                            <InsuranceTypeForm {...stepProps} />
                            <ContactDataForm className="m-t-xl m-b-xl" {...stepProps} />
                            <BrokerForm {...stepProps} removeAdvisoryMandate={actions.removeAdvisoryMandate}/>
                        </WizardContent>
                    </Step>

                    <Step
                        id={WizardSteps.STEP_CURRENT_INSURER}
                        title={l('Current Insurer')}
                        navComponent={WizardNavItemCustom}
                        contentComponent={WizardContentItem}
                        navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_CURRENT_INSURER)}}
                    >
                        <WizardContent>
                            <CurrentInsurerForm {...stepProps} />
                        </WizardContent>
                    </Step>

                    <Step
                        id={WizardSteps.STEP_CONTRACT_DATA}
                        title={l('Contract Data')}
                        navComponent={WizardNavItemCustom}
                        contentComponent={WizardContentItem}
                        navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_CONTRACT_DATA)}}
                    >
                        <WizardContent>
                            <ContractDataForm {...stepProps} />
                            <InsuranceCompaniesForm {...stepProps} />
                        </WizardContent>
                    </Step>

                    <Step
                        id={WizardSteps.STEP_INSURANCE_DATA}
                        title={l('Insurance Data')}
                        navComponent={WizardNavItemCustom}
                        contentComponent={WizardContentItem}
                        navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_INSURANCE_DATA)}}
                    >
                        <InsuranceDataForm
                            {...stepProps}
                            prevStep={WizardSteps.STEP_CONTRACT_DATA}
                            nextStep={WizardSteps.STEP_OFFER_DATA}
                        />
                    </Step>

                    <Step
                        id={WizardSteps.STEP_OFFER_DATA}
                        title={l('Offer Round')}
                        navComponent={WizardNavItemCustom}
                        contentComponent={WizardContentItem}
                        navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_OFFER_DATA)}}
                    >
                        <WizardContent>
                            <OfferDataForm {...stepProps} />
                        </WizardContent>
                    </Step>

                    <Step
                        id={WizardSteps.STEP_ANALYSIS}
                        title={l('Analysis')}
                        navComponent={WizardNavItemCustom}
                        contentComponent={WizardContentItem}
                        navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_ANALYSIS)}}
                    >
                        <WizardContent>
                            <OfferAnalysisTargetForm {...stepProps} />
                        </WizardContent>
                    </Step>

                    {hasCurrentInsurance ?
                        <Step
                            id={WizardSteps.STEP_ANALYSIS_NOW}
                            title={l('Analysis (now)')}
                            navComponent={WizardNavItemCustom}
                            contentComponent={WizardContentItem}
                            navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_ANALYSIS_NOW)}}
                        >
                            <WizardContent>
                                <OfferAnalysisCurrentRatingForm {...stepProps} />
                            </WizardContent>
                        </Step> : null}

                    <Step
                        id={WizardSteps.STEP_SUMMARY}
                        title={l('Summary')}
                        navComponent={WizardNavItemCustom}
                        contentComponent={WizardContentItem}
                        navProps={{hasErrors: hasStepErrors(errors, WizardSteps.STEP_SUMMARY)}}
                    >
                        <div className="text-left h-400">
                            <SummaryForm {...stepProps} />
                        </div>
                    </Step>
                </Wizard>

                <div className="h-100"/>
            </div>
        )
    };
}


export const CreateOfferRequestWizardForm = reduxForm({
    form: FORM_NAME, // a unique name for this form,
    enableReinitialize: true
})(CreateOfferRequestWizard);

const selector = formValueSelector(FORM_NAME);

export default withRouter(connect(
    state => {
        const {
            data = {},
            isSaving, isSaved,
            isValidating, isValidated,
            isPublishing, isPublished,
            advisoryMandateUpload,
            form
        } = state.page.createOfferRequest;

        return {
            data,
            isSaving, isSaved,
            isValidating, isValidated,
            isPublishing, isPublished,
            advisoryMandateUpload,
            i18n: state.i18n,
            auth: state.auth,
            hasCurrentInsurance: selector(state, Fields.HAS_CURRENT_INSURANCE) === 'yes',
            errors: getFormSubmitErrors(FORM_NAME)(state),
            initialValues: form.values
        }
    },
    dispatch => ({
        actions: {
            ...bindActionCreators({
                save: FormActions.save,
                validate: FormActions.validate,
                publish: FormActions.publish,
                cancel: FormActions.cancel,
                validateForm: FormActions.validateForm,
                removeAdvisoryMandate: AdvisoryMandateActions.removeAdvisoryMandate
            }, dispatch)
        }
    })
)(CreateOfferRequestWizardForm));
