import ReconnectingEventSource from 'reconnecting-eventsource';

export class AppEventSource {

    constructor({
        url,
        keepAlive = 15000
    }) {
        this.url = url;
        this.keepAlive = keepAlive;

        this.keepAliveHandle = null;
        this._init();
    }

    _init() {
        if (!this.source) {
            this.source = new ReconnectingEventSource(this.url, {});
            console.log("Initializing SSE socket"); // eslint-disable-line no-console
        // } else {
        //     this.source.reconnect();
        //     console.log("Reinitializing SSE socket"); // eslint-disable-line no-console
        }
        this._scheduleTimeout();
    }

    _scheduleTimeout() {
        if (this.keepAliveHandle != null) {
            clearTimeout(this.keepAliveHandle);
        }

        this.keepAliveHandle = setTimeout(this._init.bind(this), this.keepAlive);
    }

    close() {
        if (this.keepAliveHandle != null) {
            clearTimeout(this.keepAliveHandle);
        }

        if (this.source) {
            this.source.close();
            this.source = null;
        }
    }

    addEventListener(event, handler, useCapture) {
        if (!this.source) {
            throw new Error("Source not initialized");
        }

        const wrappedHandler = (...args) => {
            this._scheduleTimeout();
            handler(...args);
        };

        return this.source.addEventListener(event, wrappedHandler, useCapture);
    }
}
