import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@material-ui/core';

import {l} from '../../../../i18n/translator';
import {
    createRatingCalculator,
    criteriaArraysEqual,
    fixWeightRounding,
    formatPercentageFloat,
    formatPoints,
    getDefaultRatingData,
    parseFloatDefault,
    sortAnalysisCriteria,
} from '../../analysis';
import CustomSelect from '../select';
import {CRITERIA_ID_PREMIUM, CriteriaFields, CriteriaTypes, RatingFields} from '../../../../../config/domain/analysis';
import {AnalysisRatingEditField, AnalysisRatingField} from '../renderer';
import {isDefined} from '../../../../helper/core';

export const styles = {
    root: {
        width: '100%',
        marginTop: '8px',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
};

export class AnalysisBaseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {expanded: [], sortedCriteria: []};
    }

    componentDidMount() {
        this.initRatingsWithDefaultRatingData();
        this.updateStateCriteria();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateStateCriteria();
    }

    updateStateCriteria() {
        let criteria = (this.props.criteria || []).map(c => {
            if (!c[CriteriaFields.NAME]) {
                return {...this.getCriteriaById(c[CriteriaFields.ID]), ...c};
            }
            return c;
        });
        criteria = sortAnalysisCriteria(criteria);

        if (!criteriaArraysEqual(this.state.sortedCriteria, criteria)) {
            this.setState({...this.state, sortedCriteria: criteria});
        }
    }

    initRatingsWithDefaultRatingData() {
        // TODO handle correct for utility ratings where we have insuranceIds?
        if (this.getAnalysisType() === CriteriaTypes.UTILITY) return;

        const toUpdate = []; // {criteriaId, ratingData}
        for (let criteria of this.getSelectedCriteria()) {
            const rating = this.getRatings().find(rating => rating[RatingFields.CRITERIA_ID] === criteria[CriteriaFields.ID]);
            if (!rating || !isDefined(rating[RatingFields.RATING_DATA])) {
                const defaultRatingData = getDefaultRatingData(criteria);
                if (isDefined(defaultRatingData)) {
                    toUpdate.push({criteriaId: criteria[CriteriaFields.ID], ratingData: defaultRatingData});
                }
            }
        }

        if (toUpdate.length > 0) {
            let ratings = [...this.getRatings()];
            toUpdate.forEach(({criteriaId, ratingData}) => {
                this.updateRatingsItemInArray(ratings, {
                    criteriaId,
                    fieldName: RatingFields.RATING_DATA,
                    fieldValue: ratingData,
                });
            });

            this.onRatingsChange(ratings, {criteria: this.props.criteria});
            this.onChange(this.props.criteria, ratings);
        }
    }

    /////////////////////////////////////////////////////////////////////////

    createRatingCalculator() {
        return createRatingCalculator(this.props.offerRequest, this.props.offers, {
            criteria: this.getSelectedCriteria(),
            criteriaType: this.getAnalysisType(),
        });
    }

    /////////////////////////////////////////////////////////////////////////

    getAnalysisType() {
        return CriteriaTypes.COVERAGE;
    }

    isPremiumAnalysisForm() {
        return this.getAnalysisType() === CriteriaTypes.PREMIUM;
    }

    getNonEditableCriteriaIds() {
        return [];
    }

    /////////////////////////////////////////////////////////////////////////

    getCriteriaById(criteriaId) {
        return this.getAvailableCriteria().find((item) => item.id === criteriaId);
    }

    getAvailableCriteria() {
        return this.props.availableCriteria || this.props.criteria || [];
    }

    getSelectedCriteria() {
        return this.state.sortedCriteria;
    }

    getUnusedCriteria() {
        if (!this.canEditCriteria()) return [];

        let usedCriteria = this.getSelectedCriteria();
        let usedCriteriaIds = usedCriteria.map(criteria => criteria[CriteriaFields.ID]);

        const analysisType = this.getAnalysisType();
        return this.getAvailableCriteria()
            .filter(criteria => criteria.id !== CRITERIA_ID_PREMIUM && criteria.type === analysisType && !usedCriteriaIds.includes(criteria.id));
    }

    getUnusedCriteriaOptions(additionalCriteria) {
        const criteria = this.getUnusedCriteria();
        if (additionalCriteria) criteria.push(additionalCriteria);

        return this.convertCriteriaToOptions(criteria);
    }

    convertCriteriaToOptions(criteria) {
        return criteria.map(criteria => ({value: criteria.id, label: criteria.name}));
    }

    getRatings() {
        if (this.useRatingsAsTarget()) {
            return this.getSelectedCriteria().map(criteria => {
                const rating = {};
                rating[RatingFields.CRITERIA_ID] = criteria[CriteriaFields.ID];
                rating[RatingFields.RATING] = criteria[CriteriaFields.TARGET_RATING];
                rating[RatingFields.RATING_DATA] = criteria[CriteriaFields.TARGET_RATING_DATA];
                return rating;
            });
        }
        return this.props.ratings || [];
    }

    getCriteriaRating(criteriaId) {
        return this.getRatings().find(rating => rating[RatingFields.CRITERIA_ID] === criteriaId);
    }

    /////////////////////////////////////////////////////////////////////

    canEditComment() {
        return !this.props.disabled || this.props.canEditComment;
    }

    canEditCriteria() {
        return !this.props.disabled && !this.props.criteriaDisabled;
    }

    showRatings() {
        const {showRatings = true} = this.props;
        return showRatings;
    }

    showComments() {
        return !this.useRatingsAsTarget() && this.showRatings();
    }

    renderPoints({criteria, rating, points, ratingCalculator}) {
        return formatPoints(points);
    }

    useRatingsAsTarget() {
        return this.props.targetRatings;
    }

    ////////////////////////////////////////////////////////////////////////////

    addCriteria(criteria) {
        const newCriteria = [...this.getSelectedCriteria(), criteria];
        this.onCriteriaChange(newCriteria, {ratings: this.props.ratings});
        this.onChange(newCriteria, this.props.ratings);
    }

    removeCriteria(criteria) {
        const criteriaId = criteria[CriteriaFields.ID];
        const newRatings = this.getRatings().filter(rating => rating[RatingFields.CRITERIA_ID] !== criteriaId);
        const newCriteria = this.getSelectedCriteria().filter(c => c[CriteriaFields.ID] !== criteriaId);

        // first we need to update ratings (otherwise we have a problem with criteria not being removed)
        this.onRatingsChange(newRatings, {criteria: newCriteria});
        this.onCriteriaChange(newCriteria, {ratings: newRatings});
        this.onChange(newCriteria, newRatings);
    }

    replaceCriteria(criteria, newCriteria) {
        const criteriaId = criteria[CriteriaFields.ID];
        const newRatings = this.getRatings().filter(rating => rating[RatingFields.CRITERIA_ID] !== criteriaId);
        let updatedCriteria = [...this.getSelectedCriteria()];
        let indexOfCriteria = updatedCriteria.findIndex(c => c[CriteriaFields.ID] === criteriaId);
        if (indexOfCriteria >= 0) updatedCriteria.splice(indexOfCriteria, 1, newCriteria);
        else updatedCriteria.push(newCriteria);

        // first we need to update ratings (otherwise we have a problem with criteria not being removed)
        this.onRatingsChange(newRatings, {criteria: updatedCriteria});
        this.onCriteriaChange(updatedCriteria, {ratings: newRatings});
        this.onChange(updatedCriteria, newRatings);
    }

    moveCriteria(criteriaId, movePositions) {
        const criteria = this.getSelectedCriteria();
        const index = criteria.findIndex(c => c[CriteriaFields.ID] === criteriaId);
        const otherIndex = index + movePositions;

        const newCriteria = [...criteria];
        const temp = newCriteria[index];
        newCriteria[index] = newCriteria[otherIndex];
        newCriteria[otherIndex] = temp;

        this.onCriteriaChange(newCriteria, {ratings: this.props.ratings});
        this.onChange(newCriteria, this.props.ratings);
    }

    canMoveCriteria(criteriaId, movePositions) {
        if (criteriaId === CRITERIA_ID_PREMIUM) return false;

        const criteria = this.getSelectedCriteria();
        const index = criteria.findIndex(c => c[CriteriaFields.ID] === criteriaId);

        if (movePositions === -1) {
            if (index <= 0) return false;
            if (index === 1 && criteria[0][CriteriaFields.ID] === CRITERIA_ID_PREMIUM) return false;
        } else if (movePositions === 1) {
            if (index >= criteria.length - 1) return false;
        }

        return true;
    }

    toggleRow(criteriaId) {
        if (this.state.expanded.includes(criteriaId)) {
            this.setState(() => ({
                ...this.state,
                expanded: this.state.expanded.filter(x => x !== criteriaId)
            }));
        } else {
            this.setState(() => ({
                ...this.state,
                expanded: [...this.state.expanded, criteriaId]
            }));
        }
    }

    updateCriteriaProps(criteriaId, {...props}) {
        const criteria = [...this.getSelectedCriteria()];
        const criteriaIndex = criteria.findIndex(c => c[CriteriaFields.ID] === criteriaId);
        criteria[criteriaIndex] = {...criteria[criteriaIndex], ...props}; // replace props

        this.onCriteriaChange(criteria, {ratings: this.props.ratings});
        this.onChange(criteria, this.props.ratings);
    }

    updateCriteriaWeight(criteriaId, weight) {
        this.updateCriteriaProps(criteriaId, {weight});
    }

    setCriteriaOrderProp(criteria) {
        criteria.forEach((c, index) => {
            if (c[CriteriaFields.ORDER] !== index) {
                criteria[index] = {...c, [CriteriaFields.ORDER]: index};
            }
        });
    }

    updateRatingsItem({criteriaId, insuranceId, fieldName, fieldValue, removeOnNull = false}) {
        let ratings = [...this.getRatings()];
        this.updateRatingsItemInArray(ratings, {criteriaId, insuranceId, fieldName, fieldValue, removeOnNull});

        this.onRatingsChange(ratings, {criteria: this.props.criteria});
        this.onChange(this.props.criteria, ratings);
    }

    updateRatingsItemInArray(ratings, {criteriaId, insuranceId, fieldName, fieldValue, removeOnNull = false}) {
        const ratingIndex = ratings.findIndex(rating =>
            rating[RatingFields.CRITERIA_ID] === criteriaId
            && (!insuranceId || rating[RatingFields.INSURANCE_ID] === insuranceId));

        if (removeOnNull && fieldValue === undefined || fieldValue === null) { // remove
            if (ratingIndex >= 0) {
                ratings.splice(ratingIndex, 1);
            }
        } else if (ratingIndex >= 0) { // update
            ratings[ratingIndex] = {...ratings[ratingIndex]};
            ratings[ratingIndex][fieldName] = fieldValue;
        } else { // add
            const rating = {};
            rating[RatingFields.CRITERIA_ID] = criteriaId;
            if (insuranceId) rating[RatingFields.INSURANCE_ID] = insuranceId;
            rating[fieldName] = fieldValue;

            ratings.push(rating);
        }
    }

    updateRatingData({criteriaId, ratingData, insuranceId}) {
        if (this.useRatingsAsTarget()) {
            this.updateCriteriaProps(criteriaId, {targetRatingData: ratingData});
            return;
        }

        this.updateRatingsItem({
            criteriaId,
            insuranceId,
            fieldName: RatingFields.RATING_DATA,
            fieldValue: ratingData,
            removeOnNull: true
        });
    }

    updateRatingComment({criteriaId, commentBroker}) {
        this.updateRatingsItem({
            criteriaId,
            fieldName: RatingFields.COMMENT_BROKER,
            fieldValue: commentBroker
        });
    }

    ////////////////////////////////////////////////////////////////////////////////

    onCriteriaChange(criteria, {ratings}) {
        this.setCriteriaOrderProp(criteria);
        this.props.onCriteriaChange && this.props.onCriteriaChange(criteria, {ratings});
    }

    onRatingsChange(ratings, {criteria}) {
        this.props.onRatingsChange && this.props.onRatingsChange(ratings, {criteria});
    }

    onChange(criteria, ratings) {
        this.setCriteriaOrderProp(criteria);
        this.props.onChange && this.props.onChange(criteria, ratings);
    }

//////////////////////////////////////////////////////////////////////////////////

    renderCriteria(criteria) {
        return criteria[CriteriaFields.NAME];
    }

    renderCriteriaDescriptionTooltip(criteria, isEditable) {
        const criteriaDescription = criteria ? criteria[CriteriaFields.DESCRIPTION] : null;

        return criteriaDescription
            ? <Tooltip title={<div className="nl2br">{criteriaDescription}</div>}
                       className={"pull-left m-r-xs" + (isEditable ? ' m-t-xs' : '')}>
                <span className="badge">?</span>
            </Tooltip>
            : null;
    }

    renderActions(criteria) {
        if (!this.getNonEditableCriteriaIds().includes(criteria[CriteriaFields.ID])) {
            return (
                <button type="button" className="btn btn-xs btn-link" onClick={() => this.removeCriteria(criteria)}>
                    <i className="fa fa-trash" title={l('Delete')} style={{color: 'red'}}/>
                </button>
            );
        }
    }

    renderCriteriaSelectCell(criteria) {
        const fieldName = criteria ? `criteria-${criteria[CriteriaFields.ID]}` : `criteria-empty`;
        const options = this.getUnusedCriteriaOptions(criteria);
        options.sort((o1, o2) => o2.label.localeCompare(o1.label));

        return <CustomSelect
            name={fieldName}
            value={criteria ? criteria[CriteriaFields.ID] : null}
            placeholder={''}
            isInDialog={true}
            fullWidth
            options={options}
            onChange={(criteriaId) => {
                if (criteriaId === null) {
                    if (criteria) {
                        this.removeCriteria(criteria);
                    }
                } else if (!criteria) {
                    this.addCriteria(this.getCriteriaById(criteriaId));
                } else {
                    this.replaceCriteria(criteria, this.getCriteriaById(criteriaId));
                }
            }}/>;
    }

    renderWeightEditCell(criteria) {
        const additionalProps = {};
        if (this.isPremiumAnalysisForm()) {
            additionalProps.InputProps = {
                endAdornment: <InputAdornment position="end"><small className="adornment">%</small></InputAdornment>
            };
        }

        return <TextField
            {...additionalProps}
            name={`weight${criteria[CriteriaFields.ID]}`}
            // error={typeof validationResult === 'object' && validationResult.valid === false}
            // helperText={typeof validationResult === 'object' ? validationResult.message : ''}
            value={criteria[CriteriaFields.WEIGHT] || ''}
            inputProps={{style: {textAlign: 'end'/*, maxWidth: '30px'*/}}}
            style={{maxWidth: '50px'}}
            onChange={(event) => {
                if (event.target.value.split('.').length > 2) return; // do not allow two .

                if (event.target.value.endsWith('.')) {
                    return this.updateCriteriaWeight(criteria[CriteriaFields.ID], event.target.value);
                }

                const weight = parseFloat(event.target.value) || 0;
                event.target.value = '' + weight;
                this.updateCriteriaWeight(criteria[CriteriaFields.ID], weight);
            }}/>;
    }

    renderWeightCell(criteria) {
        if (this.isPremiumAnalysisForm()) {
            return `${criteria[CriteriaFields.WEIGHT]} %`;
        }
        return criteria[CriteriaFields.WEIGHT];
    }

    renderRatingEditCell({criteria, rating, insuranceId}) {
        if (!criteria) return null;

        const criteriaId = criteria[CriteriaFields.ID];
        const fieldName = insuranceId ? `ratingData${insuranceId}-${criteriaId}` : `ratingData-${criteriaId}`;
        return <AnalysisRatingEditField
            criteria={criteria}
            input={
                {
                    name: fieldName,
                    value: rating !== null && rating !== undefined ? rating[RatingFields.RATING_DATA] : null,
                    onChange: (ratingData) => this.updateRatingData({criteriaId, ratingData, insuranceId}),
                }
            }
            meta={{}}
            selectProps={{isInDialog: true}}
            fullWidth
        />;
    }

    renderRatingCell({criteria, rating}) {
        if (!criteria || !rating) return '';

        return <AnalysisRatingField ratingData={rating[RatingFields.RATING_DATA]} criteria={criteria}/>;
    }

    renderCommentCell({criteria, rating}) {
        const criteriaId = criteria[CriteriaFields.ID];
        return <TextField
            name={`commentBroker-${criteriaId}`}
            label={l('Comment')}
            placeholder={l('You can start writing here')}
            value={rating ? rating[RatingFields.COMMENT_BROKER] : ''}
            fullWidth={true}
            onChange={(event) => this.updateRatingComment({criteriaId, commentBroker: event.target.value})}/>;
    }

    renderCommentToggleCell({criteria, rating}) {
        if (!criteria || criteria[CriteriaFields.ID] === CRITERIA_ID_PREMIUM || !this.showComments()) return '';

        const hasComment = rating && !!rating[RatingFields.COMMENT_BROKER];
        return <i className={`fa fa-comment${hasComment ? '' : '-o'}`}
                  onClick={() => this.toggleRow(criteria[CriteriaFields.ID])}
                  aria-hidden="true"/>;
    }

    calculateCriteriaPoints(ratingCalculator) {
        const criteriaPoints = this.getRatings()
            .reduce((result, rating) => {
                const criteriaId = rating[RatingFields.CRITERIA_ID];
                result[criteriaId] = ratingCalculator.calculatePoints(criteriaId, rating[RatingFields.RATING_DATA]);
                return result;
            }, {});
        this.getSelectedCriteria().forEach(criteria => {
            const criteriaId = criteria[CriteriaFields.ID];
            if (!(criteriaId in criteriaPoints)) {
                criteriaPoints[criteriaId] = ratingCalculator.calculatePoints(criteriaId, null);
            }
        });
        return criteriaPoints;
    }

    render() {
        const that = this;
        const {classes, disabled, assessmentTitle} = this.props;
        const showRatings = this.showRatings();

        const nonEditableRowIds = disabled
            ? this.getAvailableCriteria().map(criteria => criteria[CriteriaFields.ID])
            : this.getNonEditableCriteriaIds();
        const showEmptyRow = !disabled && this.getUnusedCriteria().length > 0;

        const selectedCriteria = this.getSelectedCriteria();
        const ratingCalculator = this.createRatingCalculator();
        const criteriaPoints = showRatings ? this.calculateCriteriaPoints(ratingCalculator) : {};

        const totalWeight = fixWeightRounding(selectedCriteria.reduce((acc, criteria) => parseFloatDefault(criteria[CriteriaFields.WEIGHT], 0) + acc, 0));
        const totalPoints = Object.values(criteriaPoints).reduce((acc, item) => parseFloatDefault(item, 0) + acc, 0);

        const totalColumns = (showRatings ? 8 : 5) - (/*order & actions*/ this.canEditCriteria() ? 0 : 2) - (this.showComments() ? 0 : 1) - (this.isPremiumAnalysisForm() ? 1 : 0);
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {this.canEditCriteria() ? <TableCell size="small"/> /*order*/ : null}
                            <TableCell>{l('Description')}</TableCell>
                            <TableCell align="right" size="small">{l('Weight')}</TableCell>
                            {!this.isPremiumAnalysisForm() ?
                                <TableCell align="right" size="small">{'%'}</TableCell> : null}
                            {showRatings ? [
                                <TableCell key={`header-rating`}>{assessmentTitle || l('Assessment')}</TableCell>,
                                <TableCell key={`header-points`} align="right"
                                           size="small">{this.props.pointsTitle}</TableCell>,
                                this.showComments() ? <TableCell key={`header-empty1`} size="small"/> : null, // comment
                            ] : null}
                            {this.canEditCriteria() ? <TableCell size="small"/> /*actions*/ : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedCriteria.map((criteria, index) => {
                            const criteriaId = criteria[CriteriaFields.ID];
                            const isEditable = !disabled && !nonEditableRowIds.includes(criteriaId);

                            const rating = this.getCriteriaRating(criteriaId);
                            const tableRow =
                                <TableRow key={`${criteriaId}-criteria`}
                                          style={index % 2 ? {} : {background: "#f8f8f8"}}>
                                    {this.canEditCriteria() ?
                                        <TableCell size="small" className="m-r-n-md">
                                            {this.canMoveCriteria(criteriaId, -1) ? <>
                                                <i className="fa fa-arrow-up pointer" title={l('Move up')}
                                                   onClick={() => this.moveCriteria(criteriaId, -1)}/><br/></> : null}
                                            {this.canMoveCriteria(criteriaId, 1) ?
                                                <i className="fa fa-arrow-down pointer" title={l('Move down')}
                                                   onClick={() => this.moveCriteria(criteriaId, 1)}/> : null}
                                        </TableCell> : null}
                                    <TableCell component="th" scope="row" className="text-nowrap">
                                        {this.renderCriteriaDescriptionTooltip(criteria, isEditable)}
                                        {isEditable && this.canEditCriteria()
                                            ? that.renderCriteriaSelectCell(criteria)
                                            : that.renderCriteria(criteria)}
                                    </TableCell>
                                    <TableCell align="right" size="small">
                                        {isEditable && this.canEditCriteria()
                                            ? that.renderWeightEditCell(criteria)
                                            : that.renderWeightCell(criteria)}
                                    </TableCell>
                                    {!this.isPremiumAnalysisForm() ?
                                        <TableCell align="right" size="small">
                                            {formatPercentageFloat(ratingCalculator.getWeightPercentage(criteria[CriteriaFields.ID]))}
                                        </TableCell> : null}
                                    {showRatings ? [
                                        <TableCell key={`${criteriaId}-rating`}>{isEditable
                                            ? that.renderRatingEditCell({criteria, rating})
                                            : that.renderRatingCell({criteria, rating})}
                                        </TableCell>,
                                        <TableCell key={`${criteriaId}-points`} align="right"
                                                   size="small">
                                            {this.renderPoints({
                                                criteria,
                                                rating,
                                                points: criteriaPoints[criteriaId],
                                                ratingCalculator
                                            })}
                                        </TableCell>,
                                        this.showComments() ?
                                            <TableCell key={`${criteriaId}-comment-toggle`}
                                                       size="small">
                                                {that.renderCommentToggleCell({criteria, rating})}
                                            </TableCell> : null,
                                    ] : null}
                                    {this.canEditCriteria() ?
                                        <TableCell
                                            size="small">{that.renderActions(criteria)}</TableCell>
                                        : null}
                                </TableRow>;

                            if (!this.state.expanded.includes(criteriaId)) return tableRow;

                            return [
                                tableRow,
                                <TableRow key={`${criteriaId}-comment`}
                                          style={index % 2 ? {} : {background: "#f8f8f8"}}>
                                    <TableCell colSpan={totalColumns}>
                                        {isEditable && this.canEditComment()
                                            ? that.renderCommentCell({criteria, rating})
                                            : rating ? rating[RatingFields.COMMENT_BROKER] : null}
                                    </TableCell>
                                </TableRow>
                            ];
                        })}
                        {showEmptyRow ?
                            <TableRow>
                                {this.canEditCriteria() ? <TableCell size="small"/> /*order*/ : null}
                                <TableCell component="th" scope="row" className="text-nowrap">
                                    {that.renderCriteriaSelectCell()}
                                </TableCell>
                                <TableCell colSpan={totalColumns - 2}/>
                            </TableRow>
                            : null}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {this.canEditCriteria() ? <TableCell size="small"/> /*order*/ : null}
                            <TableCell><b>{l('Total')}</b></TableCell>
                            {!this.isPremiumAnalysisForm() ?
                                <TableCell align="right" size="small"><b>{totalWeight}</b></TableCell> : null}
                            <TableCell align="right" size="small"><b>{'100%'}</b></TableCell>
                            {showRatings ? [
                                <TableCell key={`total-spacer-left`} align="right"/>,
                                <TableCell key={`total-points`} align="right"
                                           size="small"><b>{this.renderPoints({points: totalPoints})}</b></TableCell>,
                                this.showComments() ? <TableCell key={`total-spacer-right`} size="small"/> : null, // comments
                            ] : null}
                            {this.canEditCriteria() ? <TableCell size="small"/> /*actions*/ : null}
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        );
    }
}

export const AnalysisForm = withStyles(styles)(AnalysisBaseForm);

AnalysisForm.propTypes = {
    availableCriteria: PropTypes.arrayOf(PropTypes.object),
    criteria: PropTypes.arrayOf(PropTypes.object),
    ratings: PropTypes.arrayOf(PropTypes.object),
    onCriteriaChange: PropTypes.func,
    onRatingsChange: PropTypes.func,
    onChange: PropTypes.func,

    offerRequest: PropTypes.object,
    offers: PropTypes.arrayOf(PropTypes.object),

    disabled: PropTypes.bool,
    criteriaDisabled: PropTypes.bool,
    showRatings: PropTypes.bool,
    canEditComment: PropTypes.bool,

    pointsTitle: PropTypes.string.isRequired,
    assessmentTitle: PropTypes.string,
    targetRatings: PropTypes.bool,
};
