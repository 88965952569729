import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector, getFormSubmitErrors} from 'redux-form';
import {l} from '../../../../i18n/translator.js';
import {objectProp} from '../../../../helper/core.js';
import {Fields as CreditOfferRequestFields} from '../../../../../config/domain/offerRequest/credit.js';
import {Step, Wizard} from '../../misc/wizard.jsx';
import {creditFieldKey as dataFieldKey} from '../../../insurance-offer/create-request/helper';
import {FORM_NAME} from '../../../insurance-offer/create-request/config/form';
import * as WizardSteps from '../../../insurance-offer/create-request/config/wizard';
import {WizardNavbar, WizardNavItemVertical} from '../../../insurance-offer/create-request/component/form.jsx';
import {SalesDetailsForm} from './step/salesDetails.jsx';
import {AccountsReceivableForm} from './step/accountsReceivable.jsx';
import {ManagementOfAccountsReceivableForm} from './step/managementOfAccountsReceivable.jsx';
import {RemindersAndCollections} from './step/remindersAndCollections.jsx';
import {AdditionalInformationForm} from '../_common/step/additionalInformation.form.step.jsx';


const WIZARD_PARENT = WizardSteps.STEP_INSURANCE_DATA;

const stepPrefix = (step) => `${WIZARD_PARENT}:${step}`;


const StepsConfiguration = {
    [WizardSteps.STEP_DETAILS_OF_SALES]: {
        nextStep: stepPrefix(WizardSteps.STEP_ACCOUNTS_RECEIVABLE)
    },
    [WizardSteps.STEP_ACCOUNTS_RECEIVABLE]: {
        prevStep: stepPrefix(WizardSteps.STEP_DETAILS_OF_SALES),
        nextStep: stepPrefix(WizardSteps.STEP_MANAGEMENT_RECEIVABLE)
    },
    [WizardSteps.STEP_MANAGEMENT_RECEIVABLE]: {
        prevStep: stepPrefix(WizardSteps.STEP_ACCOUNTS_RECEIVABLE),
        nextStep: stepPrefix(WizardSteps.STEP_REMINDERS_COLLECTIONS)
    },
    [WizardSteps.STEP_REMINDERS_COLLECTIONS]: {
        prevStep: stepPrefix(WizardSteps.STEP_MANAGEMENT_RECEIVABLE),
        nextStep: stepPrefix(WizardSteps.STEP_ADDITIONAL_INFORMATION)
    },
    [WizardSteps.STEP_ADDITIONAL_INFORMATION]: {
        prevStep: stepPrefix(WizardSteps.STEP_REMINDERS_COLLECTIONS)
    }
};


const hasNavErrors = (part, errors) => {
    const partErrors = objectProp(dataFieldKey(part), errors);
    return !!partErrors;
};

const getScopedStep = (step) => {
    step = step.replace(WIZARD_PARENT, '');
    step = step.replace(/^:/, '');

    return step;
};


/**
 * Form Component
 */
export class CreditInsuranceForm extends React.Component {

    render() {
        const {props} = this;
        const {errors, currentStep, setStep, WizardContent} = props;
        const {i18n, auth} = props;

        const stepProps = {i18n, auth};

        return (
            <div>
                <WizardContent>
                    <Wizard
                        parent={WIZARD_PARENT}
                        defaultActive={WizardSteps.STEP_DETAILS_OF_SALES}
                        activeStep={currentStep}
                        className="wizard clearfix m-l-n-lg m-r-n-lg m-t-n-sm m-b-n-xs"
                        navbarComponent={WizardNavbar}
                        orientation="vertical"
                        onSelect={(e, id) => setStep(id)}
                    >
                        <Step
                            id={WizardSteps.STEP_DETAILS_OF_SALES}
                            title={l('Details of sales')}
                            navComponent={WizardNavItemVertical}
                            navProps={{hasErrors: hasNavErrors(CreditOfferRequestFields.SALES, errors)}}
                        >
                            <SalesDetailsForm {...props} />
                        </Step>

                        <Step
                            id={WizardSteps.STEP_ACCOUNTS_RECEIVABLE}
                            title={l('Accounts receivable')}
                            navComponent={WizardNavItemVertical}
                            navProps={{hasErrors: hasNavErrors(CreditOfferRequestFields.ACCOUNTSRECEIVABLE, errors)}}
                        >
                            <AccountsReceivableForm {...props} />
                        </Step>

                        <Step
                            id={WizardSteps.STEP_MANAGEMENT_RECEIVABLE}
                            title={l('Management Receivable')}
                            navComponent={WizardNavItemVertical}
                            navProps={{hasErrors: hasNavErrors(CreditOfferRequestFields.MANAGEMENT, errors)}}
                        >
                            <ManagementOfAccountsReceivableForm {...props} />
                        </Step>

                        <Step
                            id={WizardSteps.STEP_REMINDERS_COLLECTIONS}
                            title={l('Reminders & Collections')}
                            navComponent={WizardNavItemVertical}
                            navProps={{hasErrors: hasNavErrors(CreditOfferRequestFields.SCHEDULE, errors)}}
                        >
                            <RemindersAndCollections {...props} />
                        </Step>

                        <Step
                            id={WizardSteps.STEP_ADDITIONAL_INFORMATION}
                            title={l('Additional information')}
                            navComponent={WizardNavItemVertical}
                            navProps={{hasErrors: false}}
                        >
                            <AdditionalInformationForm dataFieldKey={dataFieldKey} {...props} />
                        </Step>
                    </Wizard>
                </WizardContent>
            </div>
        );
    }
}


CreditInsuranceForm = connect(
    state => ({
        errors: getFormSubmitErrors(FORM_NAME)(state),
        currency: formValueSelector(FORM_NAME)(state, dataFieldKey(CreditOfferRequestFields.SALES_CURRENCY)) || 'CHF',
        isManagementAssessmentNewBuyersInExcess: formValueSelector(FORM_NAME)(state, dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_NEWBUYERS_FREQUENCY)) == 'inExcess',
        isManagementAssessmentExistingBuyersInExcess: formValueSelector(FORM_NAME)(state, dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ASSESSMENT_EXISTINGBUYERS_FREQUENCY)) == 'inExcess',
        isRequestYearlyFinancialStatementsSometimes: formValueSelector(FORM_NAME)(state, dataFieldKey(CreditOfferRequestFields.MANAGEMENT_ANNUALFINANCIALSTATEMENTS_ISREQUESTED)) == 'sometimes',
    })
)(CreditInsuranceForm);
