export const Fields = {
    CATEGORIES: 'categories',
    CATEGORIES_CURRENT: 'categoriesCurrent',
    MAX_INSURED_SALARY: 'maxInsuredSalary',
    CASES_ONGOING: 'casesOngoing',
};

export const CategoryFields = {
    NAME: 'name',

    PEOPLE_COUNT_MEN: 'peopleCountMen',
    PEOPLE_COUNT_WOMEN: 'peopleCountWomen',
    PEOPLE_SALARY_MEN: 'peopleSalaryMen',
    PEOPLE_SALARY_WOMEN: 'peopleSalaryWomen',

    WAITING_PERIOD: 'waitingPeriod',

    // TODO
    EXTENDED_SALARY_DEFINITION_AHV_BRUTTOLOHN: 'extendedSalaryDefinitionAhvBruttolohn',
    EXTENDED_SALARY_DEFINITION_13_MONTH_SALARY: 'extendedSalaryDefinition13MonthSalary',
    EXTENDED_SALARY_DEFINITION_GRATIFICATION: 'extendedSalaryDefinitionGratification',
    EXTENDED_SALARY_DEFINITION_BONUS: 'extendedSalaryDefinitionBonus',
    EXTENDED_SALARY_DEFINITION_CHILD_ALLOWANCE: 'extendedSalaryDefinitionChildAllowance',
    EXTENDED_SALARY_DEFINITION_SOCIAL_WELFARE_SUPPLEMENT: 'extendedSalaryDefinitionSocialWelfareSupplement',
};

export const CaseFields = {
    START_DATE:  'startDate',
    GRADE: 'grade',
    INSURED_DAILY_RATE: 'insuredDailyRate',
    ESTIMATED_DURATION: 'estimatedDuration',
    CAUSE: 'cause',
};

export const DefaultData = {
};
