/**
 * Created by br0wn on 12/5/16.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {l} from '../../../../i18n/translator.js';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {STEP_INSURANCE_DATA} from '../../../insurance-offer/create-request/config/wizard';
import EditButton from '../../../insurance-offer/request-details/component/editButton.jsx';

export const DefaultInsuranceInformation = ({offerRequest = {}}) => {
    const data = offerRequest.data || {};

    if (!data.additionalInformation) {
        return null;
    }

    return null; // now we show it always
    // return (
    //     <Row>
    //         <Col md={12}>
    //             <Ibox.Container>
    //                 <Ibox.Title>
    //                     <h5>
    //                         <i className="fa fa-file"/>&nbsp;
    //
    //                         {l('Additional information')}
    //                     </h5>
    //
    //                     <EditButton step={STEP_INSURANCE_DATA}/>
    //                 </Ibox.Title>
    //
    //                 <Ibox.Content>
    //                     <pre>
    //                         {data.additionalInformation || ''}
    //                     </pre>
    //                 </Ibox.Content>
    //             </Ibox.Container>
    //         </Col>
    //     </Row>
    // );
};
