/**
 * Created by pboos on 10/25/17.
 */
import React from 'react';
import {injectIntl} from 'react-intl';
import {Chart, HorizontalBar} from 'react-chartjs-2';
import {l} from '../../../../../i18n/translator';
import {Fields as OfferFields} from '../../../../../../config/domain/offer';
import {colorForId, extractAnalysisData, formatPercentageFloatString} from '../../../../common/analysis.js';
import {Fields as OfferRequestFields} from '../../../../../../config/domain/offerRequest';
import {CriteriaFields} from '../../../../../../config/domain/analysis';
import {fixDecimals} from '../../../../common/analysis';

class CoverageAnalysisGraphComponent extends React.Component {
    getInsuranceName(insuranceId) {
        const insurance = this.props.insurances.find((insurance) => insurance.id === insuranceId);
        return insurance ? insurance.name : insuranceId;
    }

    getInsuranceId(offer) {
        return offer.insuranceId || offer.insuranceName;
    }

    structureBarChartData(offerRequest, offersWithAnalysis) {
        const {actions} = this.props;

        const labels = [];
        const dataSets = [];

        const coverageAnalysisCriteria = extractAnalysisData(offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA]);
        coverageAnalysisCriteria.forEachCriteria((criteria) => labels.push(criteria.name));

        const bestOffersByInsurance = offersWithAnalysis.reduce((map, item) => {
            const insuranceId = this.getInsuranceId(item.offer);
            if (map[insuranceId] === undefined || item.perfectDistance < map[insuranceId].perfectDistance) {
                map[insuranceId] = item;
            }
            return map;
        }, {});
        const bestOfferItems = Object.values(bestOffersByInsurance)
            .sort((o1, o2) => o1.perfectDistance - o2.perfectDistance);

        const insurancesData = {}; // insuranceId => data []

        // init with 0
        bestOfferItems.forEach((item) => {
            const insuranceId = this.getInsuranceId(item.offer);
            insurancesData[insuranceId] = [];
            coverageAnalysisCriteria.forEachCriteria(() => insurancesData[insuranceId].push(0));
        });

        // add values

        // set the coverage values
        bestOfferItems.forEach((item) => {
            const insuranceId = this.getInsuranceId(item.offer);
            const offerCoverageAnalysisRatings = item.offer[OfferFields.COVERAGE_ANALYSIS_RATINGS];
            if (offerCoverageAnalysisRatings) {
                offerCoverageAnalysisRatings.forEach((rating) => {
                    const criteria = coverageAnalysisCriteria.getCriteriaById(rating.criteriaId);
                    const criteriaIndex = coverageAnalysisCriteria.getIndexForCriteriaId(rating.criteriaId);
                    insurancesData[insuranceId][criteriaIndex] = rating.rating * criteria[CriteriaFields.WEIGHT_PERCENTAGE];
                });
            }
        });

        let stackCount = 0;
        bestOfferItems.forEach((item) => {
            const insuranceId = this.getInsuranceId(item.offer);
            if (insurancesData[insuranceId].find((value) => value > 0) === undefined) {
                return;
            }

            // TODO maybe we can create a map beforehand to speed this up?

            const stack = ++stackCount;
            const color = colorForId(insuranceId.toString(), 0.5);
            const borderColor = colorForId(insuranceId.toString(), 0.8);
            dataSets.push({
                label: this.getInsuranceName(insuranceId),
                data: insurancesData[insuranceId],
                backgroundColor: color,
                borderColor: borderColor,
                borderWidth: 1,
                stack: `Stack ${stack}`
            });
        });

        // noinspection JSUnusedGlobalSymbols
        return {
            type: 'horizontalBar',
            data: {
                labels: labels,
                datasets: dataSets
            },
            options: {
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            min: 0,
                            stepSize: 1,
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        barPercentage: 1,
                        categoryPercentage: 0.7,
                        gridLines: {
                            color: '#888888',
                            // lineWidth: 1.5,
                            drawBorder: false
                        }
                    }]
                },
                legend: {
                    position: 'bottom'
                },
                tooltips: {
                    mode: 'point',
                    intersect: false,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const dataSet = data.datasets[tooltipItem.datasetIndex];
                            const criteria = coverageAnalysisCriteria.getCriteriaByIndex(tooltipItem.index);

                            const weightPercentage = criteria[CriteriaFields.WEIGHT_PERCENTAGE];
                            return `${dataSet.label}: ${tooltipItem.xLabel / weightPercentage} (${l('Assessment')}) * ${formatPercentageFloatString(weightPercentage)} (${l('Weight')}) = ${fixDecimals(tooltipItem.xLabel)}`;
                        }
                    }
                }
            },
            plugins: [{
                afterEvent: function (chart, event) {
                    const yAxis = chart.scales['y-axis-0'];
                    const x = event.x;
                    const y = event.y;

                    if (event.type === 'click' &&
                        x <= yAxis.right && x >= yAxis.left &&
                        y <= yAxis.bottom && y >= yAxis.top) {
                        // click on y label
                        const ticksHeight = yAxis.bottom - yAxis.top;
                        const tickHeight = ticksHeight / yAxis.ticks.length;
                        const tickY = y - yAxis.top;
                        const tickIndex = Math.floor(tickY / tickHeight);
                        // alert(chart.data.labels[tickIndex]);
                        return;
                    }

                    if (event.type === 'click') {
                        const element = chart.getElementAtEvent(event);
                        if (element && element.length > 0) {
                            // click on criteria rating
                            const datasetIndex = element[0]._datasetIndex;
                            const dataIndex = element[0]._index;

                            const offerItem = bestOfferItems[datasetIndex];
                            const offer = offerItem.offer;
                            const criteria = coverageAnalysisCriteria.getCriteriaByIndex(dataIndex);
                            if (actions && actions.onRatingClick) {
                                actions.onRatingClick(offer, criteria);
                            }
                        }
                    }
                }
            }]
        };
    };

    render() {
        let {offerRequest, offersWithAnalysis} = this.props;
        let barCharData = this.structureBarChartData(offerRequest, offersWithAnalysis);
        return (<HorizontalBar ref='chart' {...barCharData} />);
    }
}

export const CoverageAnalysisGraph = injectIntl(CoverageAnalysisGraphComponent);
