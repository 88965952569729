export default {
    " < 1 day": " < 1 Tag",
    " < 3 days": " < 3 Tage",
    " <= 100%": " <= 100%",
    " <= 110%": " <= 110%",
    " <= 130%": " <= 130%",
    "...": "...",
    "(excl. private customers, subsidiaries, affiliated companies and public corporations)": "(ohne Privatpersonen, liierte Fimen und öffentlich-rechtliche Körperschaften)",
    "{{count}} years": "{{count}} Jahr",
    "{{count}} years ago": "Vor {{count}} Jahr",
    "{{count}} years ago_plural": "Vor {{count}} Jahren",
    "{{count}} years_plural": "{{count}} Jahre",
    "# Bids": "# Gebote",
    "# of Buyers": "# Käufer",
    "# of credit/financial reports obtained annually": "Anzahl jährliche angefordeter Kredit/Finanzberichte",
    "# Offers": "# Offerten",
    "% Complete": "% Vollständig",
    "> 300": "> 300",
    "100 to 300": "100 bis 300",
    "1st": "1.",
    "1st biggest case": "erster grosser Fall",
    "1st Quarter": "1. Quartal",
    "1st reminder": "1. Mahnung",
    "2 Biggest Customers": "zwei grössten Kunden",
    "20 to 100": "20 bis 100",
    "2nd": "2.",
    "2nd biggest case": "zweit grösster Fall",
    "2nd Quarter": "2. Quartal",
    "2nd reminder": "2. Mahnung",
    "3rd": "3.",
    "3rd Quarter": "3. Quartal",
    "3rd reminder": "3. Mahnung",
    "4th": "4.",
    "4th Quarter": "4. Quartal",
    "4th reminder": "4. Mahnung",
    "5 to 20": "5 bis 20",
    "abroad": "im Export",
    "Abroad": "im Export",
    "Abstain": "Verzichten",
    "Abstain from offer": "Offerteingabe verzichten",
    "abstained": "verzichtet",
    "Accept offer": "Offerte akzeptieren",
    "accept_contractual_fee_agreement_link": "Honorar- und Auftragsvereinbarung",
    "accept_contractual_fee_agreement_part1": "Ich bestätige, dass ich die ",
    "accept_contractual_fee_agreement_part2": " gelesen habe und akzeptiere diese.",
    "accept_terms_link": "Allgemeinen Geschäftsbedingungen",
    "accept_terms_part1": "Ich bestätige, dass ich die ",
    "accept_terms_part2": " gelesen habe und akzeptiere diese.",
    "Accident Insurance Data": "Unfall Versicherungsdaten",
    "Accidentally added": "aus Versehen hinzugefügt",
    "Account requires a password. Please set the password now.": "Benutzer benötigt ein Passwort. Bitte das Passwort jetzt erfassen.",
    "Accounts receivable": "Absicherung Debitoren",
    "Action not allowed for current user.": "Aktion für aktuellen Benutzer nicht erlaubt",
    "Actions": "Aktionen",
    "Activity": "Aktivität",
    "Add": "Hinzufügen",
    "Add Customer": "Kunden hinzufügen",
    "Add Debt": "Depitoren hinzufügen",
    "Add new": "Neuen erfassen",
    "Add Term Record": "Datensatz hinzufügen",
    "Additional information": "weitere Informationen",
    "Address": "Adresse",
    "Address line 1": "Adresszeile 1",
    "Address line 2": "Adresszeile 2",
    "Adjustments ongoing": "Anpassungen werden durchgeführt",
    "admin": "Administrator",
    "Administration": "Administration",
    "advisory mandate": "Beratungsmandat",
    "Advisory mandate": "Beratungsmandat",
    "Advisory Mandate": "Beratungsmandat",
    "After publishing you will not be able to change anything anymore.": "Nach dem Veröffentlichen kann nichts mehr verändert werden.",
    "All": "Alle",
    "All insurances": "Alle Versicherungen",
    "Amount": "Betrag",
    "Amount in (000s)": "Betrag in (000s)",
    "An error occurred while loading widget.": "Fehler beim Laden des Widgets.",
    "An error occurred while uploading file.": "Fehler beim hochladen der Datei.",
    "An error occurred while widget was working.": "Beim Widget ist ein Fehler aufgetreten.",
    "Analysis": "Analyse",
    "Analysis (now)": "Analyse (jetzt)",
    "Analysis of current insurance policy": "Analyse der jetztigen Versicherungspolice",
    "Analysis criteria": "Deckungsbaustein",
    "analysis_criteria_number_type_default": "Standard",
    "analysis_criteria_number_type_currency": "Währung",
    "analysis_criteria_number_type_percentage": "Prozent",
    "analysis_criteria_rating_type_default": "Standard",
    "analysis_criteria_rating_type_number": "Zahl",
    "analysis_criteria_rating_type_options": "Optionen",
    "analysis_criteria_rating_type_checkbox": "Checkbox",
    "analysis_criteria_type_coverage": "Deckung",
    "analysis_criteria_type_premium": "Prämie",
    "analysis_criteria_type_utility": "Nutzwert",
    "Annual turnover": "Jahresumsatz",
    "Apply template": "Vorlage anwenden",
    "Archived": "Archiviert",
    "Are you sure?": "Sind Sie sicher?",
    "Area": "Gebiet",
    "Area of Operations": "Tätigkeitsbereich",
    "Assessment": "Bewertung",
    "Auto Extend": "Autom. Verlängerung",
    "Back": "Zurück",
    "Back to login": "zurück zur Anmeldung",
    "Bad": "Schlecht",
    "Bad Debts and Distress Claims": "Debitorenverluste und notleidende Forderungen",
    "Bad debts and distressed claims from the current to the last 3 years": "Debitorenverluste und notleidende Forderungen des laufenden Jahres und der letzten 3 Jahre",
    "bank guarantee, in following cases:": "Bankgarantien in folgenden Fällen:",
    "Bank guarantee, in following cases:": "Bankgarantien in folgenden Fällen:",
    "Basic data": "Basisdaten",
    "Begin legal proceedings": "Einleitung gerichtlicher Zahlungsvollzug",
    "Best offer": "Beste Offerte",
    "bidding": "veröffentlicht",
    "Bidding End Date": "Enddatum Bieterrunde",
    "Biggest cases": "Grösste Einzelfälle",
    "Biggest Customers": "Ihre grössten Kunden",
    "Biggest open position": "Höchste offene Position",
    "Billing": "Abrechnung",
    "Billing Address": "Abrechnungsadresse",
    "Billing frequency": "Prämienfälligkeit",
    "billing_frequency_12": "jährlich",
    "billing_frequency_3": "quartalsweise",
    "billing_frequency_6": "halbjährlich",
    "BIN": "Unternehmensnummer (UID)",
    "Broker comment": "Kommentar von Broker",
    "Business Identification Number": "Unternehmensnummer",
    "Buyer Credit Risk Requests": "Käufer Anfragen Kredit Risiko",
    "By 3rd party in:": "Inkasso durch Dritte:",
    "Call/visit buyer": "Telefon/Besuch des Käufers",
    "Cancel": "Abbrechen",
    "canceled": "abgebrochen",
    "Canceling ...": "Abbrechen ...",
    "Cease delivering": "Lieferstopp",
    "Change Password": "Passwort ändern",
    "CHF": "CHF",
    "City": "Stadt",
    "Clear": "Zurücksetzen",
    "Clear selection": "Auswahl zurücksetzen",
    "Click to download the advisory mandate template: ": "Klicken um das Beratungsmandat herunterzuladen: ",
    "Click to select a date": "Klicken um ein Datum auszuwählen",
    "Click to select date": "Klicken um Datum auszuwählen",
    "Close": "Schliessen",
    "Comment": "Kommentar",
    "Company": "Firma",
    "Company name": "Firmenname",
    "Company was successfully created": "Firma erfolgreich erstellt",
    "Confirm New Password": "Neues Passwort bestätigen",
    "Confirm Password": "Passwort bestätigen",
    "Confirmation": "Bestätigung",
    "Contact": "Kontakt",
    "Contact Address": "Kontaktadresse",
    "Contact name": "Kontaktname",
    "Contract Data": "Vertragsdaten",
    "Correspondence": "Korrespondenz",
    "Correspondence Address": "Korrespondenzadresse",
    "Could not find specified offer or offer request.": "Konnte Offerte für diese Offertanfrage nicht finden.",
    "Could not find specified offer request.": "Konnte Offertanfrage nicht finden.",
    "Could not find specified user.": "Benutzer nicht gefunden.",
    "Could not verify mobile.": "Verfikation Mobilenummer fehlgeschlagen.",
    "Could not verify user. User could be already verified or not found.": "Benutzer konnte nicht überprüft werden (ggf. Benutzer nicht gefunden)",
    "Country": "Land",
    "Couverage Incentive": "Deckungsattraktivität",
    "Coverage Analysis": "Deckungsanalyse",
    "Coverage assessment": "Deckungsbeurteilung",
    "Create": "Erstellen",
    "Create an account": "Account erstellen",
    "Create auto rating": "Auto Rating erstellen",
    "Create customer": "Kunde erstellen",
    "Create new account": "Account erstellen",
    "Create offer": "Offerte erstellen",
    "Create Offer Request": "Neue Offertanfrage",
    "Create Offer Overview": "Neue Offertübersicht",
    "Create user": "Benutzer erstellen",
    "created": "erstellt",
    "Created on": "Erstellungsdatum",
    "Credit Insurance Data": "Daten Kreditversicherung",
    "Credit Limits and Receivables Security": " Wir wenden folgende Absicherungsmöglichkeiten an",
    "Credit limits are decided by": "Kreditlimiten werden entschieden von",
    "Criteria": "Deckungsbausteine",
    "Curr. Premium": "Aktuelle Prämie",
    "Current": "Aktuell",
    "Current insurance": "Aktuelle Versicherung",
    "Current Insurance Data": "Aktuelle Versicherungsdaten",
    "Current insurance policy": "Aktuelle Versicherungspolice",
    "Current Insurer": "Aktuelle Versicherung",
    "Current number": "Aktuelle Nummer",
    "Current policy number": "Aktuelle Policennummer",
    "Current year": "Aktuelles Jahr",
    "Customer": "Kunde",
    "Customer details": "Kundendetails",
    "Customer name": "Kundenname",
    "Customer Requests": "Kundenanfragen",
    "Customer was successfully created": "Kunde wurde erfolgreich erstellt.",
    "Customers": "Kunden",
    "Customers with max. outstanding debt in 000s": "Kunden mit max. Aussenstand in 000s",
    "Dashboard": "Dashboard",
    "data_table__info": "Translation from https://datatables.net/plug-ins/i18n/",
    "data_table_decimal": "",
    "data_table_emptyTable": "Keine Daten in der Tabelle vorhanden",
    "data_table_first": "Erste",
    "data_table_info": "_START_ bis _END_ von _TOTAL_ Einträgen",
    "data_table_infoEmpty": "0 bis 0 von 0 Einträgen",
    "data_table_infoFiltered": "(gefiltert von _MAX_ Einträgen)",
    "data_table_infoPostFix": "",
    "data_table_last": "Letzte",
    "data_table_lengthMenu": "_MENU_ Einträge anzeigen",
    "data_table_loadingRecords": "Wird geladen...",
    "data_table_next": "Nächste",
    "data_table_previous": "Zurück",
    "data_table_processing": "Bitte warten...",
    "data_table_search": "Suchen",
    "data_table_sortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
    "data_table_sortDescending": ": aktivieren, um Spalte absteigend zu sortieren",
    "data_table_thousands": "'",
    "data_table_zeroRecords": "Keine Einträge vorhanden.",
    "Date": "Datum",
    "Date created": "Erstellungsdatum",
    "Date published": "Veröffentlichungsdatum",
    "Days after original due date": " Tage nach Originalfälligkeit",
    "daysLeft": "noch 1 Tag",
    "daysLeft_plural": "noch {{count}} Tage",
    "Debt Collection and Credit Insurance": "Forderungsinkasso",
    "Debt collection offices": "Betreibungsämter",
    "Debt enforcement request, debt collection agency, lawyer": "Betreibungsbegehren, Inkassobüro, aAnwalt",
    "Debts": "Schulden",
    "Decoy bird": "Lockvogel",
    "Delete": "Löschen",
    "Deleting ...": "Löschen ...",
    "Description": "Beschreibung",
    "Desired start date": "Gewünschtes Beginndatum",
    "Details of sales": "Umsatzangaben",
    "Development of accounts receivable": "Entwicklung der Debitoren",
    "Development per quarter": "quartalsweise Entwicklung",
    "Disabled.": "Deaktiviert.",
    "Do not have an account?": "Haben Sie bereits einen Account?",
    "Do you currently have insurance?": "Haben Sie derzeit eine Versicherung?",
    "Do you want to proceed?": "Möchten Sie den Vorgang fortsetzen?",
    "Drop files here or click to open file select dialog.": "Hier Datei ablegen oder klicken um Dialog zu öffnen.",
    "E-mail": "E-mail",
    "E-Mail": "E-Mail",
    "E-Mail sent": "E-Mail wurde gesandt",
    "Edit": "Editieren",
    "Edit comment": "Kommentar ändern",
    "Edit customer": "Kunde editieren",
    "Edit user": "Benutzer ändern",
    "Edit User": "Benutzer ändern",
    "Email": "Email",
    "Email address required.": "E-Mail-Adresse wird benötigt.",
    "Employees": "Mitarbeiter",
    "Enable": "Aktivieren",
    "End Date for Insurance Companies": "Eingabeschluss für Versicherungen",
    "End of offer round": "Ende der Offertrunde",
    "Ending Soon": "Bald fertig",
    "Enforced debt collection": "Forderungsinkasso",
    "Enter your email": "E-Mail adresse eingeben",
    "Enter your username/email": "Benutzername/E-Mail-Adresse eingeben",
    "Error": "Fehler",
    "Error while loading data.": "Fehler beim Laden der Daten.",
    "EUR": "EUR",
    "excess": "Übermass",
    "Extend offer request ending": "Enddatum Offerteingabe verlängern",
    "Extends offer request ending when last offer published is withing one day of defined end date.": "Enddatum der Offerteinreichung autom. um einen Tag nach letzer Offerteinreichung verlängern",
    "File is not valid %err%": "Ungültige Datei %err%",
    "Final requested": "Offerte angefordert",
    "Finance department": "Finanzabteilung",
    "Finance Department": "Finanzabteilung",
    "Financial statements from our customers": "Jahresabschlüsse unserer Kunden holen wir ein",
    "financial statements request cases": "Fälle für das Einholen von Jahresabschlüssen",
    "Find your NOGA code": "Finden Sie Ihren NOGA Code",
    "finished": "abgeschlossen",
    "First Name": "Vorname",
    "Flag": "Markieren",
    "Flagged": "Markiert",
    "Flop": "Flop",
    "For existing buyers": "Für bestehende Kunden",
    "For new buyers": "Für neue Kunden",
    "Forecast Current year:": "Umsatzerwartung laufendes Jahr:",
    "Forecast Next year:": "Umsatzerwartung nächstes Jahr:",
    "Forgot password?": "Passwort vergessen?",
    "Forgot your password?": "Passwort vergessen?",
    "Function": "Funktion",
    "General": "Allgemein",
    "General Conditions of Insurance": "Allgemeine Versicherungsbedingungen",
    "Generate": "Generieren",
    "Go to dashboard": "zum Dashboard",
    "Go to details": "zu den Details",
    "Good": "Gut",
    "Gross premium": "Brutto Prämie (inkl. Stempel & Abgaben)",
    "Grunddeckung": "Grunddeckung",
    "Harm history": "Schadensverlauf",
    "History": "Verlauf",
    "How should the offer premium be rated?": "Wie soll die Offertprämie beurteilt werden?",
    "How would you like this request setup?": "Wie wollen Sie die Offeranfrage definieren?",
    "I don't know": "Ich weiss es nicht",
    "I have read and accept Terms and Agreements.": "Hiermit bestätige ich die AGB gelesen und zu akzeptieren.",
    "Id": "Id",
    "If yes, the credit limits are decided by the:": "Wenn ja, entscheidet über die Kreditlimiten die:",
    "If you leave now, all changes will be lost. Are you sure you want to leave?": "Wenn Sie jetzt abbrechen, gehen alle Änderunge verloren. Soll wirklich abgebrochen werden?",
    "In 1 month": "in 1 Monat",
    "In 2 months": "in 2 Monaten",
    "In 2 weeks": "in 2 Wochen",
    "In order to assess the credit risk associated with each buyer, we request...": "Informationen über unsere Käufer zur Abschätzung des Delkredere-Risikos holen wir wie folgt ein:",
    "in Switzerland": "in der Schweiz",
    "Industry Classification Code (NOGA)": "Klassifikation Wirtschaftszweig (NOGA)",
    "Information bureaus": "Auskunftsbüros",
    "Information Bureaus": "Auskunftsbüros",
    "Insurance": "Versicherung",
    "Insurance Data": "Versicherungsdaten",
    "Insurance holder": "Versicherungsnehmer",
    "Insurance information": "Versicherungsinformationen",
    "Insurance period": "Versicherungsperiode",
    "Insurance Period": "Versicherungsperiode",
    "Insurance type": "Art der Versicherung",
    "Insurance Type": "Versicherungsart",
    "Insurance type permissions": "Berechtigung für Versicherungsart",
    "Insurance types": "Versicherungsarten",
    "Insurances": "Versicherungsanbieter",
    "Insurer": "Versicherer",
    "Invalid Value": "Ungültiger Wert",
    "Is a classification (premium increase as a result of the course of the claim) provided for by the existing insurance?": "Ist eine Sarnierung (Prämienerhöhung in Folge von Schadensverlauf) von der bestehenden Versicherung vorgesehen?",
    "Issued offers": "Erstellte Offerten",
    "Jointly insured companies": "Mitversicherte Betriebe",
    "key": "key",
    "Last Name": "Nachname",
    "Last offer": "Letzte Offerte",
    "Last offer date": "Datum letzte Offerte",
    "Last offer time": "Zeitpunkt letzte Offerte",
    "Last updated:": "letzte Aktualisierung:",
    "Loading...": "laden...",
    "Log out": "Abmelden",
    "Logging in, please wait": "Anmeldung, bitte warten",
    "Login": "Anmelden",
    "Lookup": "Suche",
    "Lookup found nothing.": "Bei Suche nichts gefunden.",
    "Management Receivable": "Debitorenbewirtschaftung",
    "Mandatory Data": "Pflichtfelder",
    "Max. outstanding debt": "Kunden mit max. Aussenstand",
    "Maximum": "Maximum",
    "Men": "Männer",
    "Messages": "Meldungen",
    "Min. offer": "Min. Offerte",
    "Min. Offer": "Min. Offerte",
    "Mobile": "Mobile",
    "Mobile has been verified": "Mobilenummer verifiziert",
    "Mobile number": "Mobilenumber",
    "Mobile Number": "Mobilenummer",
    "Mobile Token": "Mobile Token",
    "Mobile Verification": "Mobile Verifikation",
    "Name": "Name",
    "Name / Address": "Name / Adresse",
    "Net premium": "Nettoquotierung (inkl. Stempel & Abgaben)",
    "Neutral": "Neutral",
    "never": "nie",
    "Never": "Nie",
    "New best offer received": "Neue beste Offerte erhalten",
    "New Bids": "Neue Offerten",
    "New insurance data request received": "Neue Anfrage Versicherungsdaten empfangen",
    "New message received": "Neue Meldung erhalten",
    "New messages": "Neue Meldungen",
    "New offer received": "Neue Offerte erhalten",
    "New offer request received": "Neue Offertanfrage erhalten",
    "New password": "neues Passwort",
    "New Password": "neues Passwort",
    "New row": "Neue Zeile",
    "Next": "Weiter",
    "Next year": "Nächstes Jahr",
    "No": "Nein",
    "No chat selected.": "Kein Chatkanal ausgewählt.",
    "No comment": "kein Kommentar",
    "No conversations found.": "Keine Konversation gefunden.",
    "No current insurance": "keine aktuelle Versicherung",
    "No data": "keine Daten",
    "No files attached": "Kein Anhang",
    "No harm history": "Kein Schaden",
    "No messages at the moment.": "zur Zeit keine Meldungen.",
    "No notifications at the moment": "zur Zeit keine Benachrichtigungen",
    "No offers requiring manual processing": "Keine Offerten, welche manuelle Verarbeitung benötigen.",
    "No offers yet": "Noch keine Offerten",
    "No target premium": "Keine Zielprämie",
    "No users found. Create a user by clicking \"Create user\" button.": "Keinen Benutzer gefunden. Zum Erstellen bitte \"Benutzer erstellen\" klicken.",
    "No, leave it be": "Nein doch nicht",
    "Non-insurable sales": "Nicht versicherbare Umsätze",
    "None": "Keine",
    "None selected": "Nichts ausgewählt",
    "None specified": "Nichts angegeben",
    "Not specified": "Nichts angegeben",
    "Number": "Nummer",
    "Number of buyers": "Anzahl Käufer",
    "Number of collections initiated in last 12 months": "Anzahl eingeleiteter Inkassi und Betreibungen in den letzten 12 Monaten",
    "Number of collections/legal proceedings initiated in the last 12 months": "Anzahl eingeleiteter Inkassi und Betreibungen in den letzten 12 Monaten",
    "Number of Employees": "Anzahl Mitarbeiter",
    "Number type": "Nummer-Art",
    "Occasionally": "Gelegentlich",
    "of our overall sales is generated through business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).": "unseres Gesamtumsatzes geht aus Geschäften mit Privatpersonen, liierte Firmen, öffentlich-rechtlichen Körperschaften und aus Bargeschäften (Vorauszahlung, Akkreditive) hervor.",
    "Offer": "Offerte",
    "Offer {{state}}": "Offerte {{state}}",
    "Offer id": "Offertnr",
    "Offer overview": "Offertübersicht",
    "Offer Rating": "Offertbeurteilung",
    "Offer request": "Offertanfrage",
    "Offer Request #": "Offertanfrage #",
    "Offer request #:number": "Offeranfrage #:number",
    "Offer request created": "Offertanfrage erstellt",
    "Offer Request Details": "Details der Offertanfrage",
    "Offer request files": "Offertunterlagen",
    "Offer request is not complete, please edit the request and add missing information.": "Offertanfragen nicht vollständig, bitte fehlende Informationen ergänzen",
    "Offer request published": "Offertenanfrage veröffentlicht",
    "Offer request requires validation": "Offertanfrage benötigt Validierung",
    "Offer request updated": "Offertanfrage aktualisiert",
    "Offer requires validation": "Offerte benötigt Validierung",
    "Offer Round": "Offertrunde",
    "Offer round end": "Auktionsende",
    "Offer round type": "Offertrundenart",
    "offerRoundType_auction": "Auktion",
    "offerRoundType_firstShot": "Auf erste Anfrage",
    "Offers": "Offerten",
    "Offers - Pending": "Offerten - Pendent",
    "Offers - Requiring manual processing": "Offerten - Manuelle Verarbeitung erforderlich",
    "Offers count": "Anzahl Offerten",
    "Office Address": "Geschäftsadresse",
    "Official offer file": "Offizielle Offertdatei",
    "Official Offers": "Offizielle Offerten",
    "OK": "OK",
    "Old Password": "altes Passwort",
    "Optional": "Optional",
    "Orders in excess of": "Orders in excess of",
    "Orders in excess of: %excess%": "Bei Aufträgen über %excess%",
    "Other": "Andere",
    "Other:": "Andere:",
    "Our reminder procedure is as follows (in days):": "Unser Mahnwesen sieht wie folgt aus (in Tagen):",
    "Our reminder procedure is as follows:": "Our reminder procedure is as follows:",
    "Our sources": "Unsere Quellen",
    "Ourself in:": "wir selbst in:",
    "Over last 3 years:": "über die letzten 3 Jahre:",
    "Overall Analysis": "Gesamtanalyse",
    "overall sales generated trough business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).": "unseres Gesamtumsatzes geht aus Geschäften mit Privatpersonen, liierte Firmen, öffentlich-rechtlichen Körperschaften und aus Bargeschäften (Vorauszahlung, Akkreditive) hervor.",
    "Overview": "Übersicht",
    "Password": "Passwort",
    "Password cannot be empty": "Passwort darf nicht leer sein",
    "Password empty!": "Passwort ist leer!",
    "Password you entered is not valid. Please try again.": "Passwort ist nicht gültig. Bitte erneut eingeben.",
    "Passwords do not match": "Passwörter stimmen nicht überein",
    "Passwords do not match!": "Passwörter stimmen nicht überein!",
    "Passwords do not match.": "Passwörter stimmen nicht überein.",
    "Payment terms (days)": "Zahlungsfrist in Tagen",
    "pending": "Pendent",
    "pending_insurance": "Pendent bei Versicherung",
    "pending_mandate": "Pendent bei Mandat",
    "pending_validation": "Validierung pendent",
    "percent of our overall sales is generated through business with private customers, subsidiaries and affiliated companies, public corporations and cash transactions (advance payments, documentary credits).": "Prozent unseres Gesamtumsatzes geht aus Geschäften mit Privatpersonen, liierte Firmen, öffentlich-rechtlichen Körperschaften und aus Bargeschäften (Vorauszahlung, Akkreditive) hervor.",
    "Permissions": "Berechtigungen",
    "Phone": "Telefon",
    "Please add at least one offer.": "Bitte mind. 1 Offerte hinzufügen.",
    "Please check your email for password reset link.": "Bitte E-Mail prüfen. Link zum Zurücksetzen vom Passwort wurde versendet.",
    "Please check your email for your activation link.": "Bitte überprüfen Sie Ihre E-Mail für Ihre Aktivierungslink.",
    "Please choose a reason": "Bitte Grund auswählen",
    "Please enter a reason": "Grund eingeben",
    "Please enter custom reason": "individuellen Grund eingeben",
    "Please enter insurance data for this offer request": "Bitte geben Sie die Versicherungsdaten für die Offertanfrage ein",
    "Please enter insurance information": "Bitte geben Sie momentane Versicherungsinformationen ein",
    "Please enter your username/email.": "Benutzername/E-Mail eingeben.",
    "Please input your information": "Bitte geben Sie Ihre Informationen ein",
    "Please list your 5-10 biggest customers": "Bitte die 5-10 grössten Kunden angeben",
    "Please see the offer details in the link bellow.": "Offertdetails im Link unten ersichtlich.",
    "Please select": "Bitte auswählen",
    "Please select customer and insurance type to enable template download.": "Bitte Kunde und Versicherungsart auswählen um die Vorlage herunterzuladen.",
    "Please select insurance type and broker to enable template download.": "Bitte Versicherungsart und Broker auswählen um die Vorlage herunterzuladen.",
    "Please upload your offer as PDF here": "Bitte laden Sie hier Ihre Offerte als PDF hoch",
    "Points": "Punkte",
    "Policy number": "Policennummer",
    "Policy Number": "Policennummer",
    "Premium": "Prämie",
    "Premium (%)": "Prämie (%)",
    "Premium Amount": "Prämienhöhe",
    "Premium Analysis": "Prämienanalyse",
    "Premium assessment": "Prämienbeurteilung",
    "Premium Incentive": "Prämienattraktivität",
    "Premium Volume": "Prämienvolumen",
    "Previous": "Zurück",
    "Previous insurance": "Vorversicherer",
    "Previous insurance policy number": "Vorversicherer Policen-Nr.",
    "Print": "Drucken",
    "Private Notes": "Private Anmerkungen",
    "Procedure": "Verfahren",
    "Profile": "Profil",
    "Publish": "Veröffentlichen",
    "published": "veröffentlicht",
    "Published on": "Veröffentlichungsdatum",
    "Publishing ...": "Veröffentlichen ...",
    "Rank": "Rang",
    "Rank %num%": "Rang %num%",
    "Ranking of the best offer (price & coverage) by this insurance": "Rang der besten Offerte (Prämie & Deckung) dieser Versicherung",
    "Ranking of the offer with the cheapest premium by this insurance": "Rang der Offerte mit günstigster Prämie dieser Versicherung",
    "Ranking of the offer with the best coverage by this insurance": "Rang der Offerte mit bester Deckung dieser Versicherung",
    "Rating": "Bewertung",
    "Rating Criteria": "Bewertungskriterien",
    "Read": "Lesen",
    "Read only": "nur Lesen",
    "Register now": "Hier registrieren",
    "Reject offer": "Offerte zurückweisen",
    "Reject reason": "Grund für Zurückweisung",
    "Reload the page": "Seite neu laden",
    "Reminder procedure": "Mahnwesen",
    "Reminders & Collections": "Mahn- und Inkassowesen",
    "Remove": "Entfernen",
    "Removing...": "Löschen...",
    "Repeat password": "Passwort wiederholen",
    "Repeat Password": "Passwort wiederholen",
    "Request final": "Offerte anfordern",
    "Request insurance offer": "Offertanfrage",
    "Requests - Bidding": "Offertanfragen - Bieten",
    "Requests - Finished": "Offertanfragen - Abgeschlossen",
    "Requests - Pending": "Offertanfragen - Pendent",
    "Requests Finished": "Abgeschlossene Offertanfragen",
    "Requests list": "Liste Offertanfragen",
    "Requests Selected": "Ausgewählte Offertanfragen",
    "Requirement": "Anforderung",
    "Resend E-Mail": "E-Mail erneut senden",
    "Reset password": "Passwort zurücksetzen",
    "retention of title, in following cases:": "Eigentumsvorbehalt, in folgenden Fällen:",
    "Retention of title, in the following cases:": "Eigentumsvorbehalt, in folgenden Fällen:",
    "Retry": "Wiederholen",
    "Revenue": "Umsatz",
    "Role": "Rolle",
    "Salaries Job Accidents": "Lohnsumme Berufsunfälle",
    "Salaries Non-Job Accidents": "Lohnsumme Nichtberufsunfälle",
    "Sales": "Verkauf",
    "Sales department": "Verkaufsabteilung",
    "Sales Department": "Verkaufsabteilung",
    "Sales forecast": "Umsatzerwartung",
    "Sales forecast over last 3 years": "Umsätze der letzten 3 Jahre",
    "Sales previous year": "Umsatz Vorjahr",
    "Sales structure and payment terms": "Umsatzstruktur und Zahlungsfristen",
    "Sales volume in 000s": "Umsatzentwicklung (in 1000)",
    "Same as contact": "Gleich wie Kontakt",
    "Same as Contact Address": "Gleiche Angaben wie Kontaktadresse",
    "Save": "Speichern",
    "Save request": "Speichern",
    "Saving": "Einsparung",
    "Savings": "Prämienersparnis",
    "Savings %": "Einsparung %",
    "Savings CHF": "Einsparung CHF",
    "Select all": "Alle auswählen",
    "Select customer": "Kunde auswählen",
    "Select desired broker": "Gewünschten Broker auswählen",
    "Select insurance type": "Versicherungstyp auswählen",
    "Select Offered Insurances (max.3)": "Offerierende Versicherungen wählen (max.3)",
    "Selected by Customer": "Vom Kunden ausgewählt",
    "Selected Insurances": "Ausgewählte Versicherungen",
    "Send message": "Meldung versenden",
    "Sending E-Mail": "Sende E-Mail",
    "Senior Management": "Geschäftsleitung",
    "Senior nanagement": "Geschäftsleitung",
    "Show": "Anzeigen",
    "Hide current insurance": "Momentane Versicherung verbergen",
    "Show disabled users": "deaktivierte Benutzer anzeigen",
    "Show Offer": "Offerte anzeigen",
    "Sign the advisory mandate to get a broker": "Beratungsmandat unterzeichnen für einen Broker",
    "Special Conditions of Insurance": "Besondere Versicherungsbedingungen",
    "SMS Code": "SMS Code",
    "Snob": "Snob",
    "Something went wrong, please reload the page and try again.": "Ups, da ist was falsch gegangen. Bitte Seite nochmals laden und erneut versuchen.",
    "sometimes": "teilweise",
    "sometimes, in the following cases": "teilweise in folgenden Fällen",
    "Sometimes, in the following cases:": "teilweise in folgenden Fällen:",
    "SSL": "SSL",
    "Standard": "Standart",
    "Star": "Star",
    "Start Date": "Beginndatum",
    "Start exploring InsurTec": "InsurTec entdecken",
    "state_abstained": "Verzichtet",
    "state_bidding": "Bieten",
    "state_rejected": "Zurückgewiesen",
    "state_withdrawn": "Zurückgezogen",
    "state_created": "Erstellt",
    "state_finished": "Abgeschlossen",
    "state_pending": "Ausstehend",
    "state_pending_insurance": "Pendent: Versicherung",
    "state_pending_mandate": "Pendent: Mandat",
    "state_pending_validation": "Pendent: Validierung",
    "state_published": "Veröffentlicht",
    "Status": "Status",
    "Structure": "Struktur",
    "Structure of accounts receivable": "Debitorenstruktur",
    "Submit": "Einreichen",
    "Summary": "Zusammenfassung",
    "Switzerland": "Schweiz",
    "Systematically": "Systematisch",
    "Targ. Prem.": "Zielprämie",
    "Target premium": "Zielprämie",
    "Telephone": "Telefon",
    "Template": "Vorlage",
    "template.pdf": "template.pdf",
    "Templates": "Vorlagen",
    "Thank you for publishing your offer request. Our system will now get to work to find the best insurance premium possible": "Danke für die Veröffentlichung der Offertanfrage. Unser System startet nun seine Arbeit um den bestmöglichen Versicherungsvertrag zu finden",
    "Thank you for updating this offer request details. You can now close the page or click on the link bellow to go to dashboard.": "Vielen Dank für die Aktualisierung der Details der Offertanfrage. Sie können nun die Seite schliessen oder mit dem Link unten auf das Dashboard zurückkehren.",
    "There are currently no messages.": "Derzeit keine Meldungen.",
    "There were some errors while fetching data. Please try to reload the page manually or by clicking on the link bellow.": "Beim abrufen der Daten sind einige Fehler aufgetreten. Bitte versuchen Sie die Seite manuell neu zu laden oder klicken Sie auf den Link.",
    "There were some validation errors.": "Validierungsfehler entdeckt.",
    "These items can not be changed after offer request has been published.": "Diese Einträge können nach Veröffentlichung der Offertanfrage nicht mehr angepasst werden.",
    "These ratings can not be changed after offer has been published.": "Diese Bewertungen können nach Veröffentlichung der Offerte nicht mehr angepasst werden.",
    "This field is invalid (email).": "Bitte geben Sie eine korrekt E-Mailadresse ein",
    "This field is invalid (notEmpty).": "Bitte Feld ausfüllen",
    "This field is invalid (notNull).": "Bitte Feld ausfüllen",
    "This field is invalid (phone).": "Bitte geben Sie eine korrekte Telefonnummer ein",
    "This field is invalid (size).": "Inhalt hat eine falsche Länge",
    "This is a chanel for broadcasting message to all insurances. No other messages will be displayed here": "Dieser Kanal ist für das Versenden von Nachrichten an alle Versicherungen. Es werden hier keine Meldungen angezeigt.",
    "This is given to insurances as a target premium. This can as well be seen as a maximum premium.": "Dies wird als Zielprämie an die Versicherungen gegeben. Dies kann auch als Maximalprämie angesehen werden.",
    "This offer request ends soon.": "Offertanfrage endet bald.",
    "This will remove current advisory mandate!": "This will remove current advisory mandate!",
    "Token": "Token",
    "Total": "Gesamt",
    "Total #": "Gesamt #",
    "Total amount": "Summe",
    "Total must be between 0 and 100": "Total muss zwischen 0 und 100 sein",
    "Total number": "Gesamtzahl",
    "Total receivables (in 000s) at the end of the most recent 4 quarters:": "Gesamtaussenstände (in 1000) am Ende der letzten 4 Quartale:",
    "Total sales": "Gesamtumsatz",
    "Total Savings": "Gesamtersparnis",
    "Trading volume expectations": "Erwartetes Handelsvolumen",
    "Trend": "Trend",
    "Type": "Art",
    "Type of business": "Geschäftsgebiet",
    "Unknown error occurred": "Unbekannter Fehler aufgetreten",
    "Unknown notification": "Unbekannte Benachrichtigung",
    "Unknown response received from server": "Unbekannte Antwort vom Server erhalten",
    "Unknown response received from server.": "Unbekannte Antwort vom Server erhalten.",
    "up to 5": "bis zu 5",
    "Up to 5": "bis zu 5",
    "Update offer request data": "Daten der Offertanfrage aktualisiert",
    "Upload": "Hochladen",
    "Upload harm file": "Schadenrendement hochladen",
    "Uploading": "am hochladen",
    "USD": "USD",
    "user": "Benutzer",
    "User is being verified, please wait...": "Benutzer wird verifiziert, bitte warten...",
    "User not found.": "Benutzer nicht gefunden.",
    "Username": "Benutzername",
    "Username or password incorrect.": "Benutzername oder Passwort falsch.",
    "Username/Email": "Benutzername/Email",
    "Users": "Benutzer",
    "Utility Analysis": "Nutzwertanalyse",
    "Utility rating": "Nutzwert",
    "Utility sum": "Nutzwertsumme",
    "UVG Insurance Data": "Unfall Versicherungsdaten",
    "UVG Salaries": "UVG Löhne",
    "UVG Salaries Job Accidents": "UVG Lohn Arbeitsunfälle",
    "UVG Salaries Non Job Accidents": "UVG Lohn Nicht-Arbeitsunfälle",
    "Validate": "Validieren",
    "Validate and Publish": "Validieren & Veröffentlichen",
    "Value must be a number!": "Eingabe muss eine Zahl sein!",
    "Value must be between 0 and 100": "Wert muss zwischen 0 und 100 sein",
    "Value must have 5+ characters": "Mindestens 5 Zeichen",
    "Verify": "Verifizieren",
    "Verify Mobile": "Mobile Prüfen",
    "Verify user": "Benutzer verifizieren",
    "Verify with your mobile for better security": "Mehr Sicherheit mit Mobiletelefon Verifikation",
    "Very bad": "Sehr schlecht",
    "Very good": "Sehr gut",
    "View": "Anzeigen",
    "VIEW ALL": "alle anzeigen",
    "View details": "Details anzeigen",
    "View offer": "Offerte anzeigen",
    "We conduct the debt collection ourself": "Das Inkasso erledigen wir selbst",
    "We enforce dept collection": "Forderungsinkasso",
    "We have debt collection conducted by third parties": "Das Inkasso lassen wir durch Dritte erledigen",
    "We request annual financial statements from our customers": "Wir fordern jährliche Finanzunterlagen von unseren Kunden an",
    "We secure our receivables through a...": "Wir wenden folgende Absicherungsmöglichkeiten an...",
    "We set credit limits for our buyers": "Wir legen für unsere Käufer Kreditlimiten fest",
    "Weight": "Gewichtung",
    "Weight (%)": "Gewichtung (%)",
    "Welcome": "Willkommen",
    "Welcome to InsurTec": "Willkommen bei InsurTec",
    "Welcome to InsurTec! Create your first offer request within just a few clicks!": "Willkommen bei InsurTec! Erstellen Sie Ihre erste Offertanfrage mit wenigen Klicks!",
    "What conditions would you like?": "Welche Bedingungen möchten Sie?",
    "What is the reason for rejecting?": "Was ist der Grund des Zurückweisens?",
    "What is the reason for withdrawing?": "Was ist der Grund des Rückzugs?",
    "when reaching Offer Request End Date": "bei Erreichung Eingabeschluss",
    "when reaching Target Insurance Premium": "bei Erreichung angestrebter Versicherungsprämie",
    "Which currency do you use": "Welche Währung wird verwendet",
    "Which insurance companies would you like?": "Welche Versicherungen möchten Sie anfragen?",
    "Which insurance is this for?": "Für welche Versicherungart soll eine Anfrage erstellt werden?",
    "Withdraw": "Zurückziehen",
    "Withdraw offer": "Offerte zurückziehen",
    "Withdraw offer request": "Offertanfrage zurückziehen",
    "Withdraw reason": "Grund für Rückzug",
    "Withdrawing ...": "Zurückziehen ...",
    "withdrawn": "zurückgezogen",
    "Women": "Frauen",
    "Working, please wait": "am verarbeiten, bitte warten",
    "Write": "Schreiben",
    "Wrong data": "Falsche Daten",
    "Year": "Jahr",
    "Yearly right of termination": "Jährliches Kündigungsrecht",
    "Yes": "Ja",
    "Yes, reject offer": "Ja, Offerte zurückweisen",
    "Yes, withdraw offer": "Ja, Offerte zurückziehen",
    "Yes, withdraw offer request": "Ja, Offertanfrage zurückziehen",
    "You can enter custom reason here": "Individuellen Grund hier angeben",
    "You can enter your reason here": "Grund hier angeben",
    "You can start writing here": "Sie können hier mit Schreiben beginnen",
    "You have been selected for official offer! Please upload your official offer agreement.": "Sie wurden für eine offizielle Offerte ausgewählt! Bitte die rechtsverbindliche Offerte hochladen..",
    "You have not setup company information yet.": "Sie haben noch keine Firmeninformationen angelegt.",
    "You have to accept terms and agreements.": "Sie müssen Bedingungen und Vereinbarungen akzeptieren.",
    "You have unsaved data": "Daten noch nicht gespeichert",
    "You need to accept these to publish.": "Um zu veröffentlichen, müssen diese akzeptiert werden.",
    "Your account has been verified!": "Your account has been verified!",
    "Your biggest customers": "Ihre grössten Kunden",
    "Your password has been reset!": "Passwort wurde zurückgesetzt!",
    "Your Requests": "Ihre Anfragen",
    "Your user request is being processed!": "Die Benutzeranfage wird verarbeitet!",
    "Zip Code": "PLZ",
    "Zusatzdeckungen": "Zusatzdeckungen"
};
