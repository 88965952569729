import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {Button} from '@material-ui/core';
import {CompanyTypes} from '../../../../../../config/domain/user.js';
import {l} from '../../../../../i18n/translator';
import {objectMerge, objectProp} from '../../../../../helper/core.js';
import {isUserOfType} from '../../../../../auth/helper.js';
import {Fields, FORM_NAME} from '../../config/form';

import {formValueSelector} from 'redux-form';
import {Field} from '../redux-form-wrapper.jsx';
import {renderCheckbox, renderTextField} from '../../../../common/form/renderer.jsx';
import PersonDataFrom from '../../../../common/form/person-data/componentReduxForm.jsx';
import {FieldArray} from '../redux-form-wrapper';

const CONTACT_FIELD_PREFIX = `${Fields.CONTACT}.`;

const ContactField = ({sameAsContact, contact, ...field}) => {
    const FieldComponent = renderTextField;

    if (!sameAsContact) {
        return <FieldComponent {...field} />
    }

    const fieldName = field.input.name.replace(CONTACT_FIELD_PREFIX, '');
    const contactValue = objectProp(fieldName, contact);

    field = objectMerge(field, {input: {value: contactValue ? contactValue : undefined}});

    return <FieldComponent {...field} disabled={sameAsContact}/>
};

const JointlyInsuredCompanies = ({fields}) => {
    return <div>
        {fields.map((field, index) => (
            <Row key={index}>
                <Col sm={6}>
                    <Field name={field} component={renderTextField} fullWidth/>
                </Col>
                <Col sm={2} className="p-t-xs">
                    <Button onClick={() => fields.remove(index)}>
                        <i className="fa fa-trash"/>
                    </Button>
                </Col>
            </Row>
        ))}

        <Button disabled={fields.length >= 4} onClick={() => fields.push('')}>
            <i className="fa fa-plus m-r-xs"/>{l('Add')}
        </Button>
    </div>;
};


class ContactDataForm extends React.Component {

    render() {
        const {sameAsContact, className, auth, contact} = this.props;
        const isCustomer = isUserOfType(auth, CompanyTypes.CUSTOMER);

        const fieldProps = {sameAsContact, contact, component: ContactField, fullWidth: true};

        return (
            <div className={className}>
                <h3>
                    {l('Please input your information')}
                </h3>

                <Row className="m-b-lg">
                    <Col md={6}>
                        <Field name={Fields.BUSINESS_IDENTIFICATION_NUMBER}
                               disabled={isCustomer}
                               component={renderTextField}
                               fullWidth={true}
                               label={l('BIN')}/>
                    </Col>
                </Row>

                <Field name={Fields.SAME_AS_CONTACT} component={renderCheckbox} label={l('Same as Contact Address')}/>

                <PersonDataFrom formFields={Fields} fieldProps={fieldProps}/>

                <div className="m-t-lg"/>
                <h3>{l('Jointly insured companies')}</h3>
                <FieldArray name={Fields.JOINTLY_INSURED_COMPANIES} component={JointlyInsuredCompanies}/>
            </div>
        )
    }
}


// Decorate with connect to read form values
const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        sameAsContact: selector(state, Fields.SAME_AS_CONTACT)
    })
)(ContactDataForm);
