import React from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import {l} from '../../../../../i18n/translator';
import {Fields, FORM_NAME} from '../../config/form';
import {AnalysisFormFields} from '../../../../common/form/analysis/field/analysis.field';
import {CriteriaTypes} from '../../../../../../config/domain/analysis';

class OfferAnalysisCurrentRatingForm extends React.Component {

    render() {
        const {analysisCriteria} = this.props;

        if (!analysisCriteria || analysisCriteria.length === 0) {
            return (<div>{l('Please select insurance type.')}</div>);
        }

        return (
            <div>
                <h3 className="m-b-xl">{l('Analysis of current insurance policy')}</h3>

                <h4 className="m-b-md">{l('Premium assessment')}</h4>
                <AnalysisFormFields availableCriteria={analysisCriteria}
                                    criteriaType={CriteriaTypes.PREMIUM}
                                    criteriaFieldName={Fields.PREMIUM_ANALYSIS_CRITERIA}
                                    ratingsFieldName={Fields.CURRENT_INSURANCE_PREMIUM_ANALYSIS_RATINGS}
                                    criteriaDisabled={true}/>

                <div className="m-t-xs">
                    <i className="fa fa-warning text-danger"/>&nbsp;
                    <i>{l('These items can not be changed after offer request has been published.')}</i>
                </div>

                <div className="m-b-xl"/>

                <h4 className="m-b-md">{l('Coverage assessment')}</h4>
                <AnalysisFormFields availableCriteria={analysisCriteria}
                                    criteriaType={CriteriaTypes.COVERAGE}
                                    criteriaFieldName={Fields.COVERAGE_ANALYSIS_CRITERIA}
                                    ratingsFieldName={Fields.CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS}
                                    criteriaDisabled={true}/>

                <div className="m-t-xs">
                    <i className="fa fa-warning text-danger"/>&nbsp;
                    <i>{l('These items can not be changed after offer request has been published.')}</i>
                </div>

                {/*{this.props.hasCurrentInsurance ?*/}
                {/*    <AnalysisFormFields availableCriteria={analysisCriteria}*/}
                {/*                        criteriaType={CriteriaTypes.COVERAGE}*/}
                {/*                        criteriaDisabled={true}*/}
                {/*                        showRatings={true}*/}
                {/*                        criteriaFieldName={Fields.COVERAGE_ANALYSIS_CRITERIA}*/}
                {/*                        ratingsFieldName={Fields.CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS}/>*/}
                {/*    : null}*/}
            </div>
        )
    }
}

export default connect(
    state => ({
        analysisCriteria: state.page.createOfferRequest.data.analysisCriteria,
    }),
    dispatch => ({
        fieldChange: (field, value) => dispatch(change(FORM_NAME, field, value)),
    })
)(OfferAnalysisCurrentRatingForm);
