import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import URI from '../../../../../config/uri';
import {l} from '../../../../i18n/translator';
import {isDefined} from '../../../../helper/core.js';
import * as Ibox from '../../../ui-components/ibox.jsx';
import {PremiumValue} from '../../common/i18n/premium.jsx';
import {STEP_CURRENT_INSURER} from '../../create-request/config/wizard';
import EditButton from './editButton.jsx';
import {CurrentInsuranceFields, Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';

const CurrentPremium = ({offerRequest, insurances}) => {
    const Line = ({title, children, className}) => (
        <Row className={className}>
            <Col md={6}><strong>{title}</strong></Col>
            <Col md={6}>{children}</Col>
        </Row>
    );
    const LineOptional = ({title, children, className}) => value ? (
        <Line title={title} className={className}>{children}</Line>) : null;

    const currentInsurance = offerRequest[OfferRequestFields.CURRENT_INSURANCE];
    const currentInsuranceName = currentInsurance
        ? currentInsurance[CurrentInsuranceFields.INSURANCE_NAME]
        || (insurances.find(insurance => insurance.id === currentInsurance[CurrentInsuranceFields.INSURANCE_ID]) || {}).name
        : null;

    return (
        <Ibox.Container>
            <Ibox.Title>
                <h5>
                    <i className="fa fa-user"/>&nbsp;
                    {l('Current Insurance Data')}
                </h5>

                <EditButton step={STEP_CURRENT_INSURER}/>
            </Ibox.Title>

            {currentInsurance ? (
                <Ibox.Content>
                    <Line title={l('Insurer')}>{currentInsuranceName}</Line>
                    <Line title={l('Policy Number')}>{currentInsurance[CurrentInsuranceFields.POLICY_NUMBER]}</Line>
                    {currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS] ? (
                        <Line title={l('Gross premium')}>
                            <PremiumValue value={currentInsurance[CurrentInsuranceFields.PREMIUM_GROSS]}/>
                        </Line>
                    ) : null}
                    {/*{currentInsurance[CurrentInsuranceFields.PREMIUM_NET] ? (
                        <Line title={l('Net premium')}>
                            <PremiumValue value={currentInsurance[CurrentInsuranceFields.PREMIUM_NET]}/>
                        </Line>
                    ) : null}*/}

                    {isDefined(currentInsurance[CurrentInsuranceFields.ADJUSTMENTS_ONGOING]) ? (
                        <Line title={l('Adjustments ongoing')}>
                            {currentInsurance[CurrentInsuranceFields.ADJUSTMENTS_ONGOING] ? l('Yes') : l('No')}
                        </Line>
                    ) : null}
                    <Line title={l('Harm history')}>
                        {currentInsurance[CurrentInsuranceFields.NO_HARM_HISTORY] ? l('No harm history') :
                            offerRequest.files && offerRequest.files.harmHistory && offerRequest.files.harmHistory.length > 0 ?
                                offerRequest.files.harmHistory.map(file =>
                                    offerRequest.id ? (
                                        <div key={file.id}>
                                            <Link
                                                key={file.id}
                                                to={URI.OFFER_REQUEST_FILE.replace(':id', offerRequest.id).replace(':fileId', file.id)}
                                                target="_blank">
                                                {file.fileName}
                                            </Link>
                                        </div>
                                    ) : (
                                        <div key={file.id}>
                                            <Link key={file.id} to={URI.FILE.replace(':id', file.id)} target="_blank">
                                                {file.fileName}
                                            </Link>
                                        </div>
                                    )
                                ) : null}
                    </Line>
                    {offerRequest[OfferRequestFields.PREVIOUS_INSURANCE_ID] ?
                        <>
                            <Line title={l('Previous insurance')} className="m-t-md">
                                {(insurances.find(insurance => insurance.id === offerRequest[OfferRequestFields.PREVIOUS_INSURANCE_ID]) || {}).name}
                            </Line>
                            <Line title={l('Previous insurance policy number')}>
                                {offerRequest[OfferRequestFields.PREVIOUS_INSURANCE_POLICY_NUMBER]}
                            </Line>
                        </>
                        : null}
                </Ibox.Content>
            ) : (
                <Ibox.Content className="no-data">
                    {l('No current insurance')}
                </Ibox.Content>
            )}
        </Ibox.Container>
    );
};

export default CurrentPremium;
