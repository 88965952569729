import React from 'react';
import {l} from '../../i18n/translator';
import {Fields as OfferFields, States as OfferStates} from '../../../config/domain/offer';
import {Fields as OfferRequestFields} from '../../../config/domain/offerRequest';
import {
    CRITERIA_ID_PREMIUM,
    CriteriaFields,
    CriteriaTypes,
    RatingFields,
    RatingTypes as CriteriaRatingTypes,
} from '../../../config/domain/analysis';
import {isDefined} from '../../helper/core';

export const ratingDefaultOptionLabelFor = (ratingData) => {
    const number = parseFloat(ratingData);
    const roundedNumber = Math.round(number / 10 * 4) / 4 * 10;
    switch (roundedNumber) {
        case 0:
            return l('Very bad');
        case 2.5:
            return l('Bad');
        case 5:
            return l('Neutral');
        case 7.5:
            return l('Good');
        case 10:
            return l('Very good');
        default:
            return '';
    }
};
const createRatingDefaultOption = (ratingData) => ({value: ratingData, label: ratingDefaultOptionLabelFor(ratingData)});
export const ratingDefaultOptions = () => {
    return [10, 7.5, 5, 2.5, 0].map((ratingData) => createRatingDefaultOption(ratingData));
};


export const descriptionValidator = (value) => {
    const response = {isValid: true, notification: {type: 'success', msg: '', title: ''}};
    if (value && value.length < 5) {
        response.isValid = false;
        response.notification.type = 'error';
        response.notification.msg = l('Value must have 5+ characters');
        response.notification.title = l('Invalid Value');
    }
    return response;
};

export const validateWeightPercentageInput = (weight) => {
    if (weight === '' || !isDefined(weight)) {
        return true;
    }
    if (!/^\d+?(\.\d*)?$/.test(weight)) {
        return {valid: false, message: l('Value must be a number!')};
    }
    let weightNumber = parseFloat(weight);
    if (weightNumber < 0 || weightNumber > 100) {
        return {valid: false, message: l('Value must be between 0 and 100')};
    }
    return true;
};

export const formatPoints = (value) => {
    if (isNaN(value) || value === undefined || value === null || value === '') return '';
    return round(value, 2);
};

export const formatPercentage = (value) => {
    if (isNaN(value) || value === undefined || value === null || value === '') return '';
    return (<span>{round(value, 2)} %</span>);
};
export const formatPercentageFloat = (value) => {
    if (isNaN(value) || value === undefined || value === null || value === '') return '';
    return (<span>{formatPercentageFloatString(value)}</span>);
};
export const formatPercentageFloatString = (value) => {
    if (isNaN(value) || value === undefined || value === null || value === '') return '';
    return round(value * 100, 2) + ' %';
};

export const fixWeightRounding = (weight) => fixDecimals(weight, 10);

export const fixDecimals = (number, numberOfDecimals = 2) => {
    return parseFloat(number.toFixed(numberOfDecimals))
};

export const parseFloatDefault = (float, defaultFloat) => {
    const numberA = parseFloat(float);
    return isNaN(numberA) ? defaultFloat : numberA;
};


export const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};


export const calcPoints = (weight, rating) => {
    if (weight !== undefined && weight !== null && rating !== undefined && rating !== null) {
        return weight * parseFloat(rating);
    }
    return 0;
};

export const calcPremiumPercentage = (weight, rating) => {
    return calcPercentage(weight, rating, 10, 0);
};

export const calcPercentage = (weight, rating, maxValue = 10, minValue = 0) => {
    if (weight !== undefined && weight !== null && rating !== undefined && rating !== null) {
        const offsetter = (maxValue - minValue) / 2;
        return weight * (-(rating - minValue - offsetter) / (offsetter));
    }
    return 0;
};


export const createOffersWithAnalysis = (offerRequest, offers, options) => {
    options = options || {};
    const onlyPublished = isDefined(options.onlyPublished) ? options.onlyPublished : true;
    const filteredOffers = onlyPublished
        ? offers.filter(offer => offer.state === OfferStates.PUBLISHED || offer.state === OfferStates.ABSTAINED)
        : offers;
    if (filteredOffers.length === 0) {
        return [];
    }
    const weightPercentage = new WeightPercentage(getCriteriaFromOfferRequest(offerRequest));

    const premiumAnalysisData = extractAnalysisData(offerRequest[OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA]);
    const coverageAnalysisData = extractAnalysisData(offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA]);

    const offersWithAnalysis = filteredOffers
        .map((offer) => {
            const coverageAnalysisRatings = offer[OfferFields.COVERAGE_ANALYSIS_RATINGS] ? offer[OfferFields.COVERAGE_ANALYSIS_RATINGS] : [];
            const coverageAnalysisTotal = coverageAnalysisRatings.reduce((previousValue, rating) => {
                const criteria = coverageAnalysisData.getCriteriaById(rating.criteriaId);
                if (!rating.rating) {
                    return previousValue;
                }
                const weight = weightPercentage.getWeightPercentageForCriteria(criteria);
                return previousValue + calcPoints(weight, rating.rating, criteria.id);
            }, 0);

            const premiumAnalysisRatings = offer[OfferFields.PREMIUM_ANALYSIS_RATINGS] ? offer[OfferFields.PREMIUM_ANALYSIS_RATINGS] : [];
            const premiumAnalysisTotal = premiumAnalysisRatings.reduce((previousValue, rating) => {
                const criteria = premiumAnalysisData.getCriteriaById(rating.criteriaId);
                if (criteria.id === CRITERIA_ID_PREMIUM || !rating.rating) {
                    return previousValue;
                }
                const weight = weightPercentage.getWeightPercentageForCriteria(criteria);
                return previousValue + calcPremiumPercentage(weight, rating.rating, criteria.id);
            }, 1);
            return {
                offer: offer,
                coverageAnalysisTotal: coverageAnalysisTotal,
                premiumAnalysisTotal: premiumAnalysisTotal,
                adjustedPremium: offer[OfferFields.PREMIUM_GROSS] * premiumAnalysisTotal
            };
        });

    const offersWithAnalysisForRanking = offersWithAnalysis
        .filter(item => item.offer.state === OfferStates.PUBLISHED && item.offer.id !== -1);

    const maxPremium = offersWithAnalysisForRanking.reduce((previousMax, item) => Math.max(previousMax, item.adjustedPremium), 0);
    const minPremium = offersWithAnalysisForRanking.reduce((previousMin, item) => Math.min(previousMin, item.adjustedPremium), Number.MAX_SAFE_INTEGER);
    const premiumDiff = maxPremium - minPremium;

    offersWithAnalysis.forEach((item) => {
        if (item.offer.state === OfferStates.ABSTAINED) {
            item.perfectDistance = Number.MAX_SAFE_INTEGER;
        } else {
            const premiumDistance = premiumDiff === 0.0 ? 0.5 : (item.adjustedPremium - minPremium) / premiumDiff; // 0.0 = top, 1.0 = bottom
            const coverageDistance = 1 - (item.coverageAnalysisTotal / 10.0); // 0.0 = left, 1.0 = right
            item.perfectDistance = Math.sqrt(Math.pow(premiumDistance, 2) + Math.pow(coverageDistance, 2));
        }
    });
    return offersWithAnalysis;
};

export const createRatingCalculator = (offerRequest, offers, optionals) => {
    const criteriaType = (optionals && optionals.criteriaType) ? optionals.criteriaType : null;
    const criteria = (optionals && optionals.criteria) ? optionals.criteria : getCriteriaFromOfferRequest(offerRequest, criteriaType);

    const filteredOffers = (offers || []).filter(offer => offer.state === OfferStates.PUBLISHED || offer.state === OfferStates.ABSTAINED);
    // addCurrentInsuranceAsOffer(offerRequest, filteredOffers);

    // criteria id -> { maxRatingData, minRatingData, criteria}
    const criteriaRatingsMap = {};

    // offers
    filteredOffers.forEach((offer) => {
        const ratings = getRatingsFromOffer(offer, criteriaType);
        updateRatingsMapWithRatings(criteriaRatingsMap, ratings);
    });
    // as well all utility ratings (from offer request only)
    if (offerRequest) {
        const utilityRatings = offerRequest[OfferRequestFields.UTILITY_ANALYSIS_RATINGS] || [];
        updateRatingsMapWithRatings(criteriaRatingsMap, utilityRatings);
    }
    criteria.forEach((criteria) => {
        const criteriaId = criteria[CriteriaFields.ID];

        const criteriaTargetRatingData = criteria[CriteriaFields.TARGET_RATING_DATA];
        if (isDefined(criteriaTargetRatingData)) {
            updateRatingsMapWithRatingData(criteriaRatingsMap, criteriaId, criteriaTargetRatingData);
        }

        const criteriaRatings = criteriaRatingsMap[criteriaId] || {};
        criteriaRatings.criteria = criteria;
        criteriaRatingsMap[criteriaId] = criteriaRatings;
    });

    const criteriaRatingCalculator = {};
    addRatingCalculatorToMap(criteriaRatingCalculator, criteria, criteriaRatingsMap);
    if (offerRequest) {
        addRatingCalculatorToMap(criteriaRatingCalculator, getCriteriaFromOfferRequest(offerRequest), criteriaRatingsMap);
    }

    const weightPercentage = new WeightPercentage(criteria);

    return {
        calculateRating: (criteriaId, ratingData) => {
            if (!criteriaRatingCalculator[criteriaId]) return 0;

            if (!isDefined(ratingData)) {
                ratingData = getDefaultRatingData(criteriaRatingsMap[criteriaId].criteria);
            }

            return criteriaRatingCalculator[criteriaId](ratingData);
        },
        calculatePoints: (criteriaId, ratingData) => {
            if (!criteriaRatingsMap[criteriaId]) return 0;

            const criteria = criteriaRatingsMap[criteriaId].criteria;
            if (!isDefined(ratingData)) ratingData = getDefaultRatingData(criteria);

            const weight = weightPercentage.getWeightPercentageForCriteria(criteria);
            const rating = criteriaRatingCalculator[criteriaId](ratingData);
            if (rating === undefined || rating === null) return 0;

            if (criteria[CriteriaFields.TYPE] === CriteriaTypes.PREMIUM) {
                return calcPremiumPercentage(weight, rating);
            }
            return rating * weight;
        },
        getDefaultRatingData(criteriaId) {
            if (!criteriaRatingsMap[criteriaId]) return undefined;

            const criteria = criteriaRatingsMap[criteriaId].criteria;
            return getDefaultRatingData(criteria);
        },
        getWeightPercentage(criteriaId) {
            const item = criteriaRatingsMap[criteriaId];
            if (!item) return 0;

            const criteria = item.criteria;
            return weightPercentage.getWeightPercentageForCriteria(criteria);
        }
    };
};

export const getDefaultRatingData = function (criteria) {
    if (criteria[CriteriaFields.RATING_TYPE] === CriteriaRatingTypes.CHECKBOX) {
        return 0;
    } else {
        return undefined;
    }
};

export const addRatingCalculatorToMap = (map, criteria, criteriaRatingsMap) => {
    if (!criteria || criteria.length === 0) return;

    criteria.forEach((c) => {
        map[c.id] = createCriteriaRatingCalculator(c, criteriaRatingsMap);
    });
};

let getRatingsFromOffer = (offer, criteriaType) => {
    const premiumRatings = offer[OfferFields.PREMIUM_ANALYSIS_RATINGS] || [];
    const coverageRatings = offer[OfferFields.COVERAGE_ANALYSIS_RATINGS] || [];
    switch (criteriaType) {
        case CriteriaTypes.PREMIUM:
            return premiumRatings;
        case CriteriaTypes.COVERAGE:
            return coverageRatings;
        default:
            return premiumRatings.concat(coverageRatings);
    }
};

let getCriteriaFromOfferRequest = (offerRequest, criteriaType) => {
    if (!offerRequest) return [];

    const premiumCriteria = offerRequest[OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA] || [];
    const coverageCriteria = offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA] || [];
    const utilityCriteria = offerRequest[OfferRequestFields.UTILITY_ANALYSIS_CRITERIA] || [];
    switch (criteriaType) {
        case CriteriaTypes.PREMIUM:
            return premiumCriteria;
        case CriteriaTypes.COVERAGE:
            return coverageCriteria;
        case CriteriaTypes.UTILITY:
            return utilityCriteria;
        default:
            return premiumCriteria.concat(coverageCriteria).concat(utilityCriteria);
    }
};

let updateRatingsMapWithRatingData = function (criteriaRatingsMap, criteriaId, ratingData) {
    const criteriaRatings = criteriaRatingsMap[criteriaId] || {};
    if (isDefined(ratingData)) {
        if (criteriaRatings.ratingDataMax === undefined || ratingData > criteriaRatings.ratingDataMax) {
            criteriaRatings.ratingDataMax = ratingData;
        }
        if (criteriaRatings.ratingDataMin === undefined || ratingData < criteriaRatings.ratingDataMin) {
            criteriaRatings.ratingDataMin = ratingData;
        }
    }
    criteriaRatingsMap[criteriaId] = criteriaRatings;
};

let updateRatingsMapWithRatings = function (criteriaRatingsMap, ratings) {
    ratings.forEach((rating) => {
        const criteriaId = rating[RatingFields.CRITERIA_ID];
        const ratingData = rating[RatingFields.RATING_DATA];
        updateRatingsMapWithRatingData(criteriaRatingsMap, criteriaId, ratingData);
    });
};

let createCriteriaRatingCalculator = function (criteria, criteriaRatingsMap) {
    // if undefined, null or default -> simple function!
    switch (criteria[CriteriaFields.RATING_TYPE]) {
        case CriteriaRatingTypes.NUMBER:
            return (ratingData) => {
                if (ratingData === undefined || ratingData === null) return null;
                let max = (criteriaRatingsMap[criteria.id] || {}).ratingDataMax;
                let min = (criteriaRatingsMap[criteria.id] || {}).ratingDataMin;
                if (max === undefined || ratingData > max) max = ratingData;
                if (min === undefined || ratingData < min) min = ratingData;

                // if we don't have a range (only one rating, or all the same), just return 5;
                if (min === ratingData && max === ratingData) return 5;

                if (criteria[CriteriaFields.RATING_TYPE_CONFIG].higherIsBetter) {
                    return (ratingData - min) / (max - min) * 10;
                } else {
                    return (1 - (ratingData - min) / (max - min)) * 10;
                }
            };
        case CriteriaRatingTypes.CHECKBOX: {
            const checkedRating = criteria[CriteriaFields.RATING_TYPE_CONFIG].checkedRating;
            const uncheckedRating = criteria[CriteriaFields.RATING_TYPE_CONFIG].uncheckedRating;
            return (ratingData) => {
                if (ratingData) {
                    return checkedRating !== undefined && checkedRating !== null ? checkedRating : 10;
                } else {
                    return uncheckedRating !== undefined && uncheckedRating !== null ? uncheckedRating : 0;
                }
            };
        }
        case CriteriaRatingTypes.OPTIONS: // rating is already stored in rating && ratingData
        case CriteriaRatingTypes.DEFAULT:
        default:
            return (ratingData) => ratingData;
    }
};

export const sortAnalysisCriteria = (analysisCriteria) => {
    if (!isDefined(analysisCriteria)) return null;

    return analysisCriteria.sort(
        analysisCriteria.length > 0 && analysisCriteria.every(c => isDefined(c[CriteriaFields.ORDER]))
            ? (c1, c2) => c1[CriteriaFields.ORDER] - c2[CriteriaFields.ORDER]
            : (c1, c2) => c2[CriteriaFields.WEIGHT] - c1[CriteriaFields.WEIGHT]
    );
};

export const criteriaArraysEqual = (a1, a2) =>{
    if (isDefined(a1) !== isDefined(a2)) return false;
    if (a1.length !== a2.length)return false;
    return a1.every((c1, index)=> {
        const c2 = a2[index];
        return c1[CriteriaFields.ID] === c2[CriteriaFields.ID]
            && c1[CriteriaFields.WEIGHT] === c2[CriteriaFields.WEIGHT]
            && c1[CriteriaFields.ORDER] === c2[CriteriaFields.ORDER]
            && c1[CriteriaFields.TARGET_RATING_DATA] === c2[CriteriaFields.TARGET_RATING_DATA];
    });
};

export const extractAnalysisData = (analysisCriteria) => {
    // analysisObject
    const internalData = {
        criteria: analysisCriteria ? sortAnalysisCriteria([...analysisCriteria]) : [],
        idToIndex: {},
    };
    const result = {
        internal: internalData,
        getIndexForCriteriaId: (criteriaId) => internalData.idToIndex[criteriaId],
        getCriteriaById: (criteriaId) => internalData.criteria[internalData.idToIndex[criteriaId]],
        forEachCriteria: (callbackfn) => internalData.criteria.forEach(callbackfn),
        getCriteriaByIndex: (index) => internalData.criteria[index]
    };

    const weightPercentageCalculator = new WeightPercentage(internalData.criteria);

    const criteriaCount = internalData.criteria.length;
    for (let i = 0; i < criteriaCount; i++) {
        const item = internalData.criteria[i];
        internalData.criteria[i] = {
            ...item,
            [CriteriaFields.WEIGHT_PERCENTAGE]: weightPercentageCalculator.getWeightPercentageForCriteria(item)
        };
        internalData.idToIndex[item.id] = i;
    }

    return result;
};

class WeightPercentage {
    constructor(criteria) {
        this.premiumTotalWeight = this.totalWeightFor(criteria, CriteriaTypes.PREMIUM);
        this.coverageTotalWeight = this.totalWeightFor(criteria, CriteriaTypes.COVERAGE);
        this.utilityTotalWeight = this.totalWeightFor(criteria, CriteriaTypes.UTILITY);
    }

    getWeightPercentageForCriteria(criteria) {
        let totalWeight = this.getTotalWeightFor(criteria);
        if (totalWeight > 0 && criteria[CriteriaFields.WEIGHT]) {
            return criteria[CriteriaFields.WEIGHT] / totalWeight;
        }
        return 0;
    }

    getTotalWeightFor(criteria) {
        switch (criteria[CriteriaFields.TYPE]) {
            case CriteriaTypes.PREMIUM:
                return this.premiumTotalWeight;
            case CriteriaTypes.COVERAGE:
                return this.coverageTotalWeight;
            case CriteriaTypes.UTILITY:
                return this.utilityTotalWeight;
            default:
                return 0;
        }
    }

    totalWeightFor(criteria, criteriaType) {
        return criteria.filter((c) => c[CriteriaFields.TYPE] === criteriaType).reduce((accumulator, currentValue) => accumulator + (currentValue[CriteriaFields.WEIGHT] || 0), 0);
    }
}


export const colorForRank = (rank) => {
    switch (rank) {
        case 1:
            return '#CFB53B';
        case 2:
            return '#c0c1cc';
        case 3:
            return '#8C7853';
        default:
            return 'black';
    }
};

export const colorForId = (str, alpha = 1) => {
    let hashValue = hash(str);
    let colour = '#';
    let colourRgba = 'rgba(';
    for (let i = 0; i < 3; i++) {
        const value = (hashValue >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
        colourRgba += value + ',';
    }
    colourRgba += alpha + ')';
    return alpha ? colourRgba : colour;
};

// from https://github.com/darkskyapp/string-hash
const hash = (str) => {
    let hash = 5381,
        i = str.length;

    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
     * integers. Since we want the results to be always positive, convert the
     * signed int to an unsigned by doing an unsigned bitshift. */
    return hash >>> 0;
};
