import React from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import {Button} from '@material-ui/core';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import {l} from '../../../../../i18n/translator';
import {Fields, FORM_NAME} from '../../config/form';
import {AnalysisFormFields} from '../../../../common/form/analysis/field/analysis.field';
import Select from '../../../../common/form/select.jsx';
import {CriteriaTypes, TemplateFields} from '../../../../../../config/domain/analysis';
import {applyAnalysisTemplate} from '../../action/page';

class OfferAnalysisCurrentRatingForm extends React.Component {

    componentWillMount() {
        this.setState({});
    }

    onChangeTemplateId(templateId) {
        this.setState({...this.state, templateId: templateId});
    }

    onApplyTemplate() {
        const templateId = this.state.templateId;
        const template = this.props.analysisTemplates.find(template => template[TemplateFields.ID] === templateId);
        this.props.applyAnalysisTemplate(template);
    }

    render() {
        const {analysisCriteria, analysisTemplates} = this.props;

        if (!analysisCriteria || analysisCriteria.length === 0) {
            return (<div>{l('Please select insurance type.')}</div>);
        }

        return (
            <div>
                {/*<h3 className="m-b-xl">{l('Analysis criteria and targets')}</h3>*/}

                {analysisTemplates.length > 0 ?
                    <div className="m-b-lg">
                        <h4>{l('Templates')}</h4>
                        <Select
                            name='templateId'
                            options={analysisTemplates.map(template => ({
                                label: template[TemplateFields.NAME],
                                value: template[TemplateFields.ID],
                            }))}
                            value={this.state.templateId}
                            onChange={this.onChangeTemplateId.bind(this)}
                            fullWidth/>
                        <Button variant="text" color="primary" className="m-t-sm"
                                disabled={!this.state.templateId}
                                onClick={this.onApplyTemplate.bind(this)}>{l('Apply template')}</Button>
                    </div> : null}

                <h4 className="m-b-md">{l('Premium assessment')}</h4>
                <AnalysisFormFields availableCriteria={analysisCriteria}
                                    criteriaType={CriteriaTypes.PREMIUM}
                                    targetRatings={true}
                                    criteriaFieldName={Fields.PREMIUM_ANALYSIS_CRITERIA}/>

                <div className="m-t-xs">
                    <i className="fa fa-warning text-danger"/>&nbsp;
                    <i>{l('These items can not be changed after offer request has been published.')}</i>
                </div>

                <div className="m-b-xl"/>

                <h4 className="m-b-md">{l('Coverage assessment')}</h4>
                <AnalysisFormFields availableCriteria={analysisCriteria}
                                    criteriaType={CriteriaTypes.COVERAGE}
                                    targetRatings={true}
                                    criteriaFieldName={Fields.COVERAGE_ANALYSIS_CRITERIA}/>

                <div className="m-t-xs">
                    <i className="fa fa-warning text-danger"/>&nbsp;
                    <i>{l('These items can not be changed after offer request has been published.')}</i>
                </div>

                {/*{this.props.hasCurrentInsurance ?*/}
                {/*    <AnalysisFormFields availableCriteria={analysisCriteria}*/}
                {/*                        criteriaType={CriteriaTypes.COVERAGE}*/}
                {/*                        criteriaDisabled={true}*/}
                {/*                        showRatings={true}*/}
                {/*                        criteriaFieldName={Fields.COVERAGE_ANALYSIS_CRITERIA}*/}
                {/*                        ratingsFieldName={Fields.CURRENT_INSURANCE_COVERAGE_ANALYSIS_RATINGS}/>*/}
                {/*    : null}*/}
            </div>
        )
    }
}

export default connect(
    state => ({
        analysisCriteria: state.page.createOfferRequest.data.analysisCriteria,
        analysisTemplates: state.page.createOfferRequest.data.analysisTemplates || [],
    }),
    dispatch => ({
        fieldChange: (field, value) => dispatch(change(FORM_NAME, field, value)),
        applyAnalysisTemplate: (template) => dispatch(applyAnalysisTemplate(template)),
    })
)(OfferAnalysisCurrentRatingForm);
