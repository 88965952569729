import React from 'react';
import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import {l} from '../../../../i18n/translator';
import {ACTIONS} from '../../../../../config/domain/entity.js';
import {CompanyTypes as UserTypes} from '../../../../../config/domain/user';
import {
    CurrentInsuranceFields,
    Fields as OfferRequestFields,
    States as OfferRequestStates
} from '../../../../../config/domain/offerRequest';
import {isUserOfType} from '../../../../auth/helper.js';
import {isDefined} from '../../../../helper/core.js';
import {LocalDate} from '../../../common/i18n/datetime.jsx';
import {PremiumValue} from '../../common/i18n/premium.jsx';
import {DetailsLink, EditLink} from './link.jsx';
import {EntityActionOnly} from '../../../common/auth/acl/entityAction.jsx';
import {getInsuranceTypeName} from '../../../common/insurnaceType.js';
import {
    Column as OfferRequestColumn,
    EndDateCellComponent,
    favoriteValueFormat,
    PremiumValueComponent,
    SavingsCellComponent,
    Table as OfferRequestTable
} from './table.jsx';

class OfferRequestList extends React.Component {

    removeDataTables() {
        if (this._dataTable) {
            this._dataTable.destroy(false);
        }
    }

    initDataTables() {
        if (this._tableNode) {
            this._dataTable = $(this._tableNode).DataTable({
                dom: 'fBlrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'pdf', 'print'
                ],
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, l('All')]],
                "language": {
                    "decimal": l('data_table_decimal'),
                    "emptyTable": '&nbsp;<br/>' + l('data_table_emptyTable') + '<br/>&nbsp;',
                    "info": l('data_table_info'),
                    "infoEmpty": l('data_table_infoEmpty'),
                    "infoFiltered": l('data_table_infoFiltered'),
                    "infoPostFix": l('data_table_infoPostFix'),
                    "thousands": l('data_table_thousands'),
                    "lengthMenu": l('data_table_lengthMenu'),
                    "loadingRecords": l('data_table_loadingRecords'),
                    "processing": l('data_table_processing'),
                    "search": l('data_table_search'),
                    "zeroRecords": l('data_table_zeroRecords'),
                    "paginate": {
                        "first": l('data_table_first'),
                        "last": l('data_table_last'),
                        "next": l('data_table_next'),
                        "previous": l('data_table_previous'),
                    },
                    "aria": {
                        "sortAscending": l('data_table_sortAscending'),
                        "sortDescending": l('data_table_sortDescending'),
                    }
                }
            });
        }
    }

    componentDidMount() {
        this.initDataTables();
    }

    componentWillUnmount() {
        this.removeDataTables();
    }

    componentWillUpdate() {
        this.removeDataTables();
    }

    componentDidUpdate() {
        this.initDataTables();
    }


    render() {
        const {auth, offerRequestList, insuranceTypes, deleting, actions: {deleteOfferRequest}} = this.props;

        return (
            <OfferRequestTable
                items={offerRequestList}
                className="table-hover table-striped"
                tableRef={node => this._tableNode = node}
            >
                <OfferRequestColumn name={l('Number')} field="id"/>

                <OfferRequestColumn name={l('Type')} field="insuranceTypeId"
                                    valueFormat={(id) => getInsuranceTypeName(insuranceTypes, id)}/>

                {isUserOfType(auth, [UserTypes.CUSTOMER, UserTypes.BROKER]) && (
                    <OfferRequestColumn
                        name={l('Curr. Premium')}
                        field={OfferRequestFields.CURRENT_INSURANCE + "." + CurrentInsuranceFields.PREMIUM_GROSS}
                        valueComponent={PremiumValueComponent}
                        attributes={(value => ({'data-sort': value}))}
                    />
                )}

                <OfferRequestColumn
                    name={l('Offer round end')}
                    cellComponent={EndDateCellComponent}
                />

                <OfferRequestColumn name={l('# Offers')} field="offerCount"/>

                <OfferRequestColumn name={l('Status')} field="state" valueFormat={(state) => l(`state_${state}`)}/>

                <OfferRequestColumn
                    name={l('Min. offer')}
                    cellComponent={({item, Cell}) => (
                        <Cell data-sort={item.offerPremiumGrossMin}>
                            {isDefined(item.offerPremiumGrossMin) && (
                                <span>
                                    <PremiumValue value={item.offerPremiumGrossMin}/>
                                </span>
                            )}
                        </Cell>
                    )}
                />

                {isUserOfType(auth, UserTypes.CUSTOMER) && (
                    <OfferRequestColumn name={l('Savings')} cellComponent={SavingsCellComponent}/>
                )}

                <OfferRequestColumn
                    name={l('Last offer')}
                    field="offerCreationDateLatest"
                    valueComponent={LocalDate}
                    attributes={(value => ({'data-sort': value}))}
                />

                {isUserOfType(auth, [UserTypes.INSURANCE, UserTypes.BROKER]) && (
                    <OfferRequestColumn name={l('Customer name')} field="customerName"/>
                )}

                {isUserOfType(auth, [UserTypes.INSURANCE, UserTypes.BROKER]) && (
                    <OfferRequestColumn field="favorite" valueFormat={favoriteValueFormat}/>
                )}

                <OfferRequestColumn cellComponent={({item}) => {
                    const offerRequest = item;
                    const isDeleting = deleting.indexOf(offerRequest.id) !== -1;

                    return (
                        <td>
                            <span>
                                <EntityActionOnly action={ACTIONS.VIEW} allowedActions={offerRequest.actions}>
                                    {offerRequest.state === OfferRequestStates.CREATED ? (
                                        <DetailsLink offerRequest={offerRequest} className="m-r-xs">
                                            <i className="fa fa-eye" style={{color: 'blue'}}/>
                                        </DetailsLink>
                                    ) : null}
                                </EntityActionOnly>

                                <EntityActionOnly action={ACTIONS.EDIT} allowedActions={offerRequest.actions}>
                                    {offerRequest.state !== OfferRequestStates.CREATED ? (
                                        <EditLink offerRequest={offerRequest} className="m-r-xs">
                                            <i className="fa fa-pencil" style={{color: 'blue'}}/>
                                        </EditLink>
                                    ) : null}
                                </EntityActionOnly>

                                <EntityActionOnly action={ACTIONS.DELETE} allowedActions={offerRequest.actions}>
                                    <Button
                                        bsStyle="link"
                                        bsSize="xsmall"
                                        disabled={isDeleting}
                                        onClick={() => deleteOfferRequest(offerRequest.id)}
                                    >
                                        <i className={classNames('fa', {
                                            'fa-trash': !isDeleting,
                                            'fa-cog': isDeleting
                                        })} style={{color: 'red'}} title={l('Delete')}/>
                                    </Button>
                                </EntityActionOnly>
                            </span>
                        </td>
                    );
                }}/>
            </OfferRequestTable>
        );
    }
}

export default OfferRequestList;
