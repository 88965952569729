import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {Types as InsuranceTypes} from '../../../../config/domain/insurance.js';
import {InsuranceType} from '../../insurance-offer/common/insuranceType.jsx';
import {Fields, FORM_NAME} from '../../insurance-offer/create-request/config/form';
import {SickPayCurrentInsuranceAdditionalForm} from './sick_pay/sick_pay.currentInsurance.form';

const CurrentInsuranceAdditionalForm = ({insuranceTypeId, ...props}) => {
    return (
        <InsuranceType.Container activeType={insuranceTypeId}>
            <InsuranceType.Type type={InsuranceTypes.SICK_PAY}>
                <div className="m-t-md"/>
                <SickPayCurrentInsuranceAdditionalForm {...props} />
            </InsuranceType.Type>

            <InsuranceType.Default>
                <div/>
            </InsuranceType.Default>
        </InsuranceType.Container>
    )
};

const selector = formValueSelector(FORM_NAME);

export default connect(
    state => ({
        insuranceTypeId: selector(state, Fields.INSURANCE_TYPE_ID),
        offerRequestId: selector(state, Fields.ID)
    })
)(CurrentInsuranceAdditionalForm);
