import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Button} from '@material-ui/core';
import {Field, FieldArray} from '../../../insurance-offer/create-request/component/redux-form-wrapper';
import {sickPayFieldKey as dataFieldKey} from '../../../insurance-offer/create-request/helper';
import {
    CaseFields,
    CategoryFields,
    Fields as SickPayDataFields
} from '../../../../../config/domain/offerRequest/sick_pay';
import {l} from '../../../../i18n/translator';
import {renderCurrencyField, renderDatePicker, renderNumberField, renderTextField} from '../../form/renderer';

const Categories = ({fields}) => {
    return <div>
        <Row style={{fontWeight: 'bold'}}>
            <Col sm={1}>#</Col>
            <Col sm={3}>{l('Kategorie')}</Col>
            <Col sm={3}>{l('Wartefrist (Tage)')}</Col>
            <Col sm={1}/>
        </Row>

        {fields.map((field, index) => (
            <Row key={index}>
                <Col sm={1} className="p-t-xs">{index + 1}</Col>
                <Col sm={3}><Field name={`${field}.${CategoryFields.NAME}`} component={renderTextField}
                                   fullWidth/></Col>
                <Col sm={3}><Field name={`${field}.${CategoryFields.WAITING_PERIOD}`} component={renderNumberField}
                                   append={l('days')} decimals={0} rightAlign fullWidth/></Col>
                <Col sm={1} className="p-t-xs">
                    <Button onClick={() => fields.remove(index)}>
                        <i className="fa fa-trash"/>
                    </Button>
                </Col>
            </Row>
        ))}

        <Button disabled={fields.length >= 4} onClick={() => fields.push({})}>
            <i className="fa fa-plus m-r-xs"/>{l('Add')}
        </Button>
    </div>;
};

const Cases = ({fields}) => {
    return <div>
        <Row style={{fontWeight: 'bold'}}>
            <Col sm={1}>{l('Fall Nr.')}</Col>
            <Col sm={2}>{l('Beginn AUF')}</Col>
            <Col sm={1}>{l('Grad AUF')}</Col>
            <Col sm={2}>{l('versicherter Tagesansatz')}</Col>
            <Col sm={2}>{l('voraussichtliche Dauer (Tage)')}</Col>
            <Col sm={3}>{l('Ursache')}</Col>
            <Col sm={1}/>
        </Row>

        {fields.map((field, index) => (
            <Row key={index}>
                <Col sm={1} className="p-t-xs">{index + 1}</Col>
                <Col sm={2}>
                    <Field name={`${field}.${CaseFields.START_DATE}`} component={renderDatePicker} fullWidth/>
                </Col>
                <Col sm={1}>
                    <Field name={`${field}.${CaseFields.GRADE}`} component={renderNumberField} decimals={0} append={'%'}
                           rightAlign fullWidth/>
                </Col>
                <Col sm={2}>
                    <Field name={`${field}.${CaseFields.INSURED_DAILY_RATE}`} component={renderCurrencyField}
                           rightAlign fullWidth/>
                </Col>
                <Col sm={2}>
                    <Field name={`${field}.${CaseFields.ESTIMATED_DURATION}`} component={renderNumberField}
                           decimals={0} append={l('days')} rightAlign fullWidth/>
                </Col>
                <Col sm={3}>
                    <Field name={`${field}.${CaseFields.CAUSE}`} component={renderTextField} fullWidth/>
                </Col>
                <Col sm={1} className="p-t-xs">
                    <Button onClick={() => fields.remove(index)}>
                        <i className="fa fa-trash"/>
                    </Button>
                </Col>
            </Row>
        ))}


        <Button disabled={fields.length >= 4} onClick={() => fields.push({})}>
            <i className="fa fa-plus m-r-xs"/>{l('Add')}
        </Button>
    </div>;
};

export const SickPayCurrentInsuranceAdditionalForm = ({offerRequestId, i18n, ...props}) => (
    <div>
        <h3>{l('Bisherige WF (im laufenden Vertrag)')}</h3>
        <FieldArray
            name={dataFieldKey(SickPayDataFields.CATEGORIES_CURRENT)}
            component={Categories}
            i18n={i18n}/>

        <div className="m-t-sm"/>
        <h3>{l('Angaben zu laufenden Fällen')}</h3>
        <FieldArray
            name={dataFieldKey(SickPayDataFields.CASES_ONGOING)}
            component={Cases}
            i18n={i18n}/>
    </div>
);
