import React from 'react';
import {InsuranceType} from '../../insurance-offer/common/insuranceType';
import {Types as InsuranceTypes} from '../../../../config/domain/insurance';
import {CreditInsuranceInformation} from './credit/credit';
import {UVGInsuranceInformation} from './uvg/uvg';
import {DefaultInsuranceInformation} from './default/default';

const InsuranceData = ({offerRequest, ...props}) => {
    return (
        <InsuranceType.Container activeType={offerRequest.insuranceTypeId}>
            <InsuranceType.Type type={InsuranceTypes.CREDIT}>
                <CreditInsuranceInformation offerRequest={offerRequest} {...props}/>
            </InsuranceType.Type>

            <InsuranceType.Type type={InsuranceTypes.UVG}>
                <UVGInsuranceInformation offerRequest={offerRequest} {...props}/>
            </InsuranceType.Type>

            <InsuranceType.Default>
                <DefaultInsuranceInformation offerRequest={offerRequest} {...props}/>
            </InsuranceType.Default>
        </InsuranceType.Container>
    );
};

export default InsuranceData;
