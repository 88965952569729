import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {l} from '../../../../i18n/translator';
import {Fields as UVGInsuranceFields} from '../../../../../config/domain/offerRequest/uvg.js';
import {uvgFieldKey as dataFieldKey} from '../../../insurance-offer/create-request/helper';
import {Field} from '../../../insurance-offer/create-request/component/redux-form-wrapper.jsx';
import {renderCurrencyField} from '../../form/renderer.jsx';
import {AdditionalInformationForm} from '../_common/step/additionalInformation.form.step';

export const UVGInsuranceForm = ({WizardActions, WizardContent, offerRequestId, i18n, ...props}) => {
    return (
        <div>
            <WizardContent>
                <Row>
                    <Col md={12}>
                        <h1 className="m-b-xl">
                            {l('UVG Insurance Data')}
                        </h1>
                    </Col>

                    <Col md={6}>
                        <h4 className="m-b-md">
                            {l('UVG Salaries Job Accidents')}
                        </h4>

                        <Field
                            name={dataFieldKey(UVGInsuranceFields.DATA_SALARY_SUMS_JOB_ACCIDENTS_MEN)}
                            component={renderCurrencyField}
                            label={l('Men')}
                        />

                        <Field
                            name={dataFieldKey(UVGInsuranceFields.DATA_SALARY_SUMS_JOB_ACCIDENTS_WOMEN)}
                            component={renderCurrencyField}
                            label={l('Women')}
                        />
                    </Col>

                    <Col md={6}>
                        <h4 className="m-b-md">
                            {l('UVG Salaries Non Job Accidents')}
                        </h4>

                        <Field
                            name={dataFieldKey(UVGInsuranceFields.DATA_SALARY_SUMS_NON_JOB_ACCIDENTS_MEN)}
                            component={renderCurrencyField}
                            label={l('Men')}
                        />

                        <Field
                            name={dataFieldKey(UVGInsuranceFields.DATA_SALARY_SUMS_NON_JOB_ACCIDENTS_WOMEN)}
                            component={renderCurrencyField}
                            label={l('Women')}
                        />
                    </Col>
                </Row>
                <Row className="m-t-xl">
                    <Col md={12}>
                        <h4>
                            {l('Additional information')}
                        </h4>

                        <AdditionalInformationForm offerRequestId={offerRequestId}
                                                   dataFieldKey={dataFieldKey}
                                                   hideAttachments={props.hideAttachments}
                                                   i18n={i18n}/>
                    </Col>
                </Row>
            </WizardContent>
        </div>
    )
};
