import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {withStyles} from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Select from 'react-select';

import {l} from '../../../../i18n/translator';
import {colorForRank, formatPercentageFloat, parseFloatDefault, round} from '../../analysis';
import {AnalysisBaseForm} from './analysis.form.jsx';
import {styles} from './analysis.form';
import {CriteriaFields, CriteriaTypes, RatingFields} from '../../../../../config/domain/analysis';

class UtilityAnalysisTableComponent extends AnalysisBaseForm {
    constructor(props) {
        super(props);
    }

    getAnalysisType() {
        return CriteriaTypes.UTILITY;
    }

    getInsuranceForId(insuranceId) {
        return this.props.insurances.find((insurance) => insurance.id === insuranceId)
            || {id: insuranceId, name: insuranceId};
    }

    getInsuranceId(rating) {
        return rating[RatingFields.INSURANCE_NAME] || rating[RatingFields.INSURANCE_ID];
    }

    ratingInsuranceEquals(rating, insurance) {
        let ratingInsuranceId = this.getInsuranceId(rating);
        return ratingInsuranceId === insurance.id || ratingInsuranceId === insurance.name;
    }

    handleSelectChange(value) {
        value = value || [];
        if (value.length > 3) {
            value.splice(3);
        }

        const ratings = this.props.ratings;
        const selectedInsuranceIds = this.getSelectedInsuranceIds();
        const newIds = value.map((val) => val.id);

        let newRatings = [...ratings];

        // remove removed insurance ratings
        selectedInsuranceIds.forEach((id, prevIndex) => {
            if (newIds.indexOf(id) < 0) { // was removed
                newRatings = ratings.filter(rating => rating[RatingFields.INSURANCE_ID] !== id);
            }
        });

        // add new insurance
        newIds.forEach((id, index) => {
            if (!selectedInsuranceIds.includes(id)) { // added id
                const rating = {};
                rating[RatingFields.INSURANCE_ID] = id;
                newRatings.push(rating);
            }
        });

        this.onChange(this.props.criteria, newRatings);
    }

    getSelectedInsuranceIds() {
        const selectedInsuranceIds = [];
        (this.props.ratings || []).forEach((rating) => {
            const insuranceId = this.getInsuranceId(rating);
            if (!selectedInsuranceIds.includes(insuranceId)) {
                selectedInsuranceIds.push(insuranceId);
            }
        });
        return selectedInsuranceIds;
    }

    render() {
        const that = this;
        const {classes, criteria, ratings, insurances, disabled = false} = this.props;

        const ratingCalculator = this.createRatingCalculator();
        const selectedInsuranceIds = this.getSelectedInsuranceIds();
        const selectedInsurances = selectedInsuranceIds.map((insuranceId) => this.getInsuranceForId(insuranceId));

        insurances && insurances.sort((a, b) => a.name.localeCompare(b.name));

        const ratingsWithPoints = (ratings || [])
            .filter(rating => rating[RatingFields.CRITERIA_ID]) // only ones that have a criteria!
            .map(rating => ({
                ...rating,
                rating: ratingCalculator.calculateRating(rating[RatingFields.CRITERIA_ID], rating[RatingFields.RATING_DATA]),
                points: ratingCalculator.calculatePoints(rating[RatingFields.CRITERIA_ID], rating[RatingFields.RATING_DATA])
            }));

        return (
            <div>
                {!disabled && insurances ?
                    <div className='m-b-sm'>
                        <Select name="selectInsurances" options={insurances}
                                value={selectedInsurances} closeOnSelect={false} isMulti
                                getOptionLabel={({name}) => name}
                                getOptionValue={({id}) => id}
                                onChange={(value) => this.handleSelectChange(value)}
                                placeholder={l('Select Offered Insurances (max.3)')}/>
                    </div> : null}

                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={3}>{l('Rating Criteria')}</TableCell>
                                <TableCell rowSpan={3} align="right" size="small">{l('Weight')}</TableCell>
                                <TableCell rowSpan={3} align="right" size="small">{'%'}</TableCell>
                                {selectedInsurances.length > 0 ?
                                    <TableCell align="center"
                                               colSpan={selectedInsurances.length * 2}>{l('Insurances')}</TableCell>
                                    : null}
                                {!disabled ? <TableCell rowSpan={3} size="small"/> : null}
                            </TableRow>
                            {selectedInsurances.length > 0 ?
                                <TableRow>
                                    {selectedInsurances.map((insurance) =>
                                        <TableCell key={`insurance${insurance.id}`} colSpan={2}
                                                   align='center'>{insurance.name}</TableCell>
                                    )}
                                </TableRow> : null}

                            {selectedInsurances.length > 0 ?
                                <TableRow>
                                    {selectedInsurances.map((insurance) => [
                                        <TableCell key={`ratingData${insurance.id}`}
                                                   align="center" size="small">{l('Assessment')}</TableCell>,
                                        <TableCell key={`points${insurance.id}`}
                                                   align="center" size="small">{l('Utility rating')}</TableCell>
                                    ])}
                                </TableRow> : null}
                        </TableHead>
                        <TableBody>
                            {(criteria || []).map((c) => (
                                <TableRow key={`row${c[CriteriaFields.ID]}`}>
                                    <TableCell className="text-nowrap">
                                        {this.renderCriteriaDescriptionTooltip(c, !disabled)}
                                        {!disabled
                                            ? that.renderCriteriaSelectCell(c)
                                            : that.renderCriteria(c)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {!disabled
                                            ? that.renderWeightEditCell(c)
                                            : c[CriteriaFields.WEIGHT]}
                                    </TableCell>
                                    <TableCell align="right">
                                        {formatPercentageFloat(ratingCalculator.getWeightPercentage(c[CriteriaFields.ID]))}
                                    </TableCell>
                                    {selectedInsurances.map((insurance) => {
                                        const rating = ratingsWithPoints.find(r =>
                                            that.ratingInsuranceEquals(r, insurance)
                                            && r[RatingFields.CRITERIA_ID] === c[CriteriaFields.ID]) || {};
                                        const insuranceId = insurance.id || insurance.name;
                                        return [
                                            <TableCell key={`ratingData${insuranceId}`} align="center" size="small">{
                                                !disabled
                                                    ? this.renderRatingEditCell({
                                                        criteria: c,
                                                        rating,
                                                        insuranceId: insuranceId
                                                    })
                                                    : this.renderRatingCell({criteria, rating})
                                            }</TableCell>,
                                            <TableCell key={`points${insuranceId}`} align="center" size="small">
                                                {that.renderPoints({
                                                    criteria: c,
                                                    rating: [RatingFields.RATING],
                                                    points: rating[RatingFields.POINTS],
                                                    ratingCalculator
                                                })}
                                            </TableCell>
                                        ];
                                    })}
                                    {!disabled ? <TableCell>{that.renderActions(c)}</TableCell> : null}
                                </TableRow>
                            ))}

                            {!disabled && this.getUnusedCriteria().length > 0 ?
                                <TableRow>
                                    <TableCell component="th" scope="row" className="text-nowrap">
                                        {this.renderCriteriaSelectCell()}
                                    </TableCell>
                                    <TableCell colSpan={selectedInsurances.length * 2 + 2}/>
                                </TableRow>
                                : null}
                        </TableBody>
                        {this.renderFooter({
                            selectedInsurances,
                            criteria: criteria || [],
                            ratings: ratingsWithPoints,
                            ratingCalculator
                        })}
                    </Table>
                </Paper>
            </div>
        );
    }

    renderFooter({selectedInsurances, criteria, ratings, ratingCalculator}) {
        const {disabled = false} = this.props;
        const totalWeight = criteria.reduce((acc, c) => parseFloatDefault(c[CriteriaFields.WEIGHT], 0) + acc, 0);
        const totalPercentage = criteria.reduce((acc, c) => ratingCalculator.getWeightPercentage(c[CriteriaFields.ID], 0) + acc, 0);

        const insuranceTotalPoints = [];
        selectedInsurances.forEach((insurance) => {
            let total = 0;
            const insuranceRatings = ratings.filter(rating => this.ratingInsuranceEquals(rating, insurance));
            insuranceRatings.forEach(rating => total += rating[RatingFields.POINTS] || 0);
            insuranceTotalPoints.push({insuranceId: insurance.id, points: total});
        });
        insuranceTotalPoints.sort((a, b) => b.points - a.points);

        return <TableFooter>
            <TableRow>
                <TableCell>{l('Utility sum')}</TableCell>
                <TableCell align="right">{totalWeight}</TableCell>
                <TableCell align="right">{formatPercentageFloat(totalPercentage)}</TableCell>
                {selectedInsurances.map((insurance) => {
                        const total = insuranceTotalPoints.find((item) => item.insuranceId === insurance.id).points;
                        return [
                            <TableCell key={`sum${insurance.id}`} size="small"/>,
                            <TableCell key={`sumPoints${insurance.id}`} align="center" size="small">
                                <strong>= {round(total, 2)}</strong>
                            </TableCell>,
                        ];
                    }
                )}
                {!disabled ? <TableCell rowSpan={2}/> /* actions */ : null}
            </TableRow>
            {selectedInsurances.length >  0 ?
                <TableRow>
                    <TableCell>{l('Rank')}</TableCell>
                    <TableCell/>
                    {selectedInsurances.map((insurance) => {
                            const rank = insuranceTotalPoints.findIndex((item) => item.insuranceId === insurance.id) + 1;
                            const text = l('Rank %num%').replace('%num%', rank);
                            const color = colorForRank(rank);
                            return [
                                <TableCell key={`rankSpacing${insurance.id}`} size="small"/>,
                                <TableCell key={`rank${insurance.id}`} align="center" size="small">
                                    <strong><span style={{color: color}}>{text}</span></strong>
                                </TableCell>,
                            ];
                        }
                    )}
                </TableRow> : null}
        </TableFooter>;
    }
}

export const UtilityAnalysisForm = injectIntl(withStyles(styles)(UtilityAnalysisTableComponent));

UtilityAnalysisForm.propTypes = {
    availableCriteria: PropTypes.arrayOf(PropTypes.object),
    criteria: PropTypes.arrayOf(PropTypes.object),
    ratings: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    insurances: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
};
