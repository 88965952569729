import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Button} from '@material-ui/core';
import {l} from '../../../../i18n/translator';
import {sickPayFieldKey as dataFieldKey} from '../../../insurance-offer/create-request/helper';
import {Field} from '../../../insurance-offer/create-request/component/redux-form-wrapper.jsx';
import {AdditionalInformationForm} from '../_common/step/additionalInformation.form.step';
import {FieldArray} from '../../../insurance-offer/create-request/component/redux-form-wrapper';
import {CategoryFields, Fields as SickPayDataFields} from '../../../../../config/domain/offerRequest/sick_pay';
import {
    renderCheckbox,
    renderCurrencyField,
    renderNumberField,
    renderSelectField,
    renderTextField
} from '../../form/renderer';
import {LocalCurrencyWithSymbol, LocalNumber} from '../../i18n/number';

export const Categories = ({fields}) => (
    <div className="m-b-lg">
        <h3>{l('Kategorien')}</h3>

        {fields.map((field, index) => {
            return (
                <div key={index}>
                    <Row>
                        <Col sm={4}>
                            <Field
                                name={`${field}.${CategoryFields.NAME}`}
                                label={l('Name der Kategorie #:nr').replace(':nr', index + 1)}
                                component={renderTextField}
                                fullWidth
                            />
                        </Col>
                        <Col sm={1} className="p-t">
                            <Button onClick={() => fields.remove(index)}>
                                <i className="fa fa-trash"/>
                            </Button>
                        </Col>
                    </Row>
                </div>
            );
        })}

        <Button disabled={fields.length >= 4} onClick={() => fields.push({})}>
            <i className="fa fa-plus m-r-xs"/>{l('Add')}
        </Button>
    </div>
);

export const CategoryExtendedGroupRow = ({fields, fieldName, name, hint}) => {
    return <Row>
        <Col sm={8} className="p-t-sm">{name}{hint ? <sup>{hint}</sup> : null}</Col>
        {fields.map((field, index) =>
            <Col sm={1} key={index}>
                <Field name={`${field}.${fieldName}`} component={renderCheckbox}/>
            </Col>
        )}
    </Row>;
};
export const CategoryExtendedGroup = ({fields}) => {
    return (
        <div className="m-b-lg">
            <h3>{l('Erweiterter Personenkreis')}</h3>

            <Row style={{fontWeight: 'bold'}}>
                <Col sm={8}/>
                {fields.map((field, index) => <Col sm={1} key={index}>#{index + 1}</Col>)}
            </Row>

            <CategoryExtendedGroupRow fields={fields} fieldName={'heimarbeitende'} name={l('Heimarbeitende')}/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'teilzeitangestellte'}
                                      name={l('Teilzeitangestellte mit einem durchschnittlichen Beschäftigungsgrad von weniger als 8 Std./Woche')}/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'aushilfen'}
                                      name={l('Kurzfristige Aushilfen mit einem bis zu 3 Monaten befristeten Arbeitsvertrag')}/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'ahvalter'}
                                      name={l('Personen im AHV-Alter bis max. 70. Altersjahr')}/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'stundenloehner'}
                                      name={l('Stundenlöhner ohne fix bestimmtes, regelmässiges Pensum von mindestens 8 Std./Woche')}/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'lernende'} name={l('Lernende')}/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'arbeitnehmer'}
                                      name={l('Arbeitnehmer, welche aufgrund der bilateralen Abkomme nicht mehr schweiz. Sozialversicherungen unterstellt sind')}/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'betriebsinhaber'}
                                      name={l('Betriebsinhaber, Teilhaber')} hint='1'/>
            <CategoryExtendedGroupRow fields={fields} fieldName={'familienmitglieder'}
                                      name={l('Familienmitglieder des Inhabers/Teilhabers, welche nicht in der Lohnbuchhaltung aufgeführt sind')}
                                      hint='1'/>

            <br/>
            <sup>1</sup>{l('Nur bei Personengesellschaften. Bei AG und GmbH mitarbeitende Aktionäre bzw. Gesellschafter fallen unter die Kategorie "Personal"')}
        </div>
    );
};

export const CategoryPeople = ({fields}) => {
    const allValues = fields.getAll() || [];
    const totalCountMen = allValues.reduce((sum, current) => sum + (parseInt(current[CategoryFields.PEOPLE_COUNT_MEN]) || 0), 0);
    const totalCountWomen = allValues.reduce((sum, current) => sum + (parseInt(current[CategoryFields.PEOPLE_COUNT_WOMEN]) || 0), 0);
    const totalSalaryMen = allValues.reduce((sum, current) => sum + (parseInt(current[CategoryFields.PEOPLE_SALARY_MEN]) || 0), 0);
    const totalSalaryWomen = allValues.reduce((sum, current) => sum + (parseInt(current[CategoryFields.PEOPLE_SALARY_WOMEN]) || 0), 0);
    return (
        <div className="m-b-lg">
            <h3>{l('Lohnsummen')}</h3>

            <Row style={{fontWeight: 'bold'}}>
                <Col sm={2}/>
                <Col sm={4} className="text-center">{l('Anzahl Personen')}</Col>
                <Col sm={6} className="text-center">{l('Lohnsummen / Jahr')}</Col>
            </Row>
            <Row style={{fontWeight: 'bold'}}>
                <Col sm={2}/>
                <Col sm={2} className="text-center">{l('Men')}</Col>
                <Col sm={2} className="text-center">{l('Women')}</Col>
                <Col sm={3} className="text-center">{l('Men')}</Col>
                <Col sm={3} className="text-center">{l('Women')}</Col>
            </Row>

            {fields.map((field, index) => {
                return (
                    <div key={index}>
                        <Row>
                            <Col sm={2} className="p-t-xs">{`#${index + 1} - ${allValues[index].name || ''}`}</Col>
                            <Col sm={2}>
                                <Field
                                    name={`${field}.${CategoryFields.PEOPLE_COUNT_MEN}`}
                                    component={renderNumberField}
                                    fullWidth
                                />
                            </Col>
                            <Col sm={2}>
                                <Field
                                    name={`${field}.${CategoryFields.PEOPLE_COUNT_WOMEN}`}
                                    component={renderNumberField}
                                    fullWidth
                                />
                            </Col>
                            <Col sm={3}>
                                <Field
                                    name={`${field}.${CategoryFields.PEOPLE_SALARY_MEN}`}
                                    component={renderCurrencyField}
                                    fullWidth
                                />
                            </Col>
                            <Col sm={3}>
                                <Field
                                    name={`${field}.${CategoryFields.PEOPLE_SALARY_WOMEN}`}
                                    component={renderCurrencyField}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </div>
                );
            })}

            <div style={{borderTop: '1px solid black', marginTop: '10px', marginBottom: '5px'}}/>

            <Row style={{fontWeight: 'bold'}}>
                <Col sm={2}>{l('Total')}</Col>
                <Col sm={2}><LocalNumber value={totalCountMen}/></Col>
                <Col sm={2}><LocalNumber value={totalCountWomen}/></Col>
                <Col sm={3}><LocalCurrencyWithSymbol value={totalSalaryMen}/></Col>
                <Col sm={3}><LocalCurrencyWithSymbol value={totalSalaryWomen}/></Col>
            </Row>

            <div className="m-t-md"/>
            <Field
                name={SickPayDataFields.MAX_INSURED_SALARY}
                label={l('Max. versicherter Lohn:')}
                component={renderCurrencyField}
                defaultValue={300000}
            />
        </div>
    );
};

const CategoryExtendedSalaryDefinitionRow = ({fields, fieldName, name, ahvPflichtig, defaultChecked}) => (
    <Row>
        <Col sm={4} className="p-t-sm">{name}</Col>
        <Col sm={3} className="text-center p-t-sm">{ahvPflichtig ? l('ja') : l('nein')}</Col>
        {fields.map((field) => (
            <Col sm={1} key={`${field}.${fieldName}`}>
                <Field name={`${field}.${fieldName}`} component={renderCheckbox} defaultValue={!!defaultChecked}
                       fullWidth/>
            </Col>
        ))}
    </Row>
);

export const CategoryExtendedSalaryDefinition = ({fields}) => (
    <div className="m-b-lg">
        <h3>{l('Erweiterte Lohndefinition')}</h3>

        <Row style={{fontWeight: 'bold'}}>
            <Col sm={7}/>
            <Col sm={4} className="text-center">{l('versichert')}</Col>
        </Row>
        <Row style={{fontWeight: 'bold'}}>
            <Col sm={4}>{l('Lohnarten')}</Col>
            <Col sm={3} className="text-center">{l('AHV-pflichtig')}</Col>
            {fields.map((field, index) => (
                <Col sm={1} key={index}>{`#${index + 1}`}</Col>
            ))}
        </Row>
        <CategoryExtendedSalaryDefinitionRow name={l('AHV-Bruttolohn')} ahvPflichtig={true} fields={fields}
                                             fieldName={CategoryFields.EXTENDED_SALARY_DEFINITION_AHV_BRUTTOLOHN}
                                             defaultChecked={true}/>
        <CategoryExtendedSalaryDefinitionRow name={l('13. Monatslohn')} ahvPflichtig={true} fields={fields}
                                             fieldName={CategoryFields.EXTENDED_SALARY_DEFINITION_13_MONTH_SALARY}
                                             defaultChecked={true}/>
        <CategoryExtendedSalaryDefinitionRow name={l('Gratification')} ahvPflichtig={true} fields={fields}
                                             fieldName={CategoryFields.EXTENDED_SALARY_DEFINITION_GRATIFICATION}
                                             defaultChecked={true}/>
        <CategoryExtendedSalaryDefinitionRow name={l('Kinderzulagen gemäss FamZu-Gesetz')} ahvPflichtig={false}
                                             fields={fields}
                                             fieldName={CategoryFields.EXTENDED_SALARY_DEFINITION_CHILD_ALLOWANCE}
                                             defaultChecked={true}/>
        <CategoryExtendedSalaryDefinitionRow name={l('Sozialzulagen')} ahvPflichtig={false} fields={fields}
                                             fieldName={CategoryFields.EXTENDED_SALARY_DEFINITION_SOCIAL_WELFARE_SUPPLEMENT}/>
    </div>
);

const CategoryBenefitsRow = ({fields, fieldName, name, children}) => (
    <Row>
        <Col sm={4} className="p-t-xs">{name}</Col>
        {fields.map((field, index) => {
            const a = React.Children.map(children, (child, index) => {
                return React.cloneElement(child, {
                    index,
                    name: `${field}.${fieldName}.variant1`
                });
            });
            // const b = React.Children.map(children, (child, index) => {
            //     return React.cloneElement(child, {
            //         index,
            //         name: `${field}.${fieldName}.variant2`
            //     });
            // });
            return (
                <div key={index + 'a'}>
                    <Col sm={2}>{a}</Col>
                    {/*<Col sm={1} key={index+'b'}>{b}</Col>*/}
                </div>
            );
        })}
    </Row>
);

export const CategoryBenefits = ({fields}) => (
    <div className="m-b-lg">
        <h3>{l('Leistungen')}</h3>

        <Row style={{fontWeight: 'bold'}}>
            <Col sm={4}/>
            {fields.map((field, index) => (
                <Col sm={2} className="text-center" key={index}>{`#${index + 1}`}</Col>
            ))}
        </Row>
        {/*<Row>*/}
        {/*    <Col sm={4}></Col>*/}
        {/*    {fields.map((field, index) => (*/}
        {/*        <>*/}
        {/*            <Col sm={1} className="text-center" key={index + 'a'}>{l('Variante 1')}</Col>*/}
        {/*            <Col sm={1} className="text-center" key={index + 'b'}>{l('Variante 2')}</Col>*/}
        {/*        </>*/}
        {/*    ))}*/}
        {/*</Row>*/}
        <CategoryBenefitsRow fieldName={'benefitsA'} fields={fields} name={l('Leistungen bei Erwerbsunfähigkeit')}>
            <Field component={renderSelectField} defaultValue={'sickness'}
                   options={[
                       {label: l('Krankheit'), value: 'sickness'},
                       {label: l('Krankheit & Unfall'), value: 'sickness_and_accident'}
                   ]} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsB'} fields={fields} name={l('Leistungen in %')}>
            <Field component={renderNumberField} defaultValue={80} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsC1'} fields={fields} name={l('Durchschnittsalter Frauen')}>
            <Field component={renderNumberField} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsC2'} fields={fields} name={l('Durchschnittsalter Männer')}>
            <Field component={renderNumberField} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsD'} fields={fields} name={l('Wartefrist')}>
            <Field component={renderSelectField} options={[
                {label: l('pro Fall'), value: 'perCase'},
                {label: l('pro Arbeitsjahr'), value: 'perYear'}
            ]} defaultValue={'perCase'} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsE'} fields={fields} name={l('gewünschte Wartefrist in Tagen')}>
            <Field component={renderSelectField} options={[0, 3, 7, 14, 30, 60, 90, 180].map((days) => ({
                label: `${days} ` + l('Tage'), value: days
            }))} defaultValue={30} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsF'} fields={fields} name={l('Entschädigung')}>
            <Field component={renderSelectField} options={[
                {label: l('Schadenversicherung'), value: 'Schadenversicherung'},
                {label: l('Summenversicherung'), value: 'Summenversicherung'}
            ]} defaultValue={'Schadenversicherung'} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsG'} fields={fields} name={l('Deckungsart')}>
            <Field component={renderSelectField} options={[
                {label: l('BVG-koordiniert'), value: 'bvg-koordiniert'},
                {label: l('Volldeckung'), value: 'fullcoverage'}
            ]} defaultValue={'bvg-koordiniert'} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsH'} fields={fields} name={l('Leistungsdauer in Tagen ./. WF')}>
            <Field component={renderSelectField} options={[
                {label: l('730 pro Fall'), value: '730percase'},
                {label: l('720 in 900'), value: '720in900'}
            ]} defaultValue={'730percase'} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsI'} fields={fields} name={l('Leistungen bei Erwerbsunfähigkeit ab %')}>
            <Field component={renderNumberField} defaultValue={25} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsJ'} fields={fields} name={l('Geburtengeld in Ergänzung zur EO')}>
            <Field component={renderCheckbox} defaultValue={true} label={l('versichert')}/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsJ1'} fields={fields} name={l('Anzahl Tage ohne Anrechnung WF')}>
            <Field component={renderNumberField} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsJ2'} fields={fields} name={l('Lohnmaximum gem. EO oder AHV-Lohn')}>
            <Field component={renderCurrencyField} fullWidth/>
        </CategoryBenefitsRow>
        <CategoryBenefitsRow fieldName={'benefitsK'} fields={fields} name={l('Gesamtarbeitsvertrag unterstellt')}>
            <Field component={renderCheckbox} label={l('GAV unterstellt')}/>
        </CategoryBenefitsRow>
    </div>
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SickPayInsuranceForm = ({WizardActions, WizardContent, offerRequestId, i18n, ...props}) => {
    return (
        <div>
            <WizardContent>
                <FieldArray
                    name={dataFieldKey(SickPayDataFields.CATEGORIES)}
                    component={Categories}
                    i18n={i18n}
                />

                <FieldArray
                    name={dataFieldKey(SickPayDataFields.CATEGORIES)}
                    component={CategoryExtendedGroup}
                    i18n={i18n}
                />

                <FieldArray
                    name={dataFieldKey(SickPayDataFields.CATEGORIES)}
                    component={CategoryPeople}
                    i18n={i18n}
                />

                <FieldArray
                    name={dataFieldKey(SickPayDataFields.CATEGORIES)}
                    component={CategoryExtendedSalaryDefinition}
                    i18n={i18n}
                />

                <FieldArray
                    name={dataFieldKey(SickPayDataFields.CATEGORIES)}
                    component={CategoryBenefits}
                    i18n={i18n}
                />

                <Row className="m-t-xl">
                    <Col md={12}>
                        <h3>{l('Additional information')}</h3>

                        <AdditionalInformationForm offerRequestId={offerRequestId}
                                                   dataFieldKey={dataFieldKey}
                                                   hideAttachments={props.hideAttachments}
                                                   i18n={i18n}/>
                    </Col>
                </Row>
            </WizardContent>
        </div>
    )
};
