import {reducer as formReducer} from 'redux-form';
import { connectRouter } from 'connected-react-router'

import dashboardReducer from './ui/dashboard/reducer';
import createOfferRequestReducer from './ui/insurance-offer/create-request/reducer';
import createFinishedOfferRequestReducer from './ui/insurance-offer/create-request-finished/reducer';
import createOfferReducer from './ui/insurance-offer/create-offer/reducer';
import offerRequestListReducer from './ui/insurance-offer/request-list/reducer';
import offerRequestDetailsReducer from './ui/insurance-offer/request-details/reducer';
import offerDetailsReducer from './ui/insurance-offer/offer-details/reducer';
import updateOfferRequestReducer from './ui/insurance-offer/update-request/reducer';
import administrationReducer from './ui/administration/reducer';
import loginReducer from './ui/auth/login/reducer';
import forgotPasswordReducer from './ui/auth/forgot-password/reducer';
import resetPasswordReducer from './ui/auth/reset-password/reducer';
import registerReducer from './ui/auth/register/reducer';
import verifyUserReducer from './ui/auth/verify-user/reducer';
import userListPageReducer from './ui/users/reducer/list';
import userEditPageReducer from './ui/users/reducer/edit';
import customerListPageReducer from './ui/customers/customers-list/reducer';
import customerUsersReducer from './ui/customers/customers-users/reducer';
import analysisAutoRatingsListReducer from './ui/analysis/auto-ratings-list/reducer';
import analysisAutoRatingEditReducer from './ui/analysis/auto-ratings-edit/reducer';
import analysisCriteriaListPageReducer from './ui/analysis/criteria-list/reducer';
import analysisCriteriaEditPageReducer from './ui/analysis/criteria-edit/reducer';
import analysisTemplatesListPageReducer from './ui/analysis/templates-list/reducer';
import analysisTemplatesEditPageReducer from './ui/analysis/templates-edit/reducer';
import profilePageReducer from './ui/profile/reducer';
import authReducer from './auth/reducer';
import i18nReducer from './i18n/reducer';
import notificationsReducer from './notification/reducer';
import loaderStateReducer from './ui/common/misc/loader-state/reducer';
import tabnavReducer from './ui/common/page/tabnav/reducer';

const INITIAL_STATE = {
    i18n: undefined,
    auth: undefined,
    notifications: undefined,
    loaders: undefined,
    page: {
        dashboard: undefined,
        createOfferRequest: undefined,
        createOffer: undefined,
        offerRequestList: undefined,
        offerRequestDetails: undefined,
        updateOfferRequest: undefined,
        administration: undefined,
        login: undefined,
        forgotPassword: undefined,
        resetPassword: undefined,
        register: undefined,
        verifyUser: undefined,
        userList: undefined,
        userEdit: undefined
    }
};

/**
 * Main App reducer
 *
 * @param state
 * @param action
 *
 * @return {object}
 */
let appReducer = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        i18n: i18nReducer(state.i18n, action),
        auth: authReducer(state.auth, action),
        notifications: notificationsReducer(state.notifications, action),
        loaders: loaderStateReducer(state.loaders, action),
        form: formReducer(state.form, action),
        page: {
            dashboard: dashboardReducer(state.page.dashboard, action),
            createOfferRequest: createOfferRequestReducer(state.page.createOfferRequest, action),
            createFinishedOfferRequest: createFinishedOfferRequestReducer(state.page.createFinishedOfferRequest, action),
            createOffer: createOfferReducer(state.page.createOffer, action),
            offerRequestList: offerRequestListReducer(state.page.offerRequestList, action),
            offerRequestDetails: offerRequestDetailsReducer(state.page.offerRequestDetails, action),
            offerDetails: offerDetailsReducer(state.page.offerDetails, action),
            updateOfferRequest: updateOfferRequestReducer(state.page.updateOfferRequest, action),
            administration: administrationReducer(state.page.administration, action),
            login: loginReducer(state.page.login, action),
            forgotPassword: forgotPasswordReducer(state.page.forgotPassword, action),
            resetPassword: resetPasswordReducer(state.page.resetPassword, action),
            register: registerReducer(state.page.register, action),
            verifyUser: verifyUserReducer(state.page.verifyUser, action),
            userList: userListPageReducer(state.page.userList, action),
            userEdit: userEditPageReducer(state.page.userEdit, action),
            profile: profilePageReducer(state.page.profile, action),
            customersList: customerListPageReducer(state.page.customersList, action),
            customerUsers: customerUsersReducer(state.page.customerUsers, action),
            analysisAutoRatingsList: analysisAutoRatingsListReducer(state.page.analysisAutoRatingsList, action),
            analysisAutoRatingEdit: analysisAutoRatingEditReducer(state.page.analysisAutoRatingEdit, action),
            analysisCriteriaList: analysisCriteriaListPageReducer(state.page.analysisCriteriaList, action),
            analysisCriteriaEdit: analysisCriteriaEditPageReducer(state.page.analysisCriteriaEdit, action),
            analysisTemplatesList: analysisTemplatesListPageReducer(state.page.analysisTemplatesList, action),
            analysisTemplatesEdit: analysisTemplatesEditPageReducer(state.page.analysisTemplatesEdit, action),
        },
        tabnav: tabnavReducer(state.tabnav, action)
    };
};

const createRootReducer = history => combineReducersWithRoot(appReducer, {
    router: connectRouter(history),
});

export default createRootReducer;

function combineReducersWithRoot(rootReducer, reducers) {
    return (state, action) => {
        // Ensure the root state object is a new object; otherwise
        // React may not re-render.
        let newState = {...rootReducer(state, action)};
        Object.keys(reducers).forEach(domain => {
            let obj = state ? state[domain] : undefined;
            newState[domain] = reducers[domain](obj, action);
        });
        return newState;
    };
}
