import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';

import {l} from '../../../i18n/translator';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx'
import * as Ibox from '../../ui-components/ibox.jsx';
import {initTemplateEdit, onInsuranceTypeChanged, saveTemplate} from './action.js';
import URI from '../../../../config/uri';
import {CriteriaTypes, TemplateFields} from '../../../../config/domain/analysis';
import {renderCheckbox, renderSelectField, renderTextField} from '../../common/form/renderer';
import {Field} from '../../insurance-offer/create-request/component/redux-form-wrapper';
import {AnalysisFormFields} from '../../common/form/analysis/field/analysis.field';

export class TemplatesEditPage extends React.Component {

    componentWillMount() {
        let {match: {params = {}}, actions} = this.props;
        let templateId = this.isEdit() ? params.id : null;

        actions.init(templateId);
    }

    isEdit() {
        return this.props.match.path === URI.ANALYSIS_TEMPLATES_EDIT;
    }

    render() {
        const {insuranceTypeId, handleSubmit, submitting, pageState, actions} = this.props;
        const {data} = pageState;
        const template = data || {};

        const insuranceTypes = [...(data.insuranceTypes || [])];
        const insuranceTypeOptions = insuranceTypes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(insuranceType => ({label: insuranceType.name, value: insuranceType.id}));

        return (
            <InsurTecLayout>
                <BreadCrumbs items={[{label: l('Templates'), uri: URI.ANALYSIS_TEMPLATES}]}
                             title={this.isEdit() ? template.name || l('Template') : l('New template')}
                             icon="bar-chart"/>

                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content">
                        <Row>
                            <Col md={12}>
                                <Ibox.Container>
                                    <Ibox.Title>{l('Template')}</Ibox.Title>

                                    <Ibox.Content className="p-sm">
                                        <form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md={6}>
                                                    <Field name={TemplateFields.NAME} label={l('Name')}
                                                           component={renderTextField} disabled={submitting} fullWidth/>
                                                    <Field name={TemplateFields.INSURANCE_TYPE_ID}
                                                           label={l('Insurance Type')}
                                                           component={renderSelectField} options={insuranceTypeOptions}
                                                           onChange={value => actions.loadCriteria(value)}
                                                           disabled={submitting} fullWidth/>
                                                    <Field name={TemplateFields.INDUSTRY_CLASSIFICATION_CODE}
                                                           label={l('Industry Classification Code (NOGA)')}
                                                           component={renderTextField} disabled={submitting} fullWidth/>

                                                    <Field name={TemplateFields.ACTIVE} defaultValue={true}
                                                           label={l('Active')}
                                                           component={renderCheckbox} disabled={submitting}/>
                                                </Col>
                                            </Row>
                                            {insuranceTypeId ?
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="m-t-lg"/>
                                                        <h3>{l('Premium assessment')}</h3>
                                                        <AnalysisFormFields criteriaType={CriteriaTypes.PREMIUM}
                                                                            availableCriteria={data.criteria}
                                                                            targetRatings={true}
                                                                            criteriaFieldName={'criteria.premium'}
                                                        />

                                                        <div className="m-t-lg"/>
                                                        <h3>{l('Coverage assessment')}</h3>
                                                        <AnalysisFormFields criteriaType={CriteriaTypes.COVERAGE}
                                                                            availableCriteria={data.criteria}
                                                                            targetRatings={true}
                                                                            criteriaFieldName={'criteria.coverage'}/>
                                                    </Col>
                                                </Row> : null}

                                            <div className="m-t-md"/>

                                            <Button variant="contained" className="m-l-lg" color="primary"
                                                    disabled={submitting} onClick={handleSubmit}>
                                                {l('Save')}
                                            </Button>
                                        </form>
                                    </Ibox.Content>
                                </Ibox.Container>
                            </Col>
                        </Row>
                    </div>
                </LoadedPage>
            </InsurTecLayout>
        )
    }
}

export const FORM_NAME = 'template-edit';
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        insuranceTypeId: selector(state, TemplateFields.INSURANCE_TYPE_ID),
        pageState: {...state.page.analysisTemplatesEdit},
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: (templateId) => dispatch(initTemplateEdit({templateId})),
            loadCriteria: (insuranceTypeId) => dispatch(onInsuranceTypeChanged(insuranceTypeId)),
        },
        dispatch
    }
}

export default reduxForm({
    form: FORM_NAME,
    onSubmit: (data, dispatch) => saveTemplate(data, dispatch)
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplatesEditPage));
