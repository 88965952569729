import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {l} from '../../../i18n/translator';
import {redirectTo} from '../../../router/action.js';
import URI from '../../../../config/uri';
import {BreadCrumbs, InsurTecLayout} from '../../layout/app-layout.jsx';
import LoadedPage from '../../common/page/loaded-page/component.jsx'
import * as Ibox from '../../ui-components/ibox.jsx';
import {initCriteriaList} from './action.js';
import {CriteriaFields} from '../../../../config/domain/analysis';

export class AnalysisCriteriaListPage extends React.Component {

    componentWillMount() {
        this.props.actions.init();
        this.setState({search: ''});
    }

    render() {
        const {dispatch, auth, pageState} = this.props;
        const {data} = pageState;

        const insuranceTypes = [...(data.insuranceTypes || [])];
        const insuranceTypeNameById = {};
        insuranceTypes.forEach((insuranceType) => insuranceTypeNameById[insuranceType.id] = insuranceType.name);

        let criteria = [...(data.criteria || [])];

        const filter = (this.state.search || '').trim().toLowerCase().split(' ');
        if (filter.length > 0) {
            criteria = criteria.filter(c => {
                const combinedString = c[CriteriaFields.NAME].toLowerCase() + ' '
                    + l(`analysis_criteria_type_${c.type}`).toLowerCase()
                    + (c[CriteriaFields.INSURANCE_TYPE_IDS] || []).map(insuranceTypeId => insuranceTypeNameById[insuranceTypeId]).join(' ').toLowerCase();
                return filter.every(item => combinedString.includes(item));
            });
        }

        criteria.sort((a, b) => a.name.localeCompare(b.name));

        return (
            <InsurTecLayout>
                <BreadCrumbs title={l('Criteria')} icon="bar-chart"/>

                <LoadedPage pageState={pageState}>
                    <div className="wrapper wrapper-content">
                        <Row>
                            <Col md={12}>
                                <Ibox.Container>
                                    <Ibox.Title>{l('Criteria')}</Ibox.Title>

                                    <Ibox.Content className="p-sm">

                                        <Button variant="contained" color="primary"
                                                onClick={() => dispatch(redirectTo(URI.ANALYSIS_CRITERIA_CREATE))}>
                                            <i className="fa fa-plus m-r-xs"/> {l('Create criteria')}
                                        </Button>
                                        <span className="m-r-md"/>
                                        <Button variant="contained" color="default"
                                                onClick={() => dispatch(redirectTo(URI.ANALYSIS_TEMPLATES))}>
                                            <i className="fa fa-eye m-r-xs"/> {l('Show templates')}
                                        </Button>
                                        <div className="m-b-md"/>

                                        <TextField value={this.state.search}
                                                   label={l('Filter')}
                                                   onChange={e => this.setState({search: e.target.value})}
                                                   fullWidth/>
                                        <div className="m-b-lg"/>

                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th>{l('Name')}</th>
                                                <th className="col-md-2">{l('Type')}</th>
                                                <th>{l('Insurance types')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {criteria.map((c, index) => {
                                                const isOwn = !c[CriteriaFields.IS_SYSTEM] && !c[CriteriaFields.IS_LICENSED];
                                                return (
                                                    <tr key={c[CriteriaFields.ID]}
                                                        className={!c.active ? "font-italic gray" : null}
                                                        style={isOwn ? {cursor: 'pointer'} : null}
                                                        onClick={isOwn ? () => dispatch(redirectTo(URI.ANALYSIS_CRITERIA_EDIT.replace(':id', c[CriteriaFields.ID]))) : null}>
                                                        <td>{c[CriteriaFields.NAME]} {this.createCriteriaLabels(c)}</td>
                                                        <td>{l(`analysis_criteria_type_${c.type}`)}</td>
                                                        <td>{this.getInsuranceTypesColumnValue(c, insuranceTypeNameById)}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </Ibox.Content>
                                </Ibox.Container>
                            </Col>
                        </Row>
                    </div>
                </LoadedPage>
            </InsurTecLayout>
        )
    }

    createCriteriaLabels(c) {
        return (<>
            {!c.active ? (<span className="label m-l-xs">inactive</span>) : null}
            {c.isSystem ? (<span className="label label-warning m-l-xs">system</span>) : null}
            {c.isLicensed ? (<span className="label label-inverse m-l-xs">licensed</span>) : null}
        </>);
    }

    getInsuranceTypesColumnValue(c, insuranceTypeNameById) {
        const insuranceTypeIds = c[CriteriaFields.INSURANCE_TYPE_IDS];
        if (insuranceTypeIds.length > 3) {
            return insuranceTypeIds.slice(0, 3).map((insuranceTypeId) => insuranceTypeNameById[insuranceTypeId]).join(', ') + ` +${insuranceTypeIds.length - 3}`;
        }
        return insuranceTypeIds.map((insuranceTypeId) => insuranceTypeNameById[insuranceTypeId]).join(', ');
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.i18n,
        auth: state.auth,
        user: state.auth.user,
        pageState: {...state.page.analysisCriteriaList}
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            init: () => dispatch(initCriteriaList())
        },
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisCriteriaListPage);
