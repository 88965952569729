import {Fields as PersonFields} from './person';

export const States = {
    CREATED: 'created',
    PUBLISHED: 'bidding',
    EXPIRED: 'finished',
    PENDING_MANDATE: 'pending_mandate',
    PENDING_INSURANCE: 'pending_insurance',
    PENDING_VALIDATION: 'pending_validation',
    WITHDRAWN: 'withdrawn'
};

export const OfferRoundTypes = {
    AUCTION: 'auction',
    FIRST_SHOT: 'firstShot',
};

export const Fields = {
    ID: 'id',
    COMPANY_ID: 'companyId',
    CUSTOMER_NAME: 'customerName',
    CUSTOMER_INDUSTRY_CLASSIFICATION_CODE: 'customerIndustryClassificationCode',
    INSURANCE_TYPE_ID: 'insuranceTypeId',
    STATE: 'state',

    REQUEST_FROM_INSURANCE_IDS: 'requestFromInsuranceIds',
    REQUEST_FROM_INSURANCE_NAMES: 'requestFromInsuranceNames',

    CONTACT: 'contact',
    CONTACT_ID: `contact.${PersonFields.ID}`,
    CONTACT_FIRST_NAME: `contact.${PersonFields.FIRST_NAME}`,
    CONTACT_LAST_NAME: `contact.${PersonFields.LAST_NAME}`,
    CONTACT_FUNCTION: `contact.${PersonFields.FUNCTION}`,
    CONTACT_ADDRESS_LINE1: `contact.${PersonFields.ADDRESS_LINE1}`,
    CONTACT_ADDRESS_LINE2: `contact.${PersonFields.ADDRESS_LINE2}`,
    CONTACT_ZIP: `contact.${PersonFields.ZIP}`,
    CONTACT_TOWN: `contact.${PersonFields.TOWN}`,
    CONTACT_COUNTRY: `contact.${PersonFields.COUNTRY}`,
    CONTACT_TELEPHONE: `contact.${PersonFields.TELEPHONE}`,
    CONTACT_MOBILE: `contact.${PersonFields.MOBILE}`,
    CONTACT_EMAIL: `contact.${PersonFields.EMAIL}`,

    BUSINESS_IDENTIFICATION_NUMBER: 'businessIdentificationNumber',
    JOINTLY_INSURED_COMPANIES: 'jointlyInsuredCompanies',

    CURRENT_INSURANCE: 'currentInsurance',
    CURRENT_INSURANCE_HARM_HISTORY_FILES: 'files.harmHistory',
    PREVIOUS_INSURANCE_ID: 'previousInsuranceId',
    PREVIOUS_INSURANCE_POLICY_NUMBER: 'previousInsurancePolicyNumber',

    DESIRED_INSURANCE_START: 'desiredInsuranceStart',
    INSURANCE_PERIOD_YEARS: 'insurancePeriodYears',
    INSURANCE_YEARLY_RIGHT_OF_TERMINATION: 'insuranceYearlyRightOfTermination',
    INSURANCE_BILLING_FREQUENCY_MONTHS: 'insuranceBillingFrequencyMonths',
    TARGET_INSURANCE_PREMIUM: 'targetInsurancePremium',

    BROKER_ID: 'brokerId',
    ADVISORY_MANDATE_FILE: 'files.advisoryMandate',
    SUMMARY_FILE: 'files.summary',
    ATTACHMENTS: 'files.attachments',

    DATA: 'data',

    OFFER_ROUND_TYPE: 'offerRoundType',
    OFFER_ROUND_END_DATE: 'offerRoundEndDate',
    OFFER_ROUND_DATA: 'offerRoundData',

    PREMIUM_ANALYSIS_CRITERIA: 'premiumAnalysisCriteria',
    COVERAGE_ANALYSIS_CRITERIA: 'coverageAnalysisCriteria',
    UTILITY_ANALYSIS_CRITERIA: 'utilityAnalysisCriteria',
    UTILITY_ANALYSIS_RATINGS: 'utilityAnalysisRatings',

    ACTIONS: 'actions',

    OFFER_PREMIUM_GROSS_MIN: 'offerPremiumGrossMin',
};

export const CurrentInsuranceFields = {
    INSURANCE_ID: 'insuranceId',
    INSURANCE_NAME: 'insuranceName',
    PREMIUM_NET: 'premiumNet',
    PREMIUM_GROSS: 'premiumGross',
    POLICY_NUMBER: 'policyNumber',
    ADJUSTMENTS_ONGOING: 'adjustmentsOngoing',
    NO_HARM_HISTORY: 'noHarmHistory',

    PREMIUM_ANALYSIS_RATINGS: 'premiumAnalysisRatings',
    COVERAGE_ANALYSIS_RATINGS: 'coverageAnalysisRatings',
};
