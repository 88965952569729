/**
 * Created by br0wn on 11/30/16.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {Field, getFormValues, isSubmitting, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import {Col, Row} from 'react-bootstrap';
import {l} from '../../../../i18n/translator.js';
import {Fields as OfferFields, States as OfferStates} from '../../../../../config/domain/offer';
import {Fields as OfferRequestFields} from '../../../../../config/domain/offerRequest.js';
import {ACTIONS} from '../../../../../config/domain/entity';
import {
    renderCurrencyField,
    renderSelectField,
    renderSingleFileUpload,
    renderTextField
} from '../../../common/form/renderer.jsx';
import {isUserOfType} from '../../../../auth/helper.js';
import {hasAbstainedOffer} from '../../../../model/offer';
import {CompanyTypes} from '../../../../../config/domain/user.js';
import {EntityActionOnly} from '../../../common/auth/acl/entityAction.jsx';
import BrokerOnly from '../../../common/auth/acl/brokerOnly.jsx';
import URI from '../../../../../config/uri';
import {publish} from '../../create-offer/action/form';
import {rejectOffer, withdrawOffer} from '../../../../api/sdk-action';
import {
    actionAndReload,
    closeOfferModal,
    deleteOffer,
    editOffer,
    saveOffer,
    updateOfferWithAction
} from '../action/offer';
import {WithdrawOfferModal} from './withdrawOffer.jsx';
import {RejectOfferModal} from './rejectOffer.jsx';
import {LocalCurrencyWithSymbol} from '../../../common/i18n/number.jsx';
import {AnalysisFormFields} from '../../../common/form/analysis/field/analysis.field';
import {CriteriaTypes} from '../../../../../config/domain/analysis';

const hasCriteria = (criteria) => criteria && criteria.length > 0;

export const OfferForm = (props) => {
    const {offerRequest = {}, offers, readOnly, auth, insurances, submitting, formValues} = props;
    const requestedFromInsurances = insurances && offerRequest.requestFromInsuranceIds
        ? insurances.filter((insurance) => offerRequest.requestFromInsuranceIds.indexOf(insurance.id) !== -1) : null;
    const currentOfferInsurance = formValues && requestedFromInsurances
        ? requestedFromInsurances.find((insurance) => insurance.id === formValues.insuranceId) : null;

    const insurancesForCreation = requestedFromInsurances
        ? requestedFromInsurances.filter((insurance) => !hasAbstainedOffer(offers, insurance.id)) : null;
    const showAnalysisRating = isUserOfType(auth, CompanyTypes.BROKER)
        || (formValues && (formValues.state === OfferStates.PUBLISHED || formValues.state === OfferStates.WITHDRAWN));

    const renderAnalysis = function ({disabled = false}) {
        if (!showAnalysisRating) {
            return null;
        }
        const showRatingsPublishWarning = !formValues || formValues.state !== OfferStates.PUBLISHED;
        return <div className='m-b-md'>
            {hasCriteria(offerRequest[OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA]) ?
                (<div>
                    <h3 className="m-t-md">{l('Premium assessment')}</h3>
                    <AnalysisFormFields criteria={offerRequest[OfferRequestFields.PREMIUM_ANALYSIS_CRITERIA]}
                                        criteriaType={CriteriaTypes.PREMIUM}
                                        offerRequest={offerRequest} offers={offers}
                                        ratingsFieldName={OfferFields.PREMIUM_ANALYSIS_RATINGS}
                                        disabled={disabled} criteriaDisabled={true}/>
                    {showRatingsPublishWarning ? (
                        <div className="m-t-xs">
                            <i className="fa fa-warning text-danger"/>&nbsp;
                            <i>{l('These ratings can not be changed after offer has been published.')}</i>
                        </div>
                    ) : null}
                </div>) : null}

            {hasCriteria(offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA]) ?
                (<div>
                    <h3 className="m-t-md">{l('Coverage assessment')}</h3>
                    <AnalysisFormFields criteria={offerRequest[OfferRequestFields.COVERAGE_ANALYSIS_CRITERIA]}
                                        criteriaType={CriteriaTypes.COVERAGE}
                                        offerRequest={offerRequest} offers={offers}
                                        ratingsFieldName={OfferFields.COVERAGE_ANALYSIS_RATINGS}
                                        disabled={disabled} criteriaDisabled={true}/>
                    {showRatingsPublishWarning ? (
                        <div className="m-t-xs">
                            <i className="fa fa-warning text-danger"/>&nbsp;
                            <i>{l('These ratings can not be changed after offer has been published.')}</i>
                        </div>
                    ) : null}
                </div>) : null}
        </div>;
    };

    return readOnly ? (
        <div>
            {/*TODO if broker or if offer of other insurance? or always show insurance?*/}
            {currentOfferInsurance || formValues[OfferFields.INSURANCE_NAME] ? (
                <Row>
                    <Col md={6}>{l('Insurance')}</Col>
                    <Col md={6}>
                        {currentOfferInsurance ? currentOfferInsurance.name : formValues[OfferFields.INSURANCE_NAME]}
                    </Col>
                </Row>
            ) : null}
            <Row>
                <Col md={6}>{l('Billing frequency')}</Col>
                <Col md={6}>{l(`billing_frequency_${offerRequest.insuranceBillingFrequencyMonths}`)}</Col>
            </Row>
            <Row>
                <Col md={6}>{l('Gross premium')}</Col>
                <Col md={6}><LocalCurrencyWithSymbol value={formValues[OfferFields.PREMIUM_GROSS]}/></Col>
            </Row>
            <Row>
                <Col md={6}>{l('General Conditions of Insurance')}</Col>
                <Col md={6}>{formValues[OfferFields.GENERAL_CONDITIONS_OF_INSURANCE_VERSION]}</Col>
            </Row>
            <Row>
                <Col md={6}>{l('Special Conditions of Insurance')}</Col>
                <Col md={6}>{formValues[OfferFields.SPECIAL_CONDITIONS_OF_INSURANCE_VERSION]}</Col>
            </Row>
            {formValues[OfferFields.OFFICIAL_OFFER_FILE] ? (
                <Row>
                    <Col md={6}>{l('Official offer file')}</Col>
                    <Col md={6}>
                        <Link to={URI.OFFICIAL_OFFER_FILE.replace(':id', formValues[OfferFields.ID])} target="_blank">
                            {formValues[OfferFields.OFFICIAL_OFFER_FILE].fileName}
                        </Link>
                    </Col>
                </Row>
            ) : null}

            {renderAnalysis({disabled: true})}

            {formValues[OfferFields.DATA] && formValues[OfferFields.DATA].additionalInformation ? (
                <div>
                    <div className="m-t-md">{l('Comment')}</div>
                    <div className="nl2br font-weight-thin">{formValues[OfferFields.DATA].additionalInformation}</div>
                </div>
            ) : null}
        </div>
    ) : (
        <div>
            <Row>
                <Col md={12}>
                    <h3>{l('Billing frequency')}</h3>
                    {l(`billing_frequency_${offerRequest.insuranceBillingFrequencyMonths}`)}

                    <BrokerOnly auth={auth}>
                        <Field name={OfferFields.INSURANCE_ID} component={renderSelectField}
                               label={l('Insurance')} disabled={submitting} fullWidth>
                            {insurancesForCreation ? insurancesForCreation.map((insurance, index) => (
                                <MenuItem key={index} value={insurance.id} primaryText={insurance.name}/>
                            )) : null}
                        </Field>
                    </BrokerOnly>
                    <Field name={OfferFields.PREMIUM_GROSS} component={renderCurrencyField}
                           label={l('Gross premium')} readOnly={readOnly} disabled={submitting} fullWidth/>
                    {/*<Field name={OfferFields.NET_PREMIUM} component={renderCurrencyField}
                         label={l('Net premium')}
                         readOnly={readOnly}
                         disabled={submitting}/>*/}

                    <Row>
                        <Col md={6}>
                            <Field name={OfferFields.GENERAL_CONDITIONS_OF_INSURANCE_VERSION}
                                   component={renderTextField}
                                   label={l('General Conditions of Insurance')}
                                   readOnly={readOnly} disabled={submitting} fullWidth/>

                            <Field name={OfferFields.SPECIAL_CONDITIONS_OF_INSURANCE_VERSION}
                                   component={renderTextField}
                                   label={l('Special Conditions of Insurance')}
                                   readOnly={readOnly} disabled={submitting} fullWidth/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div className="p-h-sm"/>
            <p>{l('Please upload your offer as PDF here')}</p>

            <Field
                name={OfferFields.OFFICIAL_OFFER_FILE}
                component={renderSingleFileUpload}
                offerRequestId={offerRequest.id}
                i18n={props.i18n}/>

            {renderAnalysis({disabled: false})}

            <Field name={OfferFields.ADDITIONAL_INFORMATION} component={renderTextField}
                   placeholder={l('You can start writing here')}
                   multiline={true} fullWidth={true} rows={2} rowsMax={100}
                   InputLabelProps={{shrink: true}}
                   label={l('Comment')}/>
        </div>
    );
};

export const OfferFormContainer = connect(
    state => ({
        i18n: state.i18n,
        auth: state.auth,
        formValues: getFormValues(FORM_NAME)(state),
        ...state.page.createOffer
    }),
    dispatch => bindActionCreators({}, dispatch)
)(OfferForm);

export const OfferStateLabel = ({offer}) => {
    if (!offer.state) {
        return null;
    }

    const className = {
        'label-danger': offer.state === OfferStates.REJECTED
            || offer.state === OfferStates.WITHDRAWN
            || offer.state === OfferStates.ABSTAINED,
        'label-primary': offer.state === OfferStates.PUBLISHED
    };

    const isCurrent = offer[OfferFields.IS_CURRENT];

    return (
        <div className="m-b-lg">
            <div className="clear">
                <span className={classNames("label pull-left inline m-r-sm", className)} style={{
                    padding: '5px 10px',
                    fontSize: '14px',
                    textTransform: 'uppercase'
                }}>
                    {!isCurrent ? l(`state_${offer.state}`) : l('Current insurance policy')}
                </span>
            </div>

            {offer.withdrawReason && (
                <div className="m-t-sm">
                    {l('Withdraw reason')}<br/>
                    <span className="text-danger">{offer.withdrawReason}</span>
                </div>
            )}
            {offer.rejectReason && (
                <div className="m-t-sm">
                    {l('Reject reason')}<br/>
                    <span className="text-danger">{offer.rejectReason}</span>
                </div>
            )}
        </div>
    )
};


export class OfferFormModalComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isRequestInProgress: false, rejectModalShown: false, withdrawModalShown: false};
    }

    doRequest(requestAction, ...args) {
        this.setState({isRequestInProgress: true});

        return requestAction(...args).then(
            () => this.setState({isRequestInProgress: false})
        ).catch(
            () => this.setState({isRequestInProgress: false})
        )
    }

    executeSubmit(action) {
        return this.props.handleSubmit(values =>
            this.props.onSubmit({
                ...values,
                action: action
            }, this.props.dispatch));
    }

    render() {
        const {readOnly, handleSubmit, submitting, show, offer = {}, offerRequest, offers, insurances} = this.props;
        const {publishOffer, saveOffer, close, rejectOffer, withdrawOffer, deleteOffer, editOffer} = this.props;
        const {isRequestInProgress, rejectModalShown, withdrawModalShown} = this.state;
        const isCurrent = offer[OfferFields.IS_CURRENT];
        return (
            <div>
                <Dialog open={show} onBackdropClick={close} fullWidth={true} maxWidth={'xl'}>
                    <DialogContent>
                        <OfferStateLabel offer={offer}/>

                        <OfferFormContainer readOnly={readOnly}
                                            submitting={submitting || isRequestInProgress}
                                            insurances={insurances} offers={offers}/>

                        <WithdrawOfferModal
                            show={show && withdrawModalShown}
                            onCancel={() => this.setState({withdrawModalShown: false})}
                            onSubmit={(reason) => this.doRequest(withdrawOffer, offer, reason)}
                            meta={{submitting: submitting || isRequestInProgress}}
                        />
                        <RejectOfferModal
                            show={show && rejectModalShown}
                            onCancel={() => this.setState({rejectModalShown: false})}
                            onSubmit={(reason) => this.doRequest(rejectOffer, offer, reason)}
                            meta={{submitting: submitting || isRequestInProgress}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isRequestInProgress || submitting}
                            onClick={close}>
                            {l('Close')}
                        </Button>
                        {!isCurrent ? <EntityActionOnly action={ACTIONS.WITHDRAW} allowedActions={offer.actions || []}>
                            <Button
                                className="pull-left"
                                disabled={isRequestInProgress || submitting}
                                onClick={() => this.setState({withdrawModalShown: true})}>
                                {l('Withdraw offer')}
                            </Button>
                        </EntityActionOnly> : null}
                        <EntityActionOnly action={ACTIONS.REJECT} allowedActions={offer.actions || []}>
                            <Button
                                className="pull-left"
                                disabled={isRequestInProgress || submitting}
                                onClick={() => this.setState({rejectModalShown: true})}>
                                {l('Reject offer')}
                            </Button>
                        </EntityActionOnly>
                        <EntityActionOnly action={ACTIONS.DELETE} allowedActions={offer.actions || []}>
                            <Button
                                color="primary"
                                disabled={isRequestInProgress || submitting}
                                onClick={() => this.doRequest(deleteOffer, offer)}>
                                {l('Delete')}
                            </Button>
                        </EntityActionOnly>
                        <EntityActionOnly action={ACTIONS.PUBLISH} allowedActions={offer.actions || []}>
                            <Button
                                className="pull-left"
                                color="primary"
                                disabled={isRequestInProgress || submitting}
                                onClick={this.executeSubmit('publish')}>
                                {l('Publish')}
                            </Button>
                        </EntityActionOnly>
                        {readOnly && offer ? (
                            <EntityActionOnly action={ACTIONS.EDIT} allowedActions={offer.actions || []}>
                                <Button
                                    color="primary"
                                    disabled={isRequestInProgress || submitting}
                                    onClick={() => editOffer(offerRequest, offer)}>
                                    {l('Edit')}
                                </Button>
                            </EntityActionOnly>
                        ) : null}
                        {!readOnly ? (
                            <Button
                                color="primary"
                                disabled={isRequestInProgress || submitting}
                                onClick={this.executeSubmit('save')}>
                                {l('Save')}
                            </Button>
                        ) : null}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export const FORM_NAME = 'offerEdit';
export const OfferFormModalComponentWrapper = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onSubmit: (data, dispatch) => {
        if (data.action === 'save') {
            return dispatch(updateOfferWithAction(saveOffer));
        } else if (data.action === 'publish') {
            return dispatch(actionAndReload(publish))
        }
    }
})(OfferFormModalComponent);

export const OfferFormModal = connect(
    state => ({
        i18n: state.i18n,
        ...state.page.offerRequestDetails.offerModal,
        offerRequest: state.page.offerRequestDetails.data.offerRequest,
        submitting: isSubmitting(FORM_NAME)(state)
    }),
    dispatch => ({
        ...bindActionCreators({close: closeOfferModal, editOffer}, dispatch),
        save: () => dispatch(updateOfferWithAction(saveOffer)),
        rejectOffer: (offer, reason) => dispatch(actionAndReload(rejectOffer, offer.id, reason)).then(() => dispatch(closeOfferModal())),
        withdrawOffer: (offer, reason) => dispatch(actionAndReload(withdrawOffer, offer.id, reason)).then(() => dispatch(closeOfferModal())),
        deleteOffer: (offer) => dispatch(deleteOffer(offer)).then(() => dispatch(closeOfferModal())),
        dispatch
    })
)((props) => (<OfferFormModalComponentWrapper {...props}/>));
